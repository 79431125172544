// --------------------
// listStyle
// --------------------
.listStyle {
	// SP style
	margin-top: 23px;
	> li {
		position: relative;
		padding-left: 26px;
		counter-increment: listNumber;
		list-style-type: none;
		font-size: 14px;
		line-height: 1.6428;
		letter-spacing: 1.4px;
		color: #333333;
		&:before {
			content:'';
			position: absolute;
			left: 0;
			top: 6px;
			width: 10px;
			height: 11px;
			font-size : 14px;
			line-height: 1.6428;
			letter-spacing : 1.5px;
			color : #EC8E19;
			background-position: left top ;
			background-repeat:no-repeat ;
			background-size: 10px 10px;
			background-image: url(assets/images/common/icon_list.svg);
		}
	}
	li + li {
		padding-top: 0;
		margin-top: 16px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin-top: 24px;
		> li {
			position: relative;
			padding-left: 33px;
			list-style-type: none;
			font-size: 14px;
			line-height: 1.6428;
			letter-spacing: 1.4px;
			color: #333333;
			&:before {
				content:'';
				position: absolute;
				left: 0;
				top: 6px;
				width: 10px;
				height: 11px;
				font-size : 14px;
				line-height: 1.6428;
				letter-spacing : 1.5px;
				color : #EC8E19;
				background-position: left top ;
				background-repeat:no-repeat ;
				background-size: 10px 10px;
				background-image: url(assets/images/common/icon_list.svg);
			}
		}
		li + li {
			padding-top: 0;
			margin-top: 7px;
		}
	}
}

// --------------------
// sectionAboutProduct
// --------------------
.sectionAboutProduct {
	// SP style
	padding-top: 75px;
	.sectionTitle {
		text-align: center;
		font-size: 20px;
		letter-spacing: 1px;
		color: #333333;
		@include english(500);
	}
	.detail {
		margin-top: 56px;
		background-color: #ffffff;
		padding: 15px 19px 44px;
		figure{
			img {
				width: 100%;
				height: auto;
			}
		}
		.textBlock {
			padding-top: 28px;
			p {
				font-size: 14px;
				line-height: 1.7142;
				letter-spacing: 1.4px;
				color : #333333;
				& + p {
					margin-top: 28px;
				}
				em {
					font-style: normal;
					color: $colorOrange2;
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		//padding-top: 75px;
		padding-top: 110px;
		.sectionTitle {
			//text-align: center;
			//font-size: 20px;
			//letter-spacing: 1px;
			//color: #333333;
			//@include english(500);

			font-size: 36px;
			letter-spacing: 0.9px;
		}
		.detail {
			//margin-top: 56px;
			//background-color: #ffffff;
			//padding: 15px 19px 44px;
			margin-top: 68px;
			padding: 64px 60px 40px 29px;
			display: flex;
			justify-content: space-between;
			figure{
				padding-top: 14px;
				flex:0 0 393px;
				max-width:393px;
			}
			.textBlock {
				//padding-top: 28px;
				padding-top:0;
				flex:0 0 457px;
				max-width:457px;
				p {
					//font-size: 14px;
					//line-height: 1.7142;
					//letter-spacing: 1.4px;
					//color : #333333;
					font-size: 15px;
					line-height: 1.7333;
					letter-spacing: 0.7px;
					& + p {
						//margin-top: 28px;
						margin-top: 21px;
					}
					em {
						//font-style: normal;
						//color: $colorOrange2;
					}
				}
			}
		}
	}
}

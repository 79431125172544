// --------------------
// contentsMovie
// --------------------
.contentsMovie {
	//margin-bottom: 58px;

	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		.division {
			display: flex;

			.divisionHead {
				display: flex;
				flex:0 0 56px;
				max-width:56px;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
				//padding: 14px 0 8px;
				text-align: center;
				background-color: #4d4d4d;
				.title {
					display: flex;
					justify-content:center;
					align-items: center;
					font-size: 14px;
					line-height: 1.1;
					letter-spacing: 0;
					color: #FFFFFF;
					//-webkit-writing-mode: vertical-rl;
					//-ms-writing-mode: tb-rl;
					//writing-mode: vertical-rl;
					span {
						box-sizing: border-box;
					}
					.symbolSlash {
						box-sizing: border-box;
					}
					.space {

					}
				}
			}
			+ .division .divisionHead {
				position: relative;
				&:before {
					position: absolute;
					left:0;
					top:0;
					content:'';
					width: 56px;
					height: 32px;
					background-position: center bottom;
					background-repeat: no-repeat;
					background-size: 56px 31px;
					background-image: url(assets/images/business/deco_marketing1_sp.png);
				}
			}
			&:last-of-type .divisionHead {
				&:after {
					position: absolute;
					left:0;
					bottom:0;
					content:'';
					width: 56px;
					height: 22px;
					z-index:2;
					background-position: center bottom;
					background-repeat: no-repeat;
					background-size: 56px 21px;
					background-image: url(assets/images/business/deco_marketing2_sp.png);
				}
			}
			.detail {
				flex:1 1 auto;
				@include dotBorderSp(x, left top);
				ul.default {
					padding: 15px 13px 15px 13px;
					li {
						font-size: 13px;
						line-height: 1.8461;
						letter-spacing : 0.7px;
						color: #333333;
					}
				}
				.advertisement{
					padding: 14px 0 14px 13px;
					background-color: #f4f4f4;
					.detailTitle{
						font-size: 13px;
						font-weight: bold;
						letter-spacing: 0.7px;
						color: #333333;
						padding-bottom: 6px;
					}
					ul.detailAD {
						li {
							font-size: 13px;
							line-height: 1.841;
							letter-spacing: 0.7px;
							color: #333333;
						}
					}
				}
			}
			&.division1 {
				.divisionHead{
					padding-top: 10px;
					padding-bottom: 2px;
				}
			}
			&.division2 {
				.divisionHead {
					padding-top: 32px;
					.title {
					}
				}
			}
			&.division3 {
				position: relative;
				padding-right: 31px;
				.divisionHead{
					padding-top: 32px;
					padding-bottom: 22px;
					.title {
						padding: 10px 0 10px;
					}
				}
				&:after {
					position: absolute;
					right:0;
					top:0;
					content:'';
					width: 31px;
					height: 100%;
					background-color: #f4f4f4;
					background-position: center bottom;
					background-repeat: no-repeat;
					background-size: 31px auto;
					background-image: url(assets/images/business/deco_marketing3_sp.svg);
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		position: relative;
		.division {
			position: relative;
			&:before {
				content:'';
				position: absolute;
				left:0;
				bottom:0;
				display: block;
				width: 1px;
				height: calc(100% - 71px);
				@include dotBorder(y, left bottom);
			}
			.divisionHead {
				position: relative;
				display: flex;
				height: 58px;
				background-color: #4d4d4d;
				align-items: center;
				justify-content: center;
				//margin-right: 10px;
				//padding: 14px 0 14px;
				margin-bottom: 13px;
				text-align: center;
				.title {
					font-size: 16px;
					line-height: 1.1;
					letter-spacing: 0.4px;
					color: #FFFFFF;
				}
			}
			+ .division .divisionHead {
				&:before {
					position: absolute;
					left:0;
					top:0;
					content:'';
					width: 37px;
					height: 58px;
					background-position: right top;
					background-repeat: no-repeat;
					background-size: 37px 58px;
					background-image: url(assets/images/business/deco_marketing1_pc.png);
				}
			}
			&:last-of-type .divisionHead {
				&:after {
					position: absolute;
					right:0;
					bottom:0;
					content:'';
					width: 28px;
					height: 58px;
					background-position: center bottom;
					background-repeat: no-repeat;
					background-size: 28px 58px;
					background-image: url(assets/images/business/deco_marketing2_pc.png);
				}
			}
			.detail {
				padding: 5px 0 129px 25px;
				//padding-bottom: 129px;
				.inner {
					height: 125px;
					position: relative;
					ul.default {
						//padding: 15px 13px 15px 13px;
						li {
							font-size: 15px;
							line-height: 1.73333;
							letter-spacing: 0.8px;
							color: #333333;
						}
					}
					.advertisement{
						position: absolute;
						bottom:-17px;
						left:0;
						padding: 15px 24px 18px 24px;
						background-color: #f4f4f4;
						width: 602px;
						transform:translate(0,100%);
						.detailTitle{
							width: 228px;
							padding-bottom: 2px;
							font-size : 15px;
							font-weight: bold;
							letter-spacing : 0.8px;
							color : #333333;
						}
						&:after {
							position: absolute;
							right:0;
							top:0;
							content:'';
							width: 31px;
							height: 100%;
							background-position: center bottom;
							background-repeat: no-repeat;
							background-size: 31px 100%;
							background-image: url(assets/images/business/deco_marketing3_pc.svg);
						}
						ul.detailAD {
							li {
								width: 228px;
								font-size: 15px;
								line-height: 1.73333;
								letter-spacing: 0.8px;
								color: #333333;
								&.full {
									width: auto;
								}
							}
						}
					}
				}
			}
			&.division1 {
				width: 290px;
				.divisionHead {
					.title {
						padding-left: 11px;
					}
				}

			}
			&.division2 {
				width: 304px;
				.divisionHead {
					.title {
						padding-left: 17px;
					}
				}
				.detail {
					//padding: 19px 0 0;
				}

			}
			&.division3 {
				width: 327px;
				//position: relative;
				//padding-right: 46px;
				.divisionHead {
					.title {
						padding-left: 7px;
					}
				}
			}
		}
	}
}

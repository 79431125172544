// --------------------
// sectionMission
// --------------------
.sectionMission{
// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.layoutSolid{
		// height: 530px + $topAddMarin;
		position: relative;
		padding-bottom: 50px;
	}
	.head1{
		margin-left: -25px;
		flex-direction: row-reverse;
		.title{
			margin-left: 15px;
			// margin-left: 50px;
			// margin-right: -220px;
		}
	}

	.text{
		// margin: 80px 250px 0 0;
		@include english(400);
		font-size: 15px;
		line-height: 1.65;
		letter-spacing: 0;
		text-align: right;
		word-wrap:break-word;


		em{
			background-color: #e38a21;
			color: #fff;
			font-style: normal;
			padding-left: 0.5em;
			padding-right: 0.5em;
		}
	}

	.arrowDrop{
		display: none;
	}


}

// PC style
@media print, screen and (min-width: $breakpoint1){
	position: relative;
	// padding-top: 60px;
	padding-top: 0;
	padding-left: 200px;

	.layoutSolid{
		height: 530px + $topAddMarin;
		position: relative;
	}
	.head1{
		flex-direction: row-reverse;
		.title{
			margin-left: 50px;
			margin-right: -220px;
		}
	}
	.text{
		margin: 80px 205px 0 0;
		// font-feature-settings : "palt";
		@include english(400);
		font-size : 19px;
		line-height : 3.05;
		letter-spacing: 0;
		text-align: right;
		word-wrap:break-word;

		em{
			background-color: #e38a21;
			color: #fff;
			font-style: normal;
			padding-left: 0.5em;
		}
	}

	.arrowDrop{
		top: 180px;
		right: 205px;
	}


}
}

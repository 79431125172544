// --------------------
// pager
// --------------------
.pager{
	margin: 60px 0 36px 0;
	padding: 0 0 0 0;

	.screen-reader-text {
		clip: rect(1px, 1px, 1px, 1px);
		position: absolute;
	}

	.pagination .nav-links {
		position: relative;
		display: table;
		font-size: 11px;
		@include english(400);
		color: #191919;
		border-collapse: separate;
		border-spacing: 2px 0;
		margin:auto;
		.page-numbers {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			width:35px;
			height: 35px;
			border: 1px solid #ccc;
			color: #191919;
		}
		.prev.page-numbers {
			position: absolute;
			left:0;
			top:50%;
			display: block;
			margin-right: 4px;
			width: 42px;
			height: auto;
			border: 0;
			font-size: 0;
			transform:translate(-46px,-50%);

			&:before {
				content: '';
				//margin-right: 6px;
				display: block;
				width: 42px;
				height: 8px;
				background-position: right center;
				background-image: url(assets/images/common/btn_arrow.svg);
				background-size:100% auto;
				transform: rotate(-180deg);
			}
		}
		.next.page-numbers {
			position: absolute;
			right:0;
			top:50%;
			display: block;
			margin-left: 4px;
			width: 42px;
			height: auto;
			border: 0;
			font-size: 0;
			transform:translate(46px,-50%);
			&:after {
				content: '';
				display: block;
				//margin-left: 6px;
				width: 42px;
				height: 8px;
				background-position: right center;
				background-image: url(assets/images/common/btn_arrow.svg);
				background-size:100% auto;
			}
		}
		.page-numbers.dots {
			width: 18px;
			border-color: transparent;
		}
		.page-numbers.current {
			background-color: $colorOrange;
			border: 1px solid $colorOrange;
			color: #ffffff;
		}
	}
	// small SP style
	@media print, screen and (max-width: 374px) {

		.pagination .nav-links {
			font-size: 10px;
			border-spacing: 1px 0;
			.page-numbers {
				width:32px;
				height: 32px;
				margin-right:0;
				margin-left:0;
			}
			.prev.page-numbers {
				width:30px;
				margin-right: 0;
				transform:translate(-31px,-50%);
				&:before {
					width: 30px;
					height: 8px;
				}
			}
			.next.page-numbers {
				width:30px;
				margin-left: 0;
				transform:translate(31px,-50%);
				&:after {
					width: 30px;
					height: 8px;
				}
			}
			.page-numbers.dots {
				width:14px;
			}
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin: 60px 0 36px 0;
		padding: 0 0 10px 0;

		.pagination .nav-links {
			font-size: 14px;
			border-spacing: 14px 0;
			.page-numbers {
				width:40px;
				height: 40px;
			}
			a.page-numbers:hover {
				cursor: pointer;
				opacity: 0.7;
			}
			.prev.page-numbers {
				width:75px;
				height: 40px;
				margin-right: 42px;
				transform:translate(-106px,-50%);
				&:before {
					width: 42px;
					height: 100%;
					transform:translate(0,0) rotate(180deg);
				}
				.screen-reader-text {
					padding-left: 8px;
				}
				&:hover {
					opacity: 0.7;
					&:before {
						animation: arrowAnimation2-pager 500ms ease 0ms;
					}
				}
			}
			.next.page-numbers {
				width:75px;
				height: 40px;
				margin-left: 42px;
				transform:translate(135px,-50%);
				&:after {
					width: 42px;
					height: 100%;
					transform:translate(0,0);
				}
				.screen-reader-text {
					padding-right: 8px;
				}
				&:hover {
					opacity: 0.7;
					&:after{
						height: 100%;
						animation: arrowAnimation3-pager 500ms ease 0ms;
					}
				}
			}
			.page-numbers.dots {
				width:29px;
			}
		}
	}
	@keyframes arrowAnimation2-pager {
		0% {
			transform: translate(0%,0) rotate(180deg);
		}
		50% {
			transform: translate(-100%,0) rotate(180deg);
		}
		51% {
			transform: translate(100%,0) rotate(180deg);
		}
		100% {
			transform: translate(-0%,0) rotate(180deg);
		}
	}
	@keyframes arrowAnimation3-pager {
		0% {
			transform: translate(-0%,0);
		}
		50% {
			transform: translate(100%,0);
		}
		51% {
			transform: translate(-100%,0);
		}
		100% {
			transform: translate(0%,0);
		}
	}
}

// --------------------
// post-type-archive-news
// tax-news_category
// --------------------
body.post-type-archive-news main,
body.tax-news_category main{


	//$topAddMarin: 100px;


	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		padding-bottom: 100px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1) {
		padding-bottom: 100px;
	}

	// styles
	@import 'news/_sectionArchiveNews.scss';
}

// --------------------
// sectionRecruitKV
// --------------------
.sectionRecruitKV {
	// SP style
	padding-bottom: 30px;

	figure {
		img {
			vertical-align: top;
			width: 100%;
			height: auto;
		}
	}
	.detail {
		padding: 56px 30px 58px;
		.text {
			font-size: 14px;
			line-height: 1.75428;
			letter-spacing: 1.4px;
			color: #333333;
		}
		.localNavigation ul{
			margin-top: 56px;
			li {
				margin-top: 14px;
				&:first-child {
					margin-top: 0;
				}
				.btn1 a {
					padding: 13px 0 13px;
					.text, .arrow {}
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 30px;
		margin-left: -125px;
		padding-left: 0;
		min-width: 0;

		figure {
			line-height: 1;
			img {
			}
		}
		.detail {
			padding: 95px 50px 105px 175px;
			width: 1225px;
			display: flex;
			justify-content: space-between;
			.text {
				flex:1 1 64%;
				max-width: 64%;
				font-size: 14px;
				line-height: 1.85428;
				letter-spacing: 1.4px;
			}
			.localNavigation ul{
				margin-top: 0;
				flex:1 1 45%;
				max-width: 45%;
				li {
					margin-top: 14px;
					&:first-child {
						margin-top: 0;
					}
					.btn1 a {
						.text, .arrow {}
					}
				}
			}
		}
	}
}

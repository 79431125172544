// --------------------
// message
// --------------------
body.page-message main{


	//$topAddMarin: 100px;

	// styles
	@import 'message/_sectionMessageHead.scss';
	@import 'message/_sectionMessageKV.scss';
	@import 'message/_sectionCommitmentCEO.scss';
	@import 'message/_sectionCommitmentGemWay.scss';
}
// --------------------
// sectionIntro
// --------------------
.sectionIntro {
	// SP style
	padding-top: 0;
	padding-bottom: 76px;
	.logo {
		a {
			display: block;
			.logoImage {
				width: 77.419%;
				margin: auto;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
			}
			.urlText {
				padding-top: 25px;
				text-align: center;
				font-size: 14px;
				letter-spacing: 1.4px;
				color: #898989;
				text-decoration: underline;
			}
		}
	}
	.block1 {
		padding-top: 75px;
		.blockTitle {
			padding-bottom: 38px;
			text-align: center;
			font-size: 21px;
			line-height: 1.5714;
			letter-spacing: 1.5px;
			color: #333333;
		}
		.text {
			p {
				font-size: 14px;
				line-height: 1.7142;
				letter-spacing: 1.4px;
				color: #333333;
				& + p {
					margin-top: 28px;
				}
				em {
					font-style: normal;
					color: $colorOrange2;
				}
			}
		}
	}
	.block2 {
		padding-top: 53px;
		.blockTitle {
			text-align: center;
			font-size: 18px;
			line-height: 1.3333;
			letter-spacing: 1px;
			color: #333333;
		}
		.detail {
			margin-top: 30px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 120px;
		padding-left:150px;
		padding-right:150px;

		.logo {
			a {
				.logoImage {
					width: 441px;
				}
				.urlText {
					padding-top: 30px;
					font-size: 15px;
					line-height: 1.73333;
					letter-spacing : 0.8px;
					color: #666666;
					text-decoration: none;
				}
				&:hover {
					.logoImage {
						opacity: 0.7;
					}
					.urlText {
						text-decoration: underline;
					}
				}
			}
		}
		.block1 {
			padding-top: 46px;
			.blockTitle {
				padding-top: 43px;
				font-size: 28px;
				line-height: 1.4286;
				letter-spacing: 0.7px;
			}
			.text {
				p {
					font-size: 15px;
					line-height: 1.73333;
					letter-spacing: 0.8px;
				}
			}
		}
		.block2 {
			padding-top: 93px;
			.blockTitle {
				font-size: 26px;
				line-height: 1.4615;
				letter-spacing: 0.6px;
				color: #333333;
			}
			.detail {
				margin-top: 55px;
				.detail1 {
				}
			}
		}
	}

}

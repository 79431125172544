// --------------------
// sectionMemberDetailKV
// --------------------
.sectionMemberDetailKV {
	// SP style
	.pageTitle {
		position: relative;
		margin:0 30px -14px;
		z-index:10;
		.title {
			line-height: 1;
			.sub {
				font-size: 10px;
				letter-spacing: 1px;
				color: #000000;
				font-weight: 400;
			}
			.main {
				padding-top: 5px;
				font-size: 23px;
				font-weight: bold;
				line-height: 1.89;
				letter-spacing: 1.7px;
				color: #333333;
				@include YuGothic();
			}
			.en {
				padding-top: 15px;
				font-size: 10px;
				letter-spacing: 1px;
				color: #A2A2A2;
				@include english(400);
				vertical-align: top;
			}
		}
	}
	.pageTitle.message {
		position: relative;
		margin:0 30px -14px;
		padding-top: 55px;
		padding-bottom: 58px;
		z-index:10;
		.title {
			line-height: 1;
			.sub {
				font-size: 10px;
				letter-spacing: 1px;
				color: #000000;
				font-weight: 400;
				margin-top: 0;
			}
			.main {
				padding-top: 5px;
				font-size: 23px;
				font-weight: bold;
				line-height: 1.89;
				letter-spacing: 1.7px;
				color: #333333;
				@include YuGothic();
			}
			.en {
				padding-top: 15px;
				font-size: 10px;
				letter-spacing: 1px;
				color: #A2A2A2;
				@include english(400);
				vertical-align: top;
			}
		}
		.btn4 {
			margin-top: 7px;
			@include dotBorderSp(x, left top);
			a {
				padding: 15px 0 7px;
				background-color: transparent;

				.text {
					flex: 0 1 auto;
					margin-right: 26px;
					font-size : 12px;
					line-height: 1.4751;
					letter-spacing: 1px;
					color: #333333;
				}
			}
		}
	}
	.keyVisual {
		img {
			width: 100%;
			height: auto;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		max-width: 1400px;
		position: relative;
		display: flex;
		justify-content: flex-end;
		overflow: hidden;
		.pageTitleWrap {
			position: absolute;
			left:180px;
			top:0;
			width: 100%;
			height: 100%;
		}
		.pageTitle {
			position: absolute;
			margin:0 0;
			width: 460px;
			left:0;
			top:50%;
			transform: translate(0, -50%);

			.title {
				.sub {
					font-size: 15px;
					letter-spacing: 0.8px;
				}
				.main {
					font-size: 34px;
					letter-spacing: 2.55px;
				}
				.en {
					padding-top: 8px;
					font-size: 13px;
					letter-spacing: 0.65px;
					line-height: 1;
				}
			}
		}
		.pageTitle.message {
			position: absolute;
			margin:0 0;
			width: 460px;
			left:0;
			top:50%;
			transform: translate(0, -50%);
			padding-top: 48px;
			padding-bottom: 30px;

			.title {
				.sub {
					font-size: 15px;
					letter-spacing: 0.8px;
				}
				.main {
					font-size: 34px;
					letter-spacing: 2.55px;
				}
				.en {
					padding-top: 8px;
					font-size: 13px;
					letter-spacing: 0.65px;
					line-height: 1;
				}
			}
			.btn4 {
				margin-top: 18px;
				a {
					padding-top: 12px;
					.text {
						font-size : 14px;
					}
				}
			}
		}
		.keyVisual {
			width: 66.75%;
			max-width:66.75%;
			min-width:600px;
			img {
			}
		}
	}
}

// --------------------
// sectionProfile
// --------------------
.sectionProfile{
	position: relative;

	.slick-dots{
		z-index: 150;
		position: absolute;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		li{
			margin: 0 10px;
		}
		button{
			font-size: 0;
			display: block;
			width: 13px;
			height: 13px;
			border: 1px solid #fff;
			border-radius: 50%;
			background-color: rgba(#fff, 0.0);
		}
		.slick-active button{
			background-color: #fff;
		}
	}






// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.slick-dots{
		bottom: 20px;
	}

	.headWrap{
		position: absolute;
		// width: 100vw;
		// top: 50vh;
		// top: 50%;
		top: 0;
		left: 25px;
		padding-top: 12.5%;

		// transform: translateY(-50%);
		color: #fff;
		z-index: 100;

		@media print, screen and (max-width: 359px){
			padding-top: 8%;
		}
		.head2{

		}
	}

	.profileWrap{
	}

	.layoutSolid{
		padding-top:    70px;
		padding-bottom: 100px;
	}
	.companyTitle{
		font-size: 19px;
		margin-bottom: 40px;
	}
	.ceoWrap{
		padding-bottom: 40px;
		@include dotBorderSp(x, left bottom);

		.ceo{
			padding-bottom: 40px;
			.position{
				font-size: 11px;
				padding-right: 2em;
			}
			.name{
				font-size: 16px;
			}
		}
		.btn1{

		}
	}
	.address{
		padding: 30px 0;
		.address1{
			font-size: 14px;
			margin-bottom: 10px;
		}
		.address2{
			@include english(300);
			font-size: 12px;
			color: #a3a3a3;
		}
	}
	.map{
		display: flex;
		align-items: center;
		margin-bottom: 60px;

		&:before{
			content: '';
			display: block;
			width: 14px;
			height: 20px;
			background-size: contain;
			background-position: center;
			background-image: url('assets/images/common/icon_map2.svg');
			margin-right: 10px;
		}
		span{
			@include english();
			font-size: 15px;
			color: #333;
			text-decoration: underline;
		}

	}
	.btns{
		.btn1{
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}


	.images{
		// position: sticky;
		// top:0;
		// height: 100vh;
		width: 100%;
		overflow: hidden;

		li{
			overflow: hidden;
		}
		img,
		picture {
			width: 100%;
			height: auto;
			// min-width: 100vw;
			// min-height: 100vh;
		}
	}

}



// PC style
@media print, screen and (min-width: $breakpoint1){

	.headWrap{
		position: absolute;
		width: 1000px;

		// top: 50vh;
		left: 50%;
		top: 25%;
		margin-left: 75px;
		// transform: translateY(-50%);
		transform: translateY(-50%) translateX(-50%);
		color: #fff;
		z-index: 100;
		.head2{
			// position: absolute;
			// top: 50vh;
			// left: 100px;
			// transform: translateY(-50%);
			// color: #fff;
		}
	}
	.profileWrap{
		padding-left: 200px;
	}

	.layoutSolid{
		overflow: hidden;
		padding-top:    100px;
		padding-bottom: 160px;
	}
	.companyTitle{
		// width: 650px;
		float: left;
		// @import notoBold();
		font-size: 23px;
	}
	.ceoWrap{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 35px;

		@include dotBorder(x, left bottom);

		.ceo{
			flex: 0 0 300px;

			.position{
				font-size: 13px;
				padding-right: 2em;
			}
			.name{
				font-size: 17px;
			}
		}
		.btn1{
			flex: 0 0 300px;
		}
	}
	// .ceo{
	// 	// width: 650px;
	// 	// float: right;
	// 	// padding-bottom: 20px;
	//
	//
	// }
	.address{
		width: 650px;
		float: right;
		padding: 20px 0;
		display: flex;
		// align-items: center;
		justify-content: space-between;
		.address1{
			flex: 0 0 300px;
			font-size: 15px;
		}
		.address2{
			flex: 0 0 300px;
			@include english(300);
			font-size: 14px;
			color: #a3a3a3;
		}
	}
	.map{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		&:before{
			content: '';
			display: block;
			width: 14px;
			height: 20px;
			background-size: contain;
			background-position: center;
			background-image: url('assets/images/common/icon_map2.svg');
			margin-right: 10px;
		}
		span{
			@include english();
			font-size: 14px;
			color: #333;
			text-decoration: underline;
		}
		a:hover{
			opacity: 0.7;
		}
	}
	.btns{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn1{
			flex: 0 0 300px;
		}
	}


	.images{
		// position: sticky;
		// top:0;
		// height: 100vh;
		width: 100%;
		overflow: hidden;

		li{
			overflow: hidden;
		}
		img,
		picture {
			width: 100%;
			height: auto;
			// width: auto;
			// height: auto;
			// min-width: 100vw;
			// min-height: 100vh;
		}
	}

}
}

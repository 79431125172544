// --------------------
// business
// --------------------
body.page-business main{

	.wrapContents {
	// SP style
		background-color: #f6f5f6;
		padding-bottom: 42px;
		@media print, screen and (min-width: $breakpoint1) {
			// PC style
			padding-bottom: 100px;
		}
	}

	// styles
	@import 'business/_sectionHead.scss';
	@import 'business/_sectionKeyVisual.scss';
	@import 'business/_sectionIntro.scss';
	@import 'business/_sectionAboutProduct.scss';
	@import 'business/_sectionLineUp.scss';
	@import 'business/_sectionProductsNews.scss';
	@import 'business/_sectionPartners.scss';
	@import 'business/_sectionInduction.scss';
	@import 'business/_contentsMovie.scss';
}
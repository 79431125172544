// --------------------
// sectionNews
// --------------------
.sectionNews{


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	padding-top: 80px;
	padding-bottom: 150px;

	ul{
		@include dotBorderSp(x, left top);
		margin: 45px 0 50px;

		li{
			@include dotBorderSp(x, left bottom);
		}
		a{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			position: relative;
			padding: 23px 40px 23px 0;
		}
		.category{
			flex: 0 0 100px;
			background-color: #333;
			color: #fff;
			font-size: 10px;
			text-align: center;
			padding: 3px 0;
		}
		.date{
			flex: 1 1 100px;
			margin-left: 15px;
			color: #a3a3a3;
			@include english();
			font-size: 11px;
		}
		.text{
			flex: 1 1 100%;
			margin-top: 5px;
			color: #333;
			font-size: 13px;
			line-height: 28px;
		}
		.arrow{
			position: absolute;
			top: 50%;
			right: 0;
		}
	}
}


// PC style
@media print, screen and (min-width: $breakpoint1){
	padding-top:    90px;
	padding-bottom: 160px;
	padding-left: 200px;

	ul{
		@include dotBorder(x, left top);
		margin: 45px 0;

		li{
			@include dotBorder(x, left bottom);
			padding: 15px 0;
		}
		a{
			display: flex;
			align-items: center;
			&:hover{
				.text{
					text-decoration: underline;
				}
			}
		}
		.category{
			flex: 0 0 120px;
			max-width: 120px;
			background-color: #333;
			color: #fff;
			font-size: 11px;
			text-align: center;
			padding: 3px 0;
		}
		.date{
			flex: 0 0 80px;
			max-width: 80px;
			margin-left: 15px;
			color: #a3a3a3;
			@include english();
			font-size: 13px;
		}
		.text{
			flex: 1 1 695px;
			max-width: 695px;
			margin-right: 30px;
			margin-left: 15px;
			color: #333;
			font-size: 14px;
		}
		.arrow{
			flex: 0 0 38px;
			max-width: 38px;
		}
	}

	.btn1{
		margin-left: auto;
	}

}
}

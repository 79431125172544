// --------------------
// listNumStyle
// --------------------
.listNumStyle {
	margin-top: 50px;
	> li {
		position: relative;
		padding-left: 23px;
		counter-increment: listNumber;
		list-style-type: none;
		font-size: 14px;
		line-height: 1.6428;
		letter-spacing: 1.4px;
		color: #333333;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			content: counter(listNumber);
			@include english(400);
			font-size : 14px;
			line-height: 1.6428;
			letter-spacing : 1.5px;
			color : #EC8E19;
		}
	}
	li + li {
		padding-top: 0;
		margin-top: 16px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin-top: 24px;
		> li {
			position: relative;
			padding-left: 33px;
			counter-increment: listNumber;
			list-style-type: none;
			font-size: 14px;
			line-height: 1.6428;
			letter-spacing: 1.4px;
			color: #333333;
			&:before {
				letter-spacing : 1.5px;
			}
		}
	}
	li + li {
		padding-top: 0;
		margin-top: 16px;
	}
}

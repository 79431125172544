// --------------------
// sectionCommitmentCEO
// --------------------
.sectionCommitmentCEO {
	padding-top: 68px;
	// SP style
	.sectionTitle {
		font-size: 23px;
		letter-spacing: 0.8px;
		color: #000000;
		text-align: center;
	}
	.paragraph {
		margin-top: 47px;
		p {
			font-size: 14px;
			letter-spacing: 1.4px;
			color: #333333;
			span {
				display: block;
				& + span {
					padding-top: 32px;
				}
			}
		}
	}
	.signature {
		margin-top: 61px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 66px;
		border-bottom: 1px solid #bcbcbc;
		.person {
			padding-right: 20px;
			flex: 0 0 130px;
			max-width: 130px;
			.job{
				font-size: 12px;
				line-height: 1;
				letter-spacing: 1.2px;
				color: #333333;
			}
			.name {
				font-size: 21px;
				letter-spacing: 4.2px;
				color: #333333;
			}
			&:only-child {
				justify-content: center;
				text-align: center;
				padding-right: 0;
				margin-right: 0;
			}
		}
		.image {
			margin-left: 0;
			max-width: 171px;
			min-width: 140px;
			width: 100%;
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top: 84px;

		.sectionTitle {
			margin-top: 0;
			font-size: 27px;
			letter-spacing: 1.35px;
			color: #333333;
		}
		.paragraph {
			margin-top: 73px;

			p {
				font-size: 15px;
				letter-spacing : 0.5px;
				color : #333333;
				span {
					text-align: center;
					& + span {
						padding-top: 18px;
					}
				}
			}
		}
		.signature {
			margin-top: 70px;
			padding-bottom:122px;
			.person {
				flex:0 0 145px;
				max-width:145px;
				margin-right: 10px;
				.job{
				}
				.name {
					padding-top: 7px;
					font-size: 22px;
					letter-spacing: 2.2px;
				}
			}
			.image {
				width: 157px;
				img {
				}
			}
		}

	}
}
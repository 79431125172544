// --------------------
// head1
// -------------------- */
.head1{
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title {
		@include english(500);
		letter-spacing: 0.05em;
	}
	.line{
		display: block;
		flex: 1 1 100px;
		height: 1px;
		position: relative;
		overflow: visible;
		.point{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #a3a3a3;
			opacity: 0;
			width:  6px;
			height: 6px;

			transform-origin: center;
			transform: translateY(-50%) rotate(45deg) scale(0);

			animation-timing-function: ease-in;
			animation-iteration-count: infinite;
		}
	}

// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	// margin-right: -25px;
	// margin-left: -25px;
	padding-top: 50px;
	padding-bottom: 50px;

	.title {
		font-size: 38px - 2px;
		line-height: 1;
		flex: 1 0 100px;
		white-space: nowrap;
	}
	.line{
		@include dotBorderSp(x, left top);
		.point{
			animation-name: pointAnimation;
			width:  4px;
			height: 4px;
		}
	}
}

// PC style
@media print, screen and (min-width: $breakpoint1){
	.title {
		font-size: 83px;
		line-height: 1;
		flex: 1 0 100px;
		white-space: nowrap;
	}
	.line{
		@include dotBorder(x, left top);

		.point{
			animation-name: pointAnimation;
			// display: block;
			// position: absolute;
			// top: 0;
			// left: 0;
			// background-color: #a3a3a3;
			// opacity: 0;
			width:  6px;
			height: 6px;
			//
			// transform-origin: center;
			// transform: translateY(-50%) rotate(45deg) scale(0);
			//
			// animation-name: pointAnimation;
			// // animation-duration: 2500ms;
			// // animation-delay: 1000ms;
			// animation-timing-function: ease-in;
			// animation-iteration-count: infinite;
		}

	}
}
}




@keyframes pointAnimation {
	0% {
		transform: translateY(-50%) rotate(45deg) scale(0.0);
		background-color: #a3a3a3;
		opacity: 0;
	}
	50% {
		transform: translateY(-50%) rotate(45deg) scale(0.8);
		background-color: #a3a3a3;
		opacity: 0.8;
	}
	70% {
		transform: translateY(-50%) rotate(45deg) scale(1.0);
		// transform: translateY(-50%) rotate(45deg) scale(2.0);
		background-color: #a3a3a3;
		opacity: 1;
	}
	80% {
		// transform: translateY(-50%) rotate(45deg) scale(3.0);
		background-color: #ec8e19;
		opacity: 1;
	}
	90% {
		// transform: translateY(-50%) rotate(45deg) scale(1.8);
		background-color: #cd151b;
		opacity: 0.5;
	}
	100% {
		transform: translateY(-50%) rotate(45deg) scale(3.5);
		background-color: #a3a3a3;
		opacity: 0;
	}
}

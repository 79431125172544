// --------------------
// sectionCompanyProfile
// --------------------
.sectionCompanyProfile {
	// SP style
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 104px;
	padding-left: 0;

	.postContent {
		p:first-child {
			padding-top: 0;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 0.7px;
			color: #333333;
			&.addressEnglish {
				padding-top:6px;
				color: #a2a2a2;
				@include english(400);
			}
		}
		strong {
			font-size: 14px;
			@include fontBold();
			line-height: 2;
			letter-spacing: 1.4px;
		}
		a {

		}
		.bgOrange {
			background-color: #ec8e19;
			color: #FFFFFF;
		}
		> hr {
			margin-top: 30px;
			border:0;
			border-top:1px solid #D6D6D6;
		}
		.blockText {
			padding-top: 48px;
			b {
				font-size: 14px;
				line-height: 2;
				@include fontBold();
			}
			p + b{
				display: block;
				padding-top: 20px;
			}
			strong {
				font-size: 14px;
				line-height: 2;
				@include fontBold();
			}
			p + strong {

			}
		}
		.caution {
			font-size: 11px;
			line-height: 1.5714;
			letter-spacing: 1.1px;
			color: #666666;
		}
		.blockDetail {
			margin-top: 58px;
			background-color: #f6f5f6;
			padding: 32px 23px;
			p:not(:first-child){
				padding-top: 20px;
			}
			.text1 {
				font-size: 14px;
				@include fontBold();
				letter-spacing: 1px;
				color: #333333;
			}
			.text2 {
				padding-top: 20px;
			}
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		.tduOrange {
			color: #ec8e19;
			text-decoration: underline;
			.text {
				color: #333333;
			}
		}
		.bdbGray {
			padding-bottom: 10px;
			border-bottom: 1px solid #e2e2e2;
		}
		a {
			font-size : 14px;
			letter-spacing: 1.4px;
			color: #808080;
			text-decoration: underline;
		}
		[class^='btn']{
			margin-top: 50px;
			a {
				text-decoration: none;
				.text {
					color: #000;
				}
				.arrow {
				}
			}
			&.btn4 {
				margin-top: 0;
				a {
					padding: 13px 0 19px;
					.text {
					}
				}
			}
		}
		figure {
			padding-top: 80px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			> .notes {
				padding-top: 17px;
				font-size: 11px;
				line-height: 1.574;
				letter-spacing: 1px;
				color: #666666;
			}
		}

		.blockText2 {
			display: flex;
			flex-direction: column;
			margin-top: 85px;

			p:first-child{
				padding-top: 0;
			}
			figure {
				img, .notes {}
			}
			> .notes {
				padding-top: 10px;
			}
			.order1 {
				order:1;
				padding-bottom: 35px;
			}
			.order2 {
				order:2;
			}
		}

		.youtube {
			margin-top: 85px;
			padding-bottom: 56.25%;
			height: 0;
			position: relative;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		table {
			padding-top: 88px;
			display: block;
			border-collapse: collapse;
			tbody {
				display: block;
			}
			tr {
				display: block;
				border-top: 1px solid #e2e2e2;
				& + tr {
					border-top: 0;
				}
				th {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					background-color: #f6f5f6;
					padding: 18px 23px 18px 23px;
					font-weight: normal;
					text-align: left;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
				td {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					padding: 18px 23px 18px 23px;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
			}
		}
		.fwb {
			@include fontBold();
		}
		.lead {
			font-size: 14px;
			line-height: 1.7142;
			letter-spacing: 1.4px;
			color: #333333;
		}
		//config
		.colorOrange {color: #ec8e19;}
		.wrapSection {
			margin-top: 0;
			border-bottom: 1px solid #f0f0f0;
		}
		.section {
			.head {
				padding: 14px 42px 14px;
				background-color: #f6f5f6;
				border-top: 1px solid #e2e2e2;
				border-bottom: 1px solid #e2e2e2;
				.itemName {
					font-size: 16px;
					@include fontBold();
					letter-spacing: 1.6px;
					color: #333;
					&.itemNameStyle2 {
						font-size: 0;
						.text1 {
							display: inline-block;
							vertical-align: middle;
							font-size: 16px;
						}
						.small {
							vertical-align: middle;
							display: inline-block;
							font-size: 12px;
							font-weight: 400;
							letter-spacing : 2.4px;
							color : #333333;
						}
					}
				}
			}
			.detail {
				padding: 32px 30px 32px;
				h3:first-child {
					padding-top: 0;
				}
				p:first-child {
					padding-top: 0;
				}
				h3 {
					padding-top: 36px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorderSp(x, left top);
					}
				}
				&.accordion {
					padding: 0 30px 32px;
					.client {
						.clientTitle {
							padding: 14px 25px 14px 0;
							font-size: 13px;
							letter-spacing: 1px;
							color: #333333;
							border-top: 1px solid #dddddd;
							// border-bottom: 1px solid #dddddd;
							position: relative;
							cursor: pointer;
							&:first-child {
								border-top: 0;
							}
							&.close {
								border-bottom: 1px solid #fff;
								&:after {
									position: absolute;
									right:20px;
									top:50%;
									content:'';
									display: block;
									width: 10px;
									height: 10px;
									background-image: url(/wp-content/themes/gempartners_0.1/assets/images/common/btn_burger_close.svg);
									background-size:10px 10px;
									background-repeat: no-repeat;
									transform:rotate(45deg) translate(0,-50%);
								}
							}
							&.open {
								border-bottom: 1px solid #dddddd;
								&:after {
									position: absolute;
									right:15px;
									top:50%;
									content:'';
									display: block;
									width: 13px;
									height: 1px;
									background-image: none;
									transform:rotate(0deg) translate(0,1px);
									background-color: #222;
								}
							}
						}
						.clientDetail {
							padding: 24px 0 30px;
							&:last-child {
								padding-bottom: 0;
							}
							.listStyle {
								margin-top: 0;
							}
						}

						.clientDetail.accDetail{
							display: none;
						}
					}
				}
			}
		}
	}
	.wrapMap {
		padding-top: 21px;
		.map {
			position: relative;
			border: 0;
			height: 0;
			padding-bottom: 65.555%;
			overflow: hidden;
			padding-top: 55px;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.linkMap {
		display: flex;
		justify-content: flex-end;
		.linkGoogleMap {

		}
	}
	.contactList {
		li {
			margin-top: 7px;
			display: block;
			&:first-child {
				margin-top: 0;
			}
			.text {
				font-size: 14px;
				letter-spacing: 1.4px;
				color: #333333;
			}
			&.telephone {
				display: flex;
				align-items: center;
				&:before {
					content:'';
					display: block;
					margin-right: 12px;
					width: 19px;
					height: 19px;
					text-align: center;
					background-image: url(assets/images/common/icon_telephone.svg);
					background-position: center 0;
					background-repeat: no-repeat;
					background-size:8px 18px;
				}
			}
			&.mail {
				display: flex;
				align-items: center;
				&:before {
					content:'';
					display: block;
					margin-right: 12px;
					width: 19px;
					height: 25px;
					text-align: center;
					background-image: url(assets/images/common/icon_mail.svg);
					background-repeat: no-repeat;
					background-size:19px 24px;
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top: 0;
		padding-right: 50px;
		padding-bottom: 201px;
		padding-left: 50px;

		.postContent {
			margin: auto auto 88px;
			p:first-child {
				padding-top: 0;
			}

			p {
				padding-top: 27px;
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing: 1.5px;
				color: #333333;
				&.addressEnglish {
					padding-top:13px;
					font-size: 15px;
					letter-spacing: 0.75px;
					color: #a2a2a2;
				}
				+ .listStyle {
					margin-top: 16px;
				}
			}
			strong {
				font-size: 14px;
				@include fontBold();
				line-height: 2;
				letter-spacing: 1.4px;
			}
			a {

			}
			.bgOrange {
				background-color: #ec8e19;
				color: #FFFFFF;
			}
			> hr {
				margin-top: 58px;
			}
			.blockText {
				padding-top: 58px;
				b {
					font-size: 14px;
					line-height: 2;
					@include fontBold();
				}
				p + b{
					display: block;
					padding-top: 27px;
				}
				strong {
					font-size: 14px;
					line-height: 2;
					@include fontBold();
				}
				p + strong {
					display: block;
					padding-top: 27px;
				}
			}
			.caution {
				font-size: 11px;
				line-height: 1.5714;
				letter-spacing: 1.1px;
				color: #666666;
			}
			.blockDetail {
				margin-top: 60px;
				background-color: #f6f5f6;
				padding: 46px 50px;
				p:not(:first-child){
					padding-top: 20px;
				}
				li + li {
					padding-top: 0;
					margin-top: 15px;
				}
				.text1 {
					font-size: 14px;
					@include fontBold();
					letter-spacing: 1px;
					color: #333333;
				}
				.text2 {
					padding-top: 20px;
				}
			}
			.bdb {
				padding-bottom: 26px;
				border-bottom: 1px solid #e2e2e2;
			}
			.tduOrange {
				color: #ec8e19;
				text-decoration: underline;
				.text {
					color: #333333;
				}
			}
			.bdbGray {
				padding-bottom: 10px;
				border-bottom: 1px solid #e2e2e2;
			}
			a {
				font-size : 14px;
				letter-spacing: 1.4px;
				color: #808080;
				text-decoration: underline;
			}
			[class^='btn']{
				margin-top: 50px;
				a {
					text-decoration: none;
				}
				&.btn4 {
					a {
						padding: 19px 0 19px;
					}
				}
			}
			> ol {
			}
			figure {
				padding-top: 96px;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
				> .notes {
					padding-top: 19px;
					font-size: 11px;
					line-height: 1.574;
					letter-spacing: 1px;
					color: #666666;
				}
			}

			.blockText2 {
				display: block;
				flex-direction: row;
				margin-top: 97px;

				p:first-child{
					padding-top: 0;
				}
				figure {
					padding-top: 0;
					img, .notes {}
				}
				.notes {
					padding-top: 27px;
				}
				.order1 {
					order:0
				}
				.order2 {
					order:0
				}
				.text {

				}
				.flR {
					float: right;
					margin: 0 0 72px 72px;
				}
				.flL {
					float: left;
					margin: 0 72px 72px 0;
				}
				figure.flR,
				figure.flL{
					width: 370px;
				}
			}
			.blockText2{
				zoom:1;
			}

			.blockText2:after{
				content:"";
				display:block;
				clear:both;
			}

			.youtube {
				margin-top: 85px;
				padding-bottom: 56.25%;
				height: 0;
				position: relative;

				iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
			table {
				padding-top: 0;
				margin-top: 100px;
				display: table;
				border-collapse: collapse;
				width: 100%;
				tbody {
					display: table-row-group;
				}
				tr {
					display: table-row;
					border-top: 1px solid #e2e2e2;
					& + tr {
						border-top: 0;
					}
					th {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						background-color: #f6f5f6;
						padding: 18px 23px 18px 23px;
						font-weight: normal;
						text-align: left;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
					td {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						padding: 18px 23px 18px 23px;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
				}
			}
			.fwb {
				@include fontBold();
			}
			.lead {
			}
			.wrapSection {
			}
			.section {
				display: flex;
				flex-wrap: wrap;
				border-bottom: 1px solid #e2e2e2;
				border-left: 1px solid #e2e2e2;
				border-right: 1px solid #e2e2e2;
				&:first-child {
					border-top: 1px solid #e2e2e2;
				}
				.head {
					border-top: 0;
					border-bottom: 0;
					display: flex;
					flex: 0 0 180px;
					max-width: 180px;
					padding: 56px 25px 55px 25px;
					background-color: #f6f5f6;
					border-right: 1px solid #e2e2e2;
					border-bottom: 0;
					border-left: 0;
					.itemName {
						font-size: 16px;
						letter-spacing: 1px;
						@include fontBold();
						color: #191919;
						border-top: 0;
						&.itemNameStyle2 {
							font-size: 0;
							.text1 {
								display: block;
								vertical-align: top;
								font-size: 16px;
								letter-spacing: 1px;
							}
							.small {
								vertical-align: top;
								display: block;
								font-size: 13px;
								letter-spacing: 1px;
							}
						}
					}
				}
				.detail {
					flex:1 1 818px;
					max-width:818px;
					border: 0;
					padding: 56px 59px 55px 59px;
					h3 {
						padding-top: 35px;
						font-size: 16px;
						line-height: 1.2307;
						letter-spacing: 1.2px;
						color: #333333;
						&:first-child {
							padding-top: 0;
						}
						&.bdt1 {
							margin-top: 34px;
							padding-top: 38px;
							@include dotBorder(x, left top);
						}
					}
					p:first-child {
						padding-top: 0;
					}
					&.accordion {
						padding: 56px 59px 55px 59px;
						.client {
							.clientTitle {
								padding: 0;
								font-size: 15px;
								@include fontBold();
								letter-spacing: 0.75px;
								border-top: 0;
								border-bottom: 0;
								position: relative;
								cursor: auto;
								&:first-child {
									border-top: 0;
								}
								&.close {
									border-bottom: none;
									&:after {
										display: none;
									}
								}
								&.open {
									border-bottom: none;
									&:after {
										display: none;
									}
								}
							}
							.clientDetail {
								padding: 16px 0 47px;
								&:last-child {
									padding-bottom: 0;
								}
								.listStyle {
									margin-top: 0;
									li + li {
										margin-top: 9px;
									}
								}
							}

							.clientDetail.accDetail{
								display: block !important;
							}
						}
					}
				}
			}
		}
		.wrapMap {
			padding-top: 30px;
			.map {
				padding-top: 0;
				padding-bottom: 0;
				height: 400px;
				iframe {}
			}
		}
		.linkMap {
			.linkGoogleMap {
				padding-left: 22px;
			}
		}
		.contactList {
			li {
				margin-top: 3px;
				.text {
					font-size: 15px;
					letter-spacing: 1.5px;
					color: #333333;
				}

				&.telephone {
					&:before {
						margin-right: 15px;
						width: 17px;
						height: 17px;
						background-size:7px 17px;
					}
				}
				&.mail {
					&:before {
						margin-right: 15px;
						width: 17px;
						height: 11px;
						background-size:17px 11px;
					}

				}
			}
		}
	}
}

// --------------------
// arrow
// -------------------- */
a span.arrow{
	display: block;
	width: 37px;
	overflow: hidden;

	&:before{
		content: '';
		display: block;
		width: 37px;
		height: 7px;
		background-position: right center;
		background-image: url('assets/images/common/btn_arrow.svg');
	}

// PC style
@media print, screen and (min-width: $breakpoint1 +1){
	width: 37px;
	&:before{
		content: '';
		display: block;
		width: 37px;
		height: 7px;
	}
}
}

a:hover span.arrow{
	// PC style
	@media print, screen and (min-width: $breakpoint1 +1){
		&:before{
			transform: translateX(0);
			animation: arrowAnimation 500ms ease 0ms
		}
	}

}

@keyframes arrowAnimation {
	0% {
		transform: translateX(-0%);
	}
	50% {
		transform: translateX(100%);
	}
	51% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0%);
	}
}

// --------------------
// sectionRecruit
// --------------------
.sectionRecruit{

	background-position: center;
	background-size: cover;
	background-image: url('assets/images/home/recruit_bg.jpg');

	img{
		width: 100%;
	}

// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	padding-top: 80px;
	padding-bottom: 110px;

	.layoutSolid{
		position: relative;
		.head2{
		}
		>.text{
			margin: 35px 0 60px;
		}
		.image{
			margin-top: 50px;
		}
		.btn1{
		}
	}
}

// PC style
@media print, screen and (min-width: $breakpoint1){
	padding-top:    90px;
	padding-bottom: 160px;


	.layoutSolid{
		position: relative;

		.head2{
			// float: right;
			width: 300px;
			margin-left: auto;
		}
		>.text{
			// float: right;
			width: 300px;
			margin: 40px 0 40px auto;
			line-height: 1.8;
		}
		.image{
			position: absolute;
			top: 11px;
			// left: 50px;
			left: 0;
			width: 614px;
			// float: left;
		}
		.btn1{
			// float: right;
			margin-left: auto;
		}
	}


}
}

// --------------------
// sectionTop
// --------------------
.sectionTop{
// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	display: flex;
	flex-direction: column;


	.logo{
		order: 1;
		padding: 0 25px;
		padding-top: 100px;
		img{
			width: 247px;
			height: auto;
		}
	}
	.topics{
		order: 2;
		padding: 0 25px;
		margin-top: 40px;

		dl{
			display: flex;
		}
		dt{
			flex: 0 0 55px;

			@include dotBorderSp(y, right top);
			@include english(500);
			font-size: 10px;
		}
		dd{
			flex: 1 1 auto;
			padding-left: 15px;
			a{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				&:hover{
					opacity: 0.7;
				}
			}
			.category{
				background-color: #333;
				color: #fff;
				font-size: 10px;
				line-height: 1;
				text-align: center;
				padding: 3px 10px;
				margin-right: 15px;
			}
			.date{
				color: #a3a3a3;
				@include english();
				font-size: 10px;
			}
			.text{
				flex: 0 0 100%;
				max-width: 100%;
				color: #333;
				font-size: 11px;
				margin-top: 5px;
				text-decoration: underline;
			}
		}

	}
	.layoutLiquid{
		order: 3;
		margin-top: 40px;
		padding-bottom: 150px;
		position: relative;

		background-position: left top;
		background-size: contain;
		background-image: url('assets/images/home/top_bg2.svg');

		.head0{
			// position: absolute;
			height: 0;
			padding-top: 100%;
			position: relative;
			// top: 300px;
			// left: 440px;
			.title{
				position: absolute;
				top: 50%;
				left: 6vw;
				transform: translateY(-50%);

				@include english(400);
				font-size: 6.5vw;
				line-height: 1.5;
				em{
					@include fontBold();
					font-style: normal;
				}
			}
		}
		.catch{
			font-size: 14px;
			font-weight: bold;
			line-height: 29px;
			letter-spacing: 0.03em;
			margin-top: 50px;
			margin-bottom: 35px;
			color: #d6d6d6;
		}
		.copy{
			width: 50%;
			margin-left: 50%;
			background-position: left top;
			background-size: 125px auto;
			background-image: url('assets/images/home/top_icons.svg');

			ul{
				padding-top: 35px;
				padding-left: 5px;
			}
			li{
				@include english(400);
				font-size: 11px;
				color: #333;
			}
		}

		.arrowDrop{
			bottom: 0;
			left: 35%;
		}
	}

}

// PC style
@media print, screen and (min-width: $breakpoint1){
	position: relative;
	padding-top: 60px;
	padding-left: 200px;

	background-position: 200px 60px;
	background-size: auto;
	background-image: url('assets/images/home/top_bg1.svg');

	.layoutLiquid{
		height: 880px + 60px + $topAddMarin;
	}
	.topics{
		position: absolute;
		top: 60px;
		right: 60px;
		width: 80px + 275px;
		// background-color: #ddd;

		dl{
			display: flex;
		}
		dt{
			flex: 0 0 80px;
			max-width: 80px;

			@include dotBorder(y, right top);
			@include english(500);
			font-size: 13px;
		}
		dd{
			flex: 0 0 275px;
			max-width: 275px;
			padding-left: 30px;
			a{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				&:hover{
					opacity: 0.7;
				}
			}
			.category{
				background-color: #333;
				color: #fff;
				font-size: 11px;
				line-height: 1;
				text-align: center;
				padding: 3px 10px;
				margin-right: 15px;
			}
			.date{
				color: #a3a3a3;
				@include english();
				font-size: 13px;
			}
			.text{
				flex: 0 0 100%;
				max-width: 100%;
				color: #333;
				font-size: 11px;
				margin-top: 5px;
				text-decoration: underline;
			}

		}
	}

	.head0{
		position: absolute;
		top: 300px;
		left: 440px;
		.title{
			font-size: 70px;
			line-height: 90px;
			width: 900px;
			@include english(400);
			color: #333333;
			em{
				font-weight: 600;
				font-style: normal;
			}

			opacity: 0;
		}

		&.view .title{
			transform: translateX(100px);
			opacity: 0.0;

			animation: topTitleAnimation 800ms ease-in-out 500ms forwards;
		}


	}
	.catch{
		position: absolute;
		top: 605px;
		left: 625px;
		font-size: 18px;
		font-weight: bold;
		line-height: 40px;
		color: #d6d6d6;
	}
	.copy{
		position: absolute;
		width: 200px;
		top: 615px;
		left: 290px - 50px;
		padding-top: 50px;
		background-position: right top;
		background-size: 150px auto;
		background-image: url('assets/images/home/top_icons.svg');

		ul{
			text-align: right;
		}
		li{
			// @include english(300);
			// color: #d6d6d6;
			@include english(400);
			color: #333;
		}
	}

	.arrowDrop{
		top: 617px;
		left: 536px;
	}


}
}


@keyframes topTitleAnimation {
	0% {
		transform: translateX(100px);
		opacity: 0.0;
	}
	100% {
		transform: translateX(0);
		opacity: 1.0;
	}
}

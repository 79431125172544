// --------------------
// pageTitle
// --------------------
.pageTitle{
	background-position: left top, right top, right bottom, left bottom;
	background-size: 22px, 22px, 22px, 22px;
	background-image: url('assets/images/common/pagetitle_bg1.svg'), url('assets/images/common/pagetitle_bg2.svg'),  url('assets/images/common/pagetitle_bg3.svg'),  url('assets/images/common/pagetitle_bg4.svg');

	padding: 45px 25px 45px 25px;
	.main{
		display: block;
		@include english(500);
		font-size: 38px;
		line-height: 1;
	}
	.sub{
		display: block;
		@include fontBold();
		font-size: 15px;
		line-height: 1.6;
		margin-top: 10px;
	}
// SP style


// PC style
@media print, screen and (min-width: $breakpoint1){
	background-size: 32px, 32px, 32px, 32px;
	padding: 60px 75px 65px 75px;
	.main{
		font-size: 70px;
	}
	.sub{
		font-size: 17px;
		margin-top: 15px;
	}
}
}

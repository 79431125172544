// --------------------
// sectionRecruit
// --------------------
.sectionRecruit {
	// SP style
	padding-bottom: 104px;

	.pageTitle {
		margin-bottom: 58px;
		.title {
		.main {
			font-size: 23px;
				font-weight: bold;
				line-height: 1.5434;
				letter-spacing: 1.5px;
				color: #333333;
				@include YuGothic();
			}
		}
	}

	.postContent {
		p:first-child {
			padding-top: 0;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 1.4px;
			color: #333333;
		}
		strong {
			font-size: 14px;
			font-weight: bold;
			line-height: 2;
			letter-spacing: 1.4px;
		}
		a {

		}
		.bgOrange {
			background-color: #ec8e19;
			color: #FFFFFF;
		}
		> hr {
			margin-top: 30px;
			border:0;
			border-top:1px solid #D6D6D6;
		}
		.blockText {
			padding-top: 48px;
			b {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + b{
				display: block;
				padding-top: 20px;
			}
			strong {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + strong {

			}
		}
		.caution {
			font-size: 11px;
			line-height: 1.5714;
			letter-spacing: 1.1px;
			color: #666666;
		}
		.blockDetail {
			margin-top: 58px;
			background-color: #f6f5f6;
			padding: 32px 23px;
			p:not(:first-child){
				padding-top: 20px;
			}
			.text1 {
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 1px;
				color: #333333;
			}
			.text2 {
				padding-top: 20px;
			}
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		.tduOrange {
			color: #ec8e19;
			text-decoration: underline;
			.text {
				color: #333333;
			}
		}
		.bdbGray {
			padding-bottom: 10px;
			border-bottom: 1px solid #e2e2e2;
		}
		a {
			font-size : 14px;
			letter-spacing: 1.4px;
			color: #808080;
			text-decoration: underline;
		}
		[class^='btn']{
			margin-top: 50px;
			a {
				text-decoration: none;
				.text {
					color: #000;
				}
				.arrow {
				}
			}
		}
		figure {
			padding-top: 80px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			> .notes {
				padding-top: 17px;
				font-size: 11px;
				line-height: 1.574;
				letter-spacing: 1px;
				color: #666666;
			}
		}

		.blockText2 {
			display: flex;
			flex-direction: column;
			margin-top: 85px;

			p:first-child{
				padding-top: 0;
			}
			figure {
				img, .notes {}
			}
			> .notes {
				padding-top: 10px;
			}
			.order1 {
				order:1;
				padding-bottom: 35px;
			}
			.order2 {
				order:2;
			}
		}

		.youtube {
			margin-top: 85px;
			padding-bottom: 56.25%;
			height: 0;
			position: relative;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		table {
			padding-top: 88px;
			display: block;
			border-collapse: collapse;
			tbody {
				display: block;
			}
			tr {
				display: block;
				border-top: 1px solid #e2e2e2;
				& + tr {
					border-top: 0;
				}
				th {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					background-color: #f6f5f6;
					padding: 18px 23px 18px 23px;
					font-weight: normal;
					text-align: left;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
				td {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					padding: 18px 23px 18px 23px;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
			}
		}
		.fwb {
			font-weight:bold;
		}
		.lead {
			font-size: 14px;
			line-height: 1.7142;
			letter-spacing: 1.4px;
			color: #333333;
		}
		//config
		.colorOrange {color: #ec8e19;}
		.wrapSection {
			margin-top: 49px;
		}
		.section {
			.head {
				padding: 14px 12px 14px;
				background-color: #f6f5f6;
				border-top: 1px solid #e2e2e2;
				border-bottom: 1px solid #e2e2e2;
				.itemName {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 1.6px;
					color: #333;
				}
			}
			.detail {
				padding: 32px 0 49px;
				h3:first-child {
					padding-top: 0;
				}
				p:first-child {
					padding-top: 0;
				}
				h3 {
					padding-top: 36px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorderSp(x, left top);
					}
				}
			}
		}
	}
	.btn5 {
		margin-top: 30px;
		padding-top: 35px;
		border-top: 1px solid #e2e2e2;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 201px;

		.pageTitle {
			margin-bottom: 58px;
			.title {
				.main {
					font-size: 38px;
					line-height: 1.5789;
					letter-spacing: 1.42px;
				}
			}
		}

		.postContent {
			margin: auto auto 88px;
			p:first-child {
				padding-top: 0;
			}

			p {
				padding-top: 27px;
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing: 1.5px;
				color: #333333;
			}
			strong {
				font-size: 14px;
				font-weight: bold;
				line-height: 2;
				letter-spacing: 1.4px;
			}
			a {

			}
			.bgOrange {
				background-color: #ec8e19;
				color: #FFFFFF;
			}
			> hr {
				margin-top: 58px;
			}
			.blockText {
				padding-top: 58px;
				b {
					font-size: 14px;
					line-height: 2;
					font-weight: bold;
				}
				p + b{
					display: block;
					padding-top: 27px;
				}
				strong {
					font-size: 14px;
					line-height: 2;
					font-weight: bold;
				}
				p + strong {
					display: block;
					padding-top: 27px;
				}
			}
			.caution {
				font-size: 11px;
				line-height: 1.5714;
				letter-spacing: 1.1px;
				color: #666666;
			}
			.blockDetail {
				margin-top: 60px;
				background-color: #f6f5f6;
				padding: 46px 50px;
				p:not(:first-child){
					padding-top: 20px;
				}
				li + li {
					padding-top: 0;
					margin-top: 15px;
				}
				.text1 {
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 1px;
					color: #333333;
				}
				.text2 {
					padding-top: 20px;
				}
			}
			.bdb {
				padding-bottom: 26px;
				border-bottom: 1px solid #e2e2e2;
			}
			.tduOrange {
				color: #ec8e19;
				text-decoration: underline;
				.text {
					color: #333333;
				}
			}
			.bdbGray {
				padding-bottom: 10px;
				border-bottom: 1px solid #e2e2e2;
			}
			a {
				font-size : 14px;
				letter-spacing: 1.4px;
				color: #808080;
				text-decoration: underline;
			}
			[class^='btn']{
				margin-top: 50px;
				a {
					text-decoration: none;
					.text {
						color: #000;
					}
					.arrow {
					}
				}
			}
			> ol {
			}
			figure {
				padding-top: 96px;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
				> .notes {
					padding-top: 19px;
					font-size: 11px;
					line-height: 1.574;
					letter-spacing: 1px;
					color: #666666;
				}
			}

			.blockText2 {
				display: block;
				flex-direction: row;
				margin-top: 97px;

				p:first-child{
					padding-top: 0;
				}
				figure {
					padding-top: 0;
					img, .notes {}
				}
				.notes {
					padding-top: 27px;
				}
				.order1 {
					order:0
				}
				.order2 {
					order:0
				}
				.text {

				}
				.flR {
					float: right;
					margin: 0 0 72px 72px;
				}
				.flL {
					float: left;
					margin: 0 72px 72px 0;
				}
				figure.flR,
				figure.flL{
					width: 370px;
				}
			}
			.blockText2{
				zoom:1;
			}

			.blockText2:after{
				content:"";
				display:block;
				clear:both;
			}

			.youtube {
				margin-top: 85px;
				padding-bottom: 56.25%;
				height: 0;
				position: relative;

				iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
			table {
				padding-top: 0;
				margin-top: 100px;
				display: table;
				border-collapse: collapse;
				width: 100%;
				tbody {
					display: table-row-group;
				}
				tr {
					display: table-row;
					border-top: 1px solid #e2e2e2;
					& + tr {
						border-top: 0;
					}
					th {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						background-color: #f6f5f6;
						padding: 18px 23px 18px 23px;
						font-weight: normal;
						text-align: left;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
					td {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						padding: 18px 23px 18px 23px;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
				}
			}
			.fwb {
				font-weight:bold;
			}
			.lead {
			}
			.wrapSection {
				margin-top: 86px;
			}
			.section {
				display: flex;
				flex-wrap: wrap;
				border-bottom: 1px solid #e2e2e2;
				border-left: 1px solid #e2e2e2;
				border-right: 1px solid #e2e2e2;
				&:first-child {
					border-top: 1px solid #e2e2e2;
				}
				.head {
					border-top: 0;
					border-bottom: 0;
					display: flex;
					flex: 0 0 25.9%;
					max-width: 25.9%;
					padding: 56px 30px 55px 30px;
					background-color: #f6f5f6;
					border-right: 1px solid #e2e2e2;
					border-bottom: 0;
					border-left: 0;
					.itemName {
						font-size: 16px;
						letter-spacing: 1px;
						font-weight: bold;
						color: #191919;
						border-top: 0;
					}
				}
				.detail {
					flex:1 1 74.1%;
					max-width: 74.1%;
					border: 0;
					padding: 56px 54px 55px;
					h3 {
						padding-top: 35px;
						font-size: 16px;
						line-height: 1.2307;
						letter-spacing: 1.2px;
						color: #333333;
						&:first-child {
							padding-top: 0;
						}
						&.bdt1 {
							margin-top: 34px;
							padding-top: 38px;
							@include dotBorder(x, left top);
						}
					}
					p:first-child {
						padding-top: 0;
					}
				}
			}
		}
		.btn5 {
			padding-top: 0;
			border-top: 0;
			margin:67px 0 0;
		}
	}
}
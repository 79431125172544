// --------------------
// sectionProfile
// --------------------
.sectionProfile{
	position: relative;

	.slick-dots{
		z-index: 150;
		position: absolute;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		li{
			margin: 0 10px;
		}
		button{
			font-size: 0;
			display: block;
			width: 13px;
			height: 13px;
			border: 1px solid #fff;
			border-radius: 50%;
			background-color: rgba(#fff, 0.0);
		}
		.slick-active button{
			background-color: #fff;
		}
	}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.slick-dots{
		bottom: 20px;
	}

	.headWrap{
		position: absolute;
		// width: 100vw;
		// top: 50vh;
		// top: 50%;
		top: 0;
		left: 25px;
		padding-top: 12.5%;

		// transform: translateY(-50%);
		color: #fff;
		z-index: 100;

		@media print, screen and (max-width: 359px){
			padding-top: 8%;
		}
		.head2{

		}
	}

	.profileWrap{
	}

	.layoutSolid{
		padding-top:    33px;
		padding-bottom: 72px;
	}
	.companyTitle{
		font-size: 19px;
		margin-bottom: 40px;
	}
	.ceoWrap{
		padding-bottom: 40px;
		@include dotBorderSp(x, left bottom);

		.ceo{
			padding-bottom: 40px;
			.position{
				font-size: 11px;
				padding-right: 2em;
			}
			.name{
				font-size: 16px;
			}
		}
		.btn1{

		}
	}
	.address{
		padding: 30px 0;
		.address1{
			font-size: 14px;
			margin-bottom: 10px;
		}
		.address2{
			@include english(300);
			font-size: 12px;
			color: #a3a3a3;
		}
	}
	.map{
		display: flex;
		align-items: center;
		margin-bottom: 60px;

		&:before{
			content: '';
			display: block;
			width: 14px;
			height: 20px;
			background-size: contain;
			background-position: center;
			background-image: url('assets/images/common/icon_map2.svg');
			margin-right: 10px;
		}
		span{
			@include english();
			font-size: 15px;
			color: #333;
			text-decoration: underline;
		}

	}
	.btns{
		.btn1{
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}


	.images{
		// position: sticky;
		// top:0;
		// height: 100vh;
		position: relative;
		width: 100%;
		overflow: hidden;

		li{
			overflow: hidden;
		}
		img,
		picture {
			width: 100%;
			height: auto;
			// min-width: 100vw;
			// min-height: 100vh;
		}
	}

	.postContent {
		p:first-child {
			padding-top: 0;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 0.7px;
			color: #333333;
			&.addressEnglish {
				padding-top:6px;
				color: #a2a2a2;
				@include english(400);
			}
		}
		strong {
			font-size: 14px;
			font-weight: bold;
			line-height: 2;
			letter-spacing: 1.4px;
		}
		a {

		}
		.bgOrange {
			background-color: #ec8e19;
			color: #FFFFFF;
		}
		> hr {
			margin-top: 30px;
			border:0;
			border-top:1px solid #D6D6D6;
		}
		.blockText {
			b {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + b{
				display: block;
				padding-top: 20px;
			}
			strong {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + strong {

			}
		}
		.caution {
			font-size: 11px;
			line-height: 1.5714;
			letter-spacing: 1.1px;
			color: #666666;
		}
		.blockDetail {
			margin-top: 58px;
			background-color: #f6f5f6;
			padding: 32px 23px;
			p:not(:first-child){
				padding-top: 20px;
			}
			.text1 {
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 1px;
				color: #333333;
			}
			.text2 {
				padding-top: 20px;
			}
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		.tduOrange {
			color: #ec8e19;
			text-decoration: underline;
			.text {
				color: #333333;
			}
		}
		.bdbGray {
			padding-bottom: 10px;
			border-bottom: 1px solid #e2e2e2;
		}
		a {
			font-size : 14px;
			letter-spacing: 1.4px;
			color: #808080;
			text-decoration: underline;
		}
		[class^='btn']{
			margin-top: 50px;
			a {
				text-decoration: none;
				.text {
					color: #000;
				}
				.arrow {
				}
			}
			&.btn4 {
				margin-top: 0;
				a {
					padding: 13px 0 19px;
					.text {
					}
				}
			}
		}
		figure {
			padding-top: 80px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			> .notes {
				padding-top: 17px;
				font-size: 11px;
				line-height: 1.574;
				letter-spacing: 1px;
				color: #666666;
			}
		}

		.blockText2 {
			display: flex;
			flex-direction: column;
			margin-top: 85px;

			p:first-child{
				padding-top: 0;
			}
			figure {
				img, .notes {}
			}
			> .notes {
				padding-top: 10px;
			}
			.order1 {
				order:1;
				padding-bottom: 35px;
			}
			.order2 {
				order:2;
			}
		}

		.youtube {
			margin-top: 85px;
			padding-bottom: 56.25%;
			height: 0;
			position: relative;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		table {
			padding-top: 88px;
			display: block;
			border-collapse: collapse;
			tbody {
				display: block;
			}
			tr {
				display: block;
				border-top: 1px solid #e2e2e2;
				& + tr {
					border-top: 0;
				}
				th {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					background-color: #f6f5f6;
					padding: 18px 23px 18px 23px;
					font-weight: normal;
					text-align: left;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
				td {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					padding: 18px 23px 18px 23px;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
			}
		}
		.fwb {
			font-weight: bold;
		}
		.lead {
			font-size: 14px;
			line-height: 1.7142;
			letter-spacing: 1.4px;
			color: #333333;
		}
		//config
		.colorOrange {color: #ec8e19;}
		.wrapSection {
			margin-top: 0;
			border-bottom: 0;
		}
		.section {
			.head {
				padding: 14px 42px 14px;
				background-color: #f6f5f6;
				border-top: 1px solid #e2e2e2;
				border-bottom: 1px solid #e2e2e2;
				.itemName {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 1.6px;
					color: #333;
					&.itemNameStyle2 {
						font-size: 0;
						.text1 {
							display: inline-block;
							vertical-align: middle;
							font-size: 16px;
						}
						.small {
							vertical-align: middle;
							display: inline-block;
							font-size: 12px;
							font-weight: 400;
							letter-spacing : 2.4px;
							color : #333333;
						}
					}
				}
			}
			.detail {
				padding: 32px 30px 32px;
				h3:first-child {
					padding-top: 0;
				}
				p:first-child {
					padding-top: 0;
				}
				h3 {
					padding-top: 36px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorderSp(x, left top);
					}
				}
				&.accordion {
					padding: 0 30px 32px;
					.client {
						.clientTitle {
							padding: 14px 25px 14px 0;
							font-size: 13px;
							letter-spacing: 1px;
							color: #333333;
							border-top: 1px solid #dddddd;
							// border-bottom: 1px solid #dddddd;
							position: relative;
							cursor: pointer;
							&:first-child {
								border-top: 0;
							}
							&.close {
								border-bottom: 1px solid #fff;
								&:after {
									position: absolute;
									right:20px;
									top:50%;
									content:'';
									display: block;
									width: 10px;
									height: 10px;
									background-image: url(/wp-content/themes/gempartners_0.1/assets/images/common/btn_burger_close.svg);
									background-size:10px 10px;
									background-repeat: no-repeat;
									transform:rotate(45deg) translate(0,-50%);
								}
							}
							&.open {
								border-bottom: 1px solid #dddddd;
								&:after {
									position: absolute;
									right:15px;
									top:50%;
									content:'';
									display: block;
									width: 13px;
									height: 1px;
									background-image: none;
									transform:rotate(0deg) translate(0,1px);
									background-color: #222;
								}
							}
						}
						.clientDetail {
							padding: 24px 0 30px;
							&:last-child {
								padding-bottom: 0;
							}
							.listStyle {
								margin-top: 0;
							}
						}

						.clientDetail.accDetail{
							display: none;
						}
					}
				}
			}
		}
		.section2 {
			padding-top: 12px;
			& + .section2 {
				border-top: 1px solid #e2e2e2;
			}
			&:last-child .detail.accordion {
				padding-bottom: 0;
			}
			.head {
				padding: 5px 0 5px 0;
				//background-color: #f6f5f6;
				//border-top: 1px solid #e2e2e2;
				//border-bottom: 1px solid #e2e2e2;
				.itemName {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.5px;
					color: #333;

					&.itemNameStyle2 {
						font-size: 0;
						.text1 {
							display: inline-block;
							vertical-align: middle;
							font-size: 16px;
							letter-spacing: 0.4px;
							color: #333333;
						}
						.small {
							vertical-align: middle;
							display: inline-block;
							font-size: 12px;
							letter-spacing : 2.4px;
							color : #333333;
						}
					}
				}
			}
			.detail {
				padding: 6px 0 16px 0;
				h3:first-child {
					padding-top: 0;
				}
				p:first-child {
					padding-top: 0;
				}
				h3 {
					padding-top: 36px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorderSp(x, left top);
					}
				}
				>.listStyle {
					padding-bottom: 3px;
					li + li {
						margin-top: 9px;
					}
				}
				.listStyle2 {
					li {
						padding-left: 19px;
						font-size: 14px;
						line-height: 1.7142;
						letter-spacing: 0.7px;
						color: #333333;
					}
				}
				&.accordion {
					padding: 8px 0 32px;
					.client {
						.clientTitle {
							padding: 14px 25px 14px 0;
							font-size: 13px;
							letter-spacing: 1px;
							color: #333333;
							border-top: 1px solid #bcbcbc;
							// border-bottom: 1px solid #dddddd;
							position: relative;
							cursor: pointer;
							&:first-child {
								border-top: 1px solid #bcbcbc;
							}
							&.close {
								border-bottom: 1px solid #fff;
								&:after {
									position: absolute;
									right:20px;
									top:50%;
									content:'';
									display: block;
									width: 10px;
									height: 10px;
									background-image: url(/wp-content/themes/gempartners_0.1/assets/images/common/btn_burger_close.svg);
									background-size:10px 10px;
									background-repeat: no-repeat;
									transform:rotate(45deg) translate(0,-50%);
								}
							}
							&.open {
								border-bottom: 1px solid #bcbcbc;
								&:after {
									position: absolute;
									right:15px;
									top:50%;
									content:'';
									display: block;
									width: 13px;
									height: 1px;
									background-image: none;
									transform:rotate(0deg) translate(0,1px);
									background-color: #222;
								}
							}
						}
						.clientDetail {
							padding: 21px 0 30px;
							&:last-child {
								padding-bottom: 0;
							}
							.listStyle {
								margin-top: 0;
							}
						}

						.clientDetail.accDetail{
							display: none;
						}
					}
				}
			}
		}
	}
	.wrapMap {
		padding-top: 21px;
		.map {
			position: relative;
			border: 0;
			height: 0;
			padding-bottom: 65.555%;
			overflow: hidden;
			padding-top: 55px;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.linkMap {
		display: flex;
		justify-content: flex-end;
		.linkGoogleMap {
			margin-top: 13px;
		}
	}
	.contactList {
		li {
			margin-top: 7px;
			display: block;
			&:first-child {
				margin-top: 0;
			}
			.text {
				font-size: 14px;
				letter-spacing: 1.4px;
				color: #333333;
			}
			&.telephone {
				display: flex;
				align-items: center;
				&:before {
					content:'';
					display: block;
					margin-right: 12px;
					width: 19px;
					height: 19px;
					text-align: center;
					background-image: url(assets/images/common/icon_telephone.svg);
					background-position: center 0;
					background-repeat: no-repeat;
					background-size:8px 18px;
				}
			}
			&.mail {
				display: flex;
				align-items: center;
				&:before {
					display: none;
				}
			}
		}
	}

}



// PC style
@media print, screen and (min-width: $breakpoint1){

	.headWrap{
		position: absolute;
		width: 1000px;

		// top: 50vh;
		left: 50%;
		top: 50%;
		margin-left: 75px;
		// transform: translateY(-50%);
		transform: translateY(-50%) translateX(-50%);
		color: #fff;
		z-index: 100;
		.head2{
			// position: absolute;
			// top: 50vh;
			// left: 100px;
			// transform: translateY(-50%);
			// color: #fff;
		}
	}
	.profileWrap{
		padding-left: 200px;
	}

	.layoutSolid{
		overflow: hidden;
		padding-top:    94px;
		//padding-bottom: 160px;
	}
	.companyTitle{
		// width: 650px;
		float: left;
		// @import notoBold();
		font-size: 23px;
	}
	.ceoWrap{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 35px;

		@include dotBorder(x, left bottom);

		.ceo{
			flex: 0 0 300px;

			.position{
				font-size: 13px;
				padding-right: 2em;
			}
			.name{
				font-size: 17px;
			}
		}
		.btn1{
			flex: 0 0 300px;
		}
	}
	// .ceo{
	// 	// width: 650px;
	// 	// float: right;
	// 	// padding-bottom: 20px;
	//
	//
	// }
	.address{
		width: 650px;
		float: right;
		padding: 20px 0;
		display: flex;
		// align-items: center;
		justify-content: space-between;
		.address1{
			flex: 0 0 300px;
			font-size: 15px;
		}
		.address2{
			flex: 0 0 300px;
			@include english(300);
			font-size: 14px;
			color: #a3a3a3;
		}
	}
	.map{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		&:before{
			content: '';
			display: block;
			width: 14px;
			height: 20px;
			background-size: contain;
			background-position: center;
			background-image: url('assets/images/common/icon_map2.svg');
			margin-right: 10px;
		}
		span{
			@include english();
			font-size: 14px;
			color: #333;
			text-decoration: underline;
		}
		a:hover{
			opacity: 0.7;
		}
	}
	.btns{
		width: 650px;
		float: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn1{
			flex: 0 0 300px;
		}
	}


	>.images{
		// position: sticky;
		// top:0;
		// height: 100vh;
		position: relative;
		width: 100%;
		overflow: hidden;

		li{
			overflow: hidden;
		}
		img,
		picture {
			width: 100%;
			height: auto;
			// width: auto;
			// height: auto;
			// min-width: 100vw;
			// min-height: 100vh;
		}
	}

	.postContent {
		p:first-child {
			padding-top: 0;
		}

		p {
			padding-top: 27px;
			font-size: 15px;
			line-height: 1.7333;
			letter-spacing: 1.5px;
			color: #333333;
			&.addressEnglish {
				padding-top:13px;
				font-size: 15px;
				letter-spacing: 0.75px;
				color: #a2a2a2;
			}
			+ .listStyle {
				margin-top: 16px;
			}
		}
		strong {
			font-size: 14px;
			font-weight: bold;
			line-height: 2;
			letter-spacing: 1.4px;
		}
		a {

		}
		.bgOrange {
			background-color: #ec8e19;
			color: #FFFFFF;
		}
		> hr {
			margin-top: 58px;
		}
		.blockText {
			b {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + b{
				display: block;
				padding-top: 27px;
			}
			strong {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + strong {
				display: block;
				padding-top: 27px;
			}
		}
		.caution {
			font-size: 11px;
			line-height: 1.5714;
			letter-spacing: 1.1px;
			color: #666666;
		}
		.blockDetail {
			margin-top: 60px;
			background-color: #f6f5f6;
			padding: 46px 50px;
			p:not(:first-child){
				padding-top: 20px;
			}
			li + li {
				padding-top: 0;
				margin-top: 15px;
			}
			.text1 {
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 1px;
				color: #333333;
			}
			.text2 {
				padding-top: 20px;
			}
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		.tduOrange {
			color: #ec8e19;
			text-decoration: underline;
			.text {
				color: #333333;
			}
		}
		.bdbGray {
			padding-bottom: 10px;
			border-bottom: 1px solid #e2e2e2;
		}
		a {
			font-size : 14px;
			letter-spacing: 1.4px;
			color: #808080;
			text-decoration: underline;
		}
		[class^='btn']{
			margin-top: 50px;
			a {
				text-decoration: none;
			}
			&.btn4 {
				a {
					padding: 19px 0 19px;
				}
			}
		}
		> ol {
		}
		figure {
			padding-top: 96px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			> .notes {
				padding-top: 19px;
				font-size: 11px;
				line-height: 1.574;
				letter-spacing: 1px;
				color: #666666;
			}
		}

		.blockText2 {
			display: block;
			flex-direction: row;
			margin-top: 97px;

			p:first-child{
				padding-top: 0;
			}
			figure {
				padding-top: 0;
				img, .notes {}
			}
			.notes {
				padding-top: 27px;
			}
			.order1 {
				order:0
			}
			.order2 {
				order:0
			}
			.text {

			}
			.flR {
				float: right;
				margin: 0 0 72px 72px;
			}
			.flL {
				float: left;
				margin: 0 72px 72px 0;
			}
			figure.flR,
			figure.flL{
				width: 370px;
			}
		}
		.blockText2{
			zoom:1;
		}

		.blockText2:after{
			content:"";
			display:block;
			clear:both;
		}
		.blockText3 {
			display: flex;
			justify-content: space-between;
		}
		.youtube {
			margin-top: 85px;
			padding-bottom: 56.25%;
			height: 0;
			position: relative;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		table {
			padding-top: 0;
			margin-top: 100px;
			display: table;
			border-collapse: collapse;
			width: 100%;
			tbody {
				display: table-row-group;
			}
			tr {
				display: table-row;
				border-top: 1px solid #e2e2e2;
				& + tr {
					border-top: 0;
				}
				th {
					display: table-cell;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					background-color: #f6f5f6;
					padding: 18px 23px 18px 23px;
					font-weight: normal;
					text-align: left;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
				td {
					display: table-cell;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					padding: 18px 23px 18px 23px;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
			}
		}
		.fwb {
			font-weight: bold;
		}
		.lead {
		}
		.wrapSection {
		}
		.listStyle li {
			padding-left: 24px;
		}
		.section {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #e2e2e2;
			border-left: 1px solid #e2e2e2;
			border-right: 1px solid #e2e2e2;
			&:first-child {
				border-top: 1px solid #e2e2e2;
			}
			.head {
				border-top: 0;
				border-bottom: 0;
				display: flex;
				flex: 0 0 180px;
				max-width: 180px;
				padding: 56px 25px 55px 25px;
				background-color: #f6f5f6;
				border-right: 1px solid #e2e2e2;
				border-bottom: 0;
				border-left: 0;
				.itemName {
					font-size: 16px;
					letter-spacing: 1px;
					font-weight: bold;
					color: #191919;
					border-top: 0;
					&.itemNameStyle2 {
						font-size: 0;
						.text1 {
							display: block;
							vertical-align: top;
							font-size: 16px;
							letter-spacing: 1px;
						}
						.small {
							vertical-align: top;
							display: block;
							font-size: 13px;
							letter-spacing: 1px;
						}
					}
				}
			}
			.detail {
				flex:1 1 818px;
				max-width:818px;
				border: 0;
				padding: 56px 59px 55px 59px;
				h3 {
					padding-top: 35px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorder(x, left top);
					}
				}
				p:first-child {
					padding-top: 0;
				}
				&.accordion {
					padding: 56px 59px 55px 59px;
					.client {
						.clientTitle {
							padding: 0;
							font-size: 15px;
							font-weight: bold;
							letter-spacing: 0.75px;
							border-top: 0;
							border-bottom: 0;
							position: relative;
							cursor: auto;
							&:first-child {
								border-top: 0;
							}
							&.close {
								border-bottom: none;
								&:after {
									display: none;
								}
							}
							&.open {
								border-bottom: none;
								&:after {
									display: none;
								}
							}
						}
						.clientDetail {
							padding: 16px 0 47px;
							&:last-child {
								padding-bottom: 0;
							}
							.listStyle {
								margin-top: 0;
								li + li {
									margin-top: 9px;
								}
							}
						}

						.clientDetail.accDetail{
							display: block !important;
						}
					}
				}
			}
		}
		.section2 {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #e2e2e2;
			//border-left: 1px solid #e2e2e2;
			//border-right: 1px solid #e2e2e2;
			&:first-child {
				border-top: 0;
			}
			.head {
				border-top: 0;
				border-bottom: 0;
				display: flex;
				justify-content: flex-end;
				flex: 0 0 183px;
				max-width: 183px;
				padding: 19px 0 17px 10px;
				border-left: 0;
				.itemName {
					text-align: right;
					font-size: 16px;
					letter-spacing: 0.8px;
					font-weight: bold;
					color: #333333;
					border-top: 0;
					&.itemNameStyle2 {
						font-size: 0;
						.text1 {
							display: block;
							vertical-align: top;
							font-size: 16px;
							letter-spacing: 1px;
						}
						.small {
							vertical-align: top;
							display: block;
							font-size: 13px;
							letter-spacing: 1px;
						}
					}
				}
			}
			.detail {
				flex:1 1 817px;
				max-width:817px;
				border: 0;
				padding: 19px 10px 17px 89px;
				h3 {
					padding-top: 35px;
					font-size: 16px;
					line-height: 1.2307;
					letter-spacing: 1.2px;
					color: #333333;
					&:first-child {
						padding-top: 0;
					}
					&.bdt1 {
						margin-top: 34px;
						padding-top: 38px;
						@include dotBorder(x, left top);
					}
				}
				p:first-child {
					padding-top: 0;
				}
				>.listStyle {
					letter-spacing: 0.7px;
				}
				&.accordion {
					//padding: 56px 59px 55px 59px;
					.client {
						.clientTitle {
							padding: 0 0 0 24px;
							font-size: 15px;
							font-weight: bold;
							letter-spacing: 0.75px;
							line-height: 1.6428;
							border-top: 0;
							border-bottom: 0;
							position: relative;
							cursor: auto;
							color : #333333;
							&:before {
								content: '';
								position: absolute;
								left: 0;
								top: 8px;
								width: 12px;
								height: 12px;
								font-size: 14px;
								line-height: 1.6428;
								letter-spacing: 1.5px;
								color: #EC8E19;
								background-position: left top;
								background-repeat: no-repeat;
								background-size: 10px 10px;
								background-image: url(assets/images/common/icon_list.svg);
							}
							&:first-child {
								border-top: 0;
							}
							&.close {
								border-bottom: none;
								&:after {
									display: none;
								}
							}
							&.open {
								border-bottom: none;
								&:after {
									display: none;
								}
							}
						}
						.clientDetail {
							padding: 6px 0 15px;
							display: flex;
							&:last-child {
								padding-bottom: 0;
							}
							.listStyle {
								margin-top: 0;
								li + li {
									margin-top: 9px;
								}
							}
						}
						.clientDetail.accDetail{
							display: flex !important;
						}
						.listStyle2 {
							flex-basis: 375px;
							max-width: 375px;
							& + .listStyle2 {
								flex-basis: 355px;
								max-width: 355px;
							}
							li {
								padding-left: 0;
								font-size: 14px;
								line-height: 24px;
								letter-spacing: 0;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	.wrapMap {
		padding-top: 30px;
		.map {
			padding-top: 0;
			padding-bottom: 0;
			height: 400px;
			iframe {}
		}
	}
	.linkMap {
		margin-right: 60px;
		.linkGoogleMap {
			margin-top: 0;
			padding-left: 22px;
		}
	}
	.contactList {
		li {
			margin-top: 3px;
			.text {
				font-size: 15px;
				letter-spacing: 1.5px;
				color: #333333;
			}

			&.telephone {
				&:before {
					margin-right: 15px;
					width: 17px;
					height: 17px;
					background-size:7px 17px;
				}
			}
			&.mail {
				&:before {
					margin-right: 15px;
					width: 17px;
					height: 11px;
					background-size:17px 11px;
				}

			}
		}
	}

}
}

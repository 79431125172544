// --------------------
// topHeader
// --------------------
#topHeader{
// common
	a:hover{
		text-decoration: none;
	}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 200;
	background-color: #fff;
	border-bottom: 1px solid #e2e2e2;

	height: 66px;
	// overflow: hidden;

	.wrapper{
		// position: relative;
	}
	.siteId{
		display: none;
	}
	.headerNav1{
		// display: none;

		// display: block;
		display: flex;
		align-items: center;
		justify-content: center;

		position: fixed;
		z-index: 201;
		width: 100vw;
		// height: 100vh;
		height: 0;
		overflow: hidden;

		background: #fff;
		top: 0;
		left: 0;

		transition-duration: 0ms;

		ul{
			margin-top: -45px;
			li{
			}
			a{
				display: block;
				padding: 13px 0;
				text-align: center;
			}
			span{
				@include english(500);
				font-size: 22px;
				line-height: 1;
			}
		}

		.burgerClose{
			position: absolute;
			top: 8px;
			right: 8px;
			width: 50px;
			height: 50px;
			background-size: 19px 19px;
			background-position: center;
			background-image: url('assets/images/common/btn_burger_close.svg');
			cursor: pointer;

		}
		.burgerLogo{
			position: absolute;
			top: 27px;
			left: 20px;
			width: 100px;
			height: 13px;
			background-size: contain;
			background-position: center;
			background-image: url('assets/images/common/logo3.svg');
		}

	}
	.headerNav2{
		position: absolute;
		top: 50%;
		right: 70px;
		transform: translateY(-50%);
		width: 120px;

		ul{
			display: flex;
		}
		a{
			display: block;
			width: 34px;
			height: 29px;
			margin: 0;
			background-color: #fff;
			text-align: center;
			span{
				@include english(500);
				font-size : 10px;
				line-height : 1
			}
		}
		li{
			margin: 0 5px;
			&.map{
				a{
					&:before{
						content: '';
						display: block;
						width: 24px;
						height: 29px;
						margin: 0 auto;

						background-size: contain;
						background-position: center center;
						background-image: url('assets/images/common/icon_map1.svg');
					}
					span{
						display: none;
					}
				}
			}
			&.jp{
				a{
					padding-top: 3px;
					background-color: #333;
					span{
						color: #fff;
						line-height: 1;
					}
				}
			}
			&.en{
				a{
					padding-top: 3px;
					background-color: #fff;
					span{
						line-height: 1;
					}
				}
			}
		}
	}
	.burgerOpen{
		position: absolute;
		width: 50px;
		height: 50px;
		top: 8px;
		right: 8px;
		cursor: pointer;
		background-size: 25px 22px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('assets/images/common/btn_burger_open.svg');
	}

	// burgerMenu Open
	&.open{
		.headerNav1{
			height: 100vh;
		}
	}
}








// PC style
@media print, screen and (min-width: $breakpoint1){
	$headMargin : 40px;
	.wrapper{
		position: relative;
		// position: fixed;
		// top: 0;
		// left: 0;
		// // width: 100%;
		// z-index: 100;

		&:before{
			content: '';
			display: block;
			height: 100vh;
			width: 1px;
			border-right: 1px solid #e2e2e2;
			position: fixed;
			top: 0;
			left: 120px - $headMargin;
			z-index: 100;
		}
	}
	.siteId{
		position: fixed;
		top: 55px;
		left: 103px - $headMargin;
		z-index: 120;
		width: 286px;

		a{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		img{
			display: block;
			flex: 0 0 37px;
			max-width: 37px;
			height: auto;
		}
		svg{
			display: block;
			flex: 0 0 237px;
			max-width: 237px;
			height: auto;
			g{
				transition-duration: 500ms;
			}
		}
	}
	.headerNav1{
		position: fixed;
		top: 185px;
		left: 120px - $headMargin;
		transform: translateX(-50%);
		z-index: 121;
		text-shadow:
			1px 1px 1px rgba(#fff,1.0),
			-1px 1px 1px rgba(#fff,1.0),
			1px -1px 1px rgba(#fff,1.0),
			-1px -1px 1px rgba(#fff,1.0),
			1px 1px 0px rgba(#fff,1.0),
			-1px 1px 0px rgba(#fff,1.0),
			1px -1px 0px rgba(#fff,1.0),
			-1px -1px 0px rgba(#fff,1.0);

		ul{
		}
		li{
			position: relative;
			width: 100px;
			&:before{
				content: '';
				display: block;
				width: 11px;
				height: 1px;
				border-top: 1px solid #bcbcbc;
				margin: 6px auto 3px;
			}
			&:first-child{
				&:before{
					border-top: 2px solid $colorOrange;
					margin-top: 5px;
				}
			}


		}
		a{
			display: block;
			text-align: center;
			&:hover{
				opacity: 0.7;
				span{
					text-decoration: underline;
				}
			}
		}
		span{
			@include english(500);
			font-size : 13px;
			line-height : 1;
			letter-spacing : 0.65px;
			color : #000000;
			transition-duration: 500ms;
		}
	}
	.headerNav2{
		position: fixed;
		top: 510px;
		// top: 185px + 300px;
		left: 120px - $headMargin;
		transform: translateX(-50%);
		z-index: 122;
		li{
			&.map{
				// margin-top: 30px;
				&:before{
					display: none;
				}
				a{
					width: 43px;
					height: 34px;
					margin: 0 auto;
					background-color: #fff;
					&:before{
						content: '';
						display: block;
						width: 43px;
						height: 34px;

						background-size: auto;
						background-position: center center;
						background-image: url('assets/images/common/icon_map1.svg');
					}
					span{
						display: none;
					}
					&:hover{
						opacity: 0.7;
					}
				}
			}
			&.jp{
				margin-top: 18px;
				&:before{
					display: none;
				}
				a{
					width: 43px;
					height: 34px;
					margin: 0 auto;
					padding-top: 3px;
					background-color: #333;
					span{
						color: #fff;
						line-height: 1;
					}
					&:hover{
						opacity: 0.7;
					}
				}
			}
			&.en{
				margin-top: 18px;
				&:before{
					display: none;
				}
				a{
					width: 43px;
					height: 34px;
					margin: 0 auto;
					padding-top: 3px;
					background-color: #fff;
					span{
						line-height: 1;
					}
					&:hover{
						opacity: 0.7;
					}
				}
			}
		}
		a{
			display: block;
			text-align: center;
			&:hover{
				opacity: 0.7;
				span{
					text-decoration: underline;
				}
			}
		}
		span{
			@include english(500);
			font-size : 13px;
			line-height : 1;
			letter-spacing : 0.65px;
			color : #000000;
			transition-duration: 500ms;
		}
	}
	.burgerOpen,
	.burgerClose,
	.burgerLogo{
		display: none;
	}
}

&.dark{

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.siteId{
			svg g{
				fill: #fff !important;
			}
		}
		.headerNav1{
			li{
			// li:not(.en):not(.jp):not(.map){
				span{
					color: #fff;
				}
			}
		}
	}
}
}

// --------------------
// sectionInduction
// --------------------
.sectionInduction {
	// SP style
	padding-bottom: 150px;

	>.text {
		border-top: 1px solid #898989;
		padding-top: 88px;
		padding-bottom: 22px;
		text-align: center;
		font-size: 15px;
		line-height: 1.6666;
		letter-spacing: 1.5px;
		color: #333333;
	}

	.btn2 a {
		background-position: -1px center;
		background-color: #cc3636;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		width: 1020px;

		>.text {
			padding-top: 96px;
			padding-bottom: 61px;
			font-size: 21px;
			letter-spacing: 0.78px;
			color: #333333;
		}

		.btn2 {
			margin: auto;
		}
	}
}

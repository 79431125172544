// --------------------
// layout
// --------------------
$pcMargin : 50px;
$spMargin : 30px;

// $pcMarginLeft: 125px;

// 画面幅最大レイアウト
.layoutFull{
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	// background-color: rgba(#c0d, 0.1);
	@media print, screen and (min-width: $breakpoint1){
		overflow: auto;
		width: calc(100% + 125px);
		min-width: 1000px + $pcMargin * 2 + 125px;
		margin-left: -125px;
		// padding-left: 125px;
		padding-left: 0;
	}
}
// リキッド
.layoutLiquid{
	width: 100%;
	box-sizing: border-box;
	padding: 0 $spMargin;
	// background-color: rgba(#35a, 0.1);
	@media print, screen and (min-width: $breakpoint1){
		width: 100%;
		max-width: 1240px;
		min-width: 1000px + $pcMargin * 2;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $pcMargin;
	}
}
// 固定幅
.layoutSolid{
	width: 100%;
	box-sizing: border-box;
	padding: 0 $spMargin;
	// background-color: rgba(#ad9 , 0.1);
	@media print, screen and (min-width: $breakpoint1){
		width: 1000px + $pcMargin * 2;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $pcMargin;
	}
}


// 120+120+995+125

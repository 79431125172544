// --------------------
// home
// --------------------
body.home{
	// #header{
	// 	display: none;
	// }

	// background-color: #fff;
	// transition-duration: 500ms;
	//
	// &.dark{
	// 	background-color: #333;
	// }


	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		.layoutSolid,
		.layoutLiquid{
			padding: 0 25px;
		}
		// logo
		#header{
			.siteId a:before{
				// display: none;
				// opacity: 0;
				margin-top: -100px;
			}
			.siteId.scrolled a:before{
				// display: none;
				// opacity: 1;
				margin-top: 0;
				// background-color: #ddd;
				// background-size: 108px 14px;
			}
		}
	}



	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.layoutFull{
			min-width: 1050px + 200px;
			max-width: 1400px;
			overflow: hidden;
			margin-left: 0;
			padding-left: 0;
		}
		.layoutLiquid{
			min-width: 1050px;
		}
		.layoutSolid{
			width: 1050px;
			padding-left: 0;
		}
	}
}
body.home main{

	$topAddMarin: 100px;

	// styles
	@import 'home/_sectionTop.scss';
	@import 'home/_sectionMission';
	@import 'home/_sectionWhatMeDo';
	@import 'home/_sectionProfile.scss';
	@import 'home/_sectionRecruit.scss';
	@import 'home/_sectionNews.scss';

}

/* IE11 hack */
@media all and (-ms-high-contrast:none){
	*::-ms-backdrop,
	body.home main .sectionNews ul .category {
		padding: 4px 0 2px;
	}
}

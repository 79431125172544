// --------------------
// sectionMember
// --------------------
.sectionMember {
	@media print, screen and (max-width: $breakpoint1 - 1){
		padding-bottom: 140px;
		// SP style
		.layoutSolid{
			// height: 530px + $topAddMarin;
			position: relative;
			//padding-bottom: 50px;
		}
		.head1{
			margin-right: -25px;
			.title{
				margin-right: 15px;
			}
		}

		.sectionMemberDetailKV {
			margin-top: 37px;
			.pageTitle {
				position: relative;
				margin:0 30px -14px;
				z-index:10;
				display: flex;
				justify-content: center;
				.title {
					line-height: 1;
					.sub {
						font-size: 14px;
						letter-spacing: 1.4px;
						color: #000000;
						font-weight: 400;
						margin-top: 0;
					}
					.main {
						padding-top: 5px;
						font-size: 27px;
						font-weight: bold;
						line-height: 1.89;
						letter-spacing: 2.75px;
						color: #333333;
					}
					.en {
						padding-top: 15px;
						font-size: 10px;
						letter-spacing: 1px;
						color: #A2A2A2;
						@include english(400);
						vertical-align: top;
					}
				}
			}
			.pageTitle.message {
				position: relative;
				margin:0 30px -14px;
				padding-top: 55px;
				padding-bottom: 58px;
				z-index:10;
				.title {
					line-height: 1;
					.sub {
						font-size: 10px;
						letter-spacing: 1px;
						color: #000000;
						font-weight: 400;
						margin-top: 0;
					}
					.main {
						padding-top: 5px;
						font-size: 23px;
						font-weight: bold;
						line-height: 1.89;
						letter-spacing: 1.7px;
						color: #333333;
						@include YuGothic();
					}
					.en {
						padding-top: 15px;
						font-size: 10px;
						letter-spacing: 1px;
						color: #A2A2A2;
						@include english(400);
						vertical-align: top;
					}
				}
				.btn4 {
					margin-top: 7px;
					@include dotBorderSp(x, left top);
					a {
						padding: 15px 0 7px;
						background-color: transparent;

						.text {
							flex: 0 1 auto;
							margin-right: 26px;
							font-size : 12px;
							line-height: 1.4751;
							letter-spacing: 1px;
							color: #333333;
						}
					}
				}
			}
			.keyVisual {
				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.ceoComment {
			margin-top: 40px;
			margin-bottom: 80px;
			.wrap {
				.comment {
					overflow: hidden;
					padding: 0;
					p {
						font-size: 14px;
						line-height: 1.7142;
						letter-spacing : 1.4px;
						color : #333333;
						& + p {
							margin-top: 20px;
						}
						&.ellipsis {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

				.blockText3 {
					display: none;
					margin-top: 40px;
					border-top: 1px solid #d5d5d5;
					border-right: 1px solid #d5d5d5;
					border-bottom: 1px solid #d5d5d5;
					padding: 32px 32px 44px;

					background-size: 10px auto;
					background-position: left top;
					background-image: url('assets/images/common/btn_border4.svg');
					position: relative;
					&:before {
						position: absolute;
						left:0;
						bottom:1px;
						display: block;
						content:'';
						width: 1px;
						height: 30%;
						background-color: #d5d5d5;
					}
					.title4{
						font-size: 16px;
						letter-spacing: 1.4px;
						color: $colorOrange;
					}
					.text {
						margin-top: 9px;
						padding-top: 13px;
						@include dotBorderSp(x, left top);
						p {
							font-size: 12px;
							line-height: 1.6666;
							letter-spacing: 1.2px;
							color: #666666;
							+ p {
								padding-top: 10px;
							}
						}
					}
				}
			}

			.btnReadMore2 {
				margin-top: 34px;
				text-align: center;
				cursor: pointer;
				span {
					display: inline-block;
					padding: 13px 44px 13px;
					border-radius:46px;
					vertical-align: middle;
					border:1px solid #d5d5d5;
					font-size: 11px;
					letter-spacing: 0.875px;
					color: #666666;

				}
			}
		}

		ul.entries {
			display: flex;
			flex-wrap: wrap;
			margin:-18px -8px;
			li {
				display: flex;
				flex: 0 0 50%;
				max-width: 50%;
				box-sizing: border-box;
				padding:18px 8px;
				.inner {
					display: block;
					width: 100%;
					figure {
						img {
							width: 100%;
							height: auto;
							vertical-align: top;
						}
					}
					.position {
						padding-top: 7px;
						font-size: 12px;
						letter-spacing: 0.6px;
						color: #000000;
					}
					.name {
						padding-top: 5px;
						font-size: 15px;
						font-weight: bold;
						letter-spacing: 0.35px;
						color: #333333;
					}
					.nameEnglish {
						font-size : 10px;
						letter-spacing : 1px;
						color : #A2A2A2;
						@include english(400);
					}
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		position: relative;
		// padding-top: 60px;
		padding-top: 0;
		padding-left: 200px;
		padding-bottom: 193px;

		.layoutSolid{
			//height: 530px + $topAddMarin;
			position: relative;
		}
		.head1{
			.title{
				margin-right: 50px;
			}
			.line{
				// margin-right: 50px;
				// margin-left: -200px;
			}
		}
		.sectionMemberDetailKV {
			margin-top: 90px;
			padding-top: 0;
			//padding-right: 50px;
			//padding-bottom: 187px;
			//padding-left: 50px;
			position: relative;
			display: flex;
			justify-content: flex-end;
			overflow: hidden;
			.pageTitleWrap {
				position: absolute;
				left:130px;
				top:0;
				width: 100%;
				height: 100%;
			}
			.pageTitle {
				position: absolute;
				margin:0 0;
				width: 460px;
				left:0;
				top:50%;
				transform: translate(0, -50%);
				display: block;

				.title {
					.sub {
						font-size: 25px;
						letter-spacing: 1.25px;
						color : #000000;
					}
					.main {
						padding-top: 8px;
						font-size: 47px;
						letter-spacing: 2.55px;
						color : #333333;

					}
					.en {
						font-size: 13px;
						letter-spacing: 0.65px;
						line-height: 1;
					}
				}
			}
			.pageTitle.message {
				position: absolute;
				margin:0 0;
				width: 460px;
				left:0;
				top:50%;
				transform: translate(0, -50%);
				padding-top: 48px;
				padding-bottom: 30px;

				.title {
					.sub {
						font-size: 15px;
						letter-spacing: 0.8px;
					}
					.main {
						padding-top: 8px;
						font-size: 34px;
						letter-spacing: 2.55px;
					}
					.en {
						font-size: 13px;
						letter-spacing: 0.65px;
						line-height: 1;
					}
				}
				.btn4 {
					margin-top: 18px;
					a {
						padding-top: 12px;
						.text {
							font-size : 14px;
						}
					}
				}
			}
			.keyVisual {
				width: 66.75%;
				max-width:66.75%;
				min-width:600px;
				img {
				}
			}
		}
		.ceoComment {
			margin-top: 90px;
			margin-bottom: 80px;
			.wrap {
				height: auto !important;
				display: flex !important;
				justify-content: space-between;
				.comment {
					flex:0 0 619px;
					max-width: 619px;
					padding: 0;
					p {
						font-size : 15px;
						line-height : 1.86666;
						letter-spacing : 0.75px;
						color : #333333;
						padding: 0 0;
					}
				}


				.blockText3 {
					display: block !important;
					flex:0 0 308px;
					max-width:308px;
					margin-top: 0;
					padding: 22px 32px 29px 44px;
					background-size: 17px auto;
					background-image: url('assets/images/common/btn_border4_pc.svg');
					border-top: 1px solid #d5d5d5;
					border-right: 1px solid #d5d5d5;
					border-bottom: 1px solid #d5d5d5;
					background-position: left top;
					position: relative;
					&:before {
						position: absolute;
						left:0;
						bottom:1px;
						display: block;
						content:'';
						width: 1px;
						height: 30%;
						background-color: #d5d5d5;
					}
					.title4{
						font-size: 18px;
						letter-spacing: 1.62px;
						color: $colorOrange;
					}
					.text {
						margin-top: 9px;
						padding-top: 14px;
						@include dotBorderSp(x, left top);
						p {
							font-size: 13px;
							line-height: 1.6692;
							letter-spacing: 1.3px;
							color: #666666;
							+ p {
								padding-top: 18px;
							}
						}
					}
				}
			}
		}

		ul.entries {
			display: flex;
			flex-wrap: wrap;
			margin:-19px -20px;
			li {
				flex: 0 0 25%;
				max-width: 25%;
				padding:19px 20px;
				.inner {
					figure {
						img {
							width: 100%;
							height: auto;
							vertical-align: top;
						}
					}
					.position {
						padding-top: 14px;
						font-size: 12px;
						letter-spacing: 0.6px;
						color: #000000;
					}
					.name {
						padding-top: 5px;
						font-size: 20px;
						font-weight: bold;
						letter-spacing: 1px;
						color: #333333;
					}
					.nameEnglish {
						font-size: 12px;
						letter-spacing: 0.6px;
						color: #A2A2A2;
					}
				}
			}
		}
	}
}

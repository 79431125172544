// --------------------
// sectionMemberDetailMain
// --------------------
.sectionMemberDetailMain {
	padding-bottom: 104px;
	// SP style
	.title2 {
		font-size: 21px;
		line-height: 1.571;
		letter-spacing: 1px;
		color: #333333;
	}
	.blockStyle1 {
		padding-top: 70px;
		.title2{}
		.title2 + .text {
			padding-top: 35px;
		}
		.text p {
			font-size: 14px;
			line-height: 1.714;
			letter-spacing: 1.4px;
			color: #333333;
			+ p {
				padding-top: 20px;
			}
		}
		+ figure {
			padding-top: 86px;
		}
	}
	figure {
		img {
			width: 100%;
			height: auto;
			vertical-align: top;
		}
	}
	.blockStyle2 {
		padding-top: 85px;
		display: flex;
		flex-direction: column;
		+ .blockStyle2 {
			padding-top: 45px;
		}
		figure {
			padding-top: 86px;
			width: 100%;
			order:2;
			+ .block {
				order:0;
			}
		}
		.block {
			width: 100%;
			&:first-child {
				order:0;
			}
			.title2 + .text {
				padding-top: 35px;
			}
			.text p {
				+ p {
					padding-top: 20px;
				}
			}
			+ figure {
				order:2;
				padding-top: 86px;
			}
		}
		.text p {
			font-size: 14px;
			line-height: 1.714;
			letter-spacing: 1.4px;
			color: #333333;
		}
	}
	.career {
		margin-top: 69px;
		.title3 {
			display: flex;
			align-items: center;
			.text {
				display: flex;
				flex:0 0 auto;
				//max-width: 30px;
				font-size: 16px;
				letter-spacing: 3px;
				color: #333333;
				margin-right:23px;
			}
			&:after {
				flex:1 1 auto;
				content:'';
				width: 100%;
				height: 1px;
				background-color: #e2e2e2;
			}
		}
		.careerList {
			margin-top: 19px;
			li {
				display: flex;
				+ li {
					margin-top: 20px;
				}
				.textYear {
					flex:0 0 auto;
					margin-right:24px;
					.text1 {
						font-size: 12px;
						line-height: 1.6667;
						letter-spacing: 1px;
						color: #333333;
						padding-right: 3px;
						@include english(400);
						font-weight: bold;
					}
					.text2 {
						font-size: 12px;
						line-height: 1.6667;
						letter-spacing: 1px;
						color: #333333;
						@include english(400);
						font-weight: bold;
					}
				}
				.text {
					flex:1 1 auto;
					font-size: 12px;
					line-height: 1.6667;
					letter-spacing: 1px;
					color: #666666;
				}
			}
		}
	}

	.blockText3 {
		margin-top: 76px;
		border-top: 1px solid #d5d5d5;
		border-right: 1px solid #d5d5d5;
		border-bottom: 1px solid #d5d5d5;
		padding: 32px 32px 44px;

		background-size: 10px auto;
		background-position: left top;
		background-image: url('assets/images/common/btn_border4.svg');
		position: relative;
		&:before {
			position: absolute;
			left:0;
			bottom:1px;
			display: block;
			content:'';
			width: 1px;
			height: 30%;
			background-color: #d5d5d5;
		}
		.title4{
			font-size: 16px;
			letter-spacing: 1.4px;
			color: $colorOrange;
		}
		.text {
			margin-top: 9px;
			padding-top: 13px;
			@include dotBorderSp(x, left top);
			p {
				font-size: 12px;
				line-height: 1.6666;
				letter-spacing: 1.2px;
				color: #666666;
				+ p {
					padding-top: 10px;
				}
			}
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 0;
		width: 880px;
		.title2 {
			font-size: 24px;
			line-height: 1.4583;
			letter-spacing: 1.2px;
		}
		.blockStyle1 {
			padding-top: 70px;
			.title2{}
			.title2 + .text {
				padding-top: 35px;
			}
			.text p {
				font-size: 15px;
				line-height: 1.8;
				letter-spacing: 1.5px;
				+ p {
					padding-top: 27px;
				}
			}
			+ figure {
				padding-top: 93px;
			}
		}
		figure {
			img {
			}
		}
		.blockStyle2 {
			padding-top: 99px;
			flex-direction: row;
			justify-content: space-between;
			+ .blockStyle2 {
				padding-top: 70px;
			}
			figure {
				order: 1;

				flex:0 0 354px;
				max-width: 354px;
				padding-top: 0;
				+ .block {
					order: 2;
				}
			}
			.block {
				order: 2;
				flex:0 0 354px;
				max-width: 354px;
				&:first-child {
				}
				.title2 {
					margin-top: -4px;
				}
				.title2 + .text {
					padding-top: 38px;
				}
				.text p {
					+ p {
						padding-top: 20px;
					}
				}
				+ figure {
					padding-top: 0;
				}
			}
			.text p {
				font-size: 15px;
				line-height: 1.8;
				letter-spacing: 1.5px;
			}
		}
		.career {
			margin-top: 102px;
			.title3 {
				.text {
					font-size: 20px;
					letter-spacing: 4px;
					margin-right: 27px;
				}
			}
			.careerList {
				margin-top: 36px;
				li {
					+ li {
						margin-top: 19px;
						padding-top: 17px;
						@include dotBorderSp(x, left top);
					}
					.textYear {
						margin-right:75px;
						line-height: 1.457;
						.text1 {
							font-size: 13px;
							letter-spacing: 1.3px;
						}
						.text2 {
							font-size: 13px;
							letter-spacing: 1.3px;
						}
					}
					.text {
						font-size: 13px;
						line-height: 1.6667;
					}
				}
			}
		}

		.blockText3 {
			padding: 32px 55px 52px 54px;
			background-size: 17px auto;
			background-image: url('assets/images/common/btn_border4_pc.svg');
			.title4{
				font-size: 18px;
				letter-spacing: 1.62px;
			}
			.text {
				padding-top: 14px;
				p {
					font-size: 13px;
					line-height: 1.7692;
					letter-spacing: 1.3px;
					+ p {
						padding-top: 18px;
					}
				}
			}
		}

	}
}

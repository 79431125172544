// --------------------
// single-news
// --------------------
body.single-news main{

	//$topAddMarin: 100px;


	// styles
	@import 'news/_sectionNews.scss';
}
// --------------------
// footer
// --------------------
#footer{
	color: #fff;
	background-color: #333;
	position: relative;
	// height: 340px;
	// overflow: hidden;
	z-index: 110;

	a{
		color:#fff;
	}
	a:hover{
		text-decoration: none;
	}
	// .layoutLiquid{
	// 	// position: relative;
	// }

	.footerContent{
		padding: 0 25px;
	}


	.facebook{
		a:before{
			content: '';
			display: block;
			background-position: center;
			background-image: url('assets/images/common/icon_facebook.svg');
			background-size: contain;
		}
		span{
			display: none;
		}
	}
	.twitter{
		a:before{
			content: '';
			display: block;
			background-position: center;
			background-image: url('assets/images/common/icon_twitter.svg');
			background-size: contain;
		}
		span{
			display: none;
		}
	}




// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	padding-top: 75px;
	padding-bottom: 75px;

	// .layoutLiquid{
	// }

	.footerContent{
		// display: flex;
		// align-items: flex-start;
		// justify-content: space-between;

		.siteId{
			// flex: 0 0 200px;
			width: 216px;
			margin: 0 auto;
		}
		.nav{
			margin-top: 60px;
			// flex: 1 1 510px;
			// margin: 0 50px;
			.nav1{
				border-top: 1px solid #808080;
				li{
					border-bottom: 1px solid #808080;
				}
				a{
					display: block;
					text-align: center;
					font-size: 13px;
					padding: 10px 0;
				}
			}
			.nav2{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin: 40px 0 30px;

				li{
					flex: 0 0 100%;
					text-align: center;


					a{
						font-size: 11px;
					}

					&.facebook,
					&.twitter{
						flex: 0 0 50%;
						margin-bottom: 20px;
						padding: 0 10px;
						a{
							display: block;
							width: 32px;
							// transition-duration: 200ms;
							&:before{
								width: 32px;
								height: 32px;
							}
							// &:hover{
							// 	&:before{
							// 		opacity: 0.7;
							// 		text-decoration: underline
							// 	};
							// }
						}
					}
					&.facebook a{
						margin: 0 0 0 auto;
					}
					&.twitter a{
						margin: 0 auto 0 0;
					}
				}
			}
		}
		.certify{
			// flex: 0 0 110px;
			ul{
				display: flex;
				align-items: flex-start;
				justify-content: center;
				img{
					height: 57px;
					width: auto;
					margin: 0 5px;
					// width: 100%;
				}
			}
		}
	}
	.copyright{
		text-align: center;
		margin-top: 50px;

		small{
			@include english();
			font-size: 10px;
			color: #fff;
		}
	}
}



// PC style
@media print, screen and (min-width: $breakpoint1){
	padding-top: 75px;
	padding-bottom: 75px;

	// .layoutLiquid{
	// }

	.footerContent{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 1000px;
		padding: 0;
		margin: 0 auto;

		.siteId{
			flex: 0 0 200px;
		}
		.nav{
			flex: 1 1 510px;
			margin: 0 50px;
			ul{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 20px;
			}
			li{
				margin-right: 1.5em;
			}
			a{
				font-size: 13px;
				transition-duration: 200ms;
				&:hover{
					opacity: 0.7;
					text-decoration: underline;
				}
			}
			.nav2 a{
				font-size: 11px;
			}

			.facebook,
			.twitter{
				a{
					display: block;
					transition-duration: 200ms;
					&:before{
						width: 32px;
						height: 32px;
					}
					&:hover{
						&:before{
							opacity: 0.7;
							text-decoration: underline
						};
					}
				}
			}
		}
		.certify{
			flex: 0 0 110px;
			ul{
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				img{
					height: 57px;
					width: auto;
					// width: 100%;
				}
			}
		}
	}
	.copyright{
		text-align: center;
		margin-top: 75px;

		small{
			@include english();
			font-size: 10px;
			color: #fff;
		}
	}
}
}



#pageTop{
	position: fixed;
	z-index: 150;
	bottom: 20px;
	right:  20px;
	transform: translateY(-50%) scale(0);
	transition-duration: 100ms;

	span{
		font-size: 0;
		display: none;
	}
	a{
		display: block;
		width: 60px;
		height: 60px;
		padding: 10px;
		svg{
			width: 40px;
			height: 40px;
		}
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			border: 1px solid #a3a3a3;

		}
	}

	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		bottom: 15px;
		right:  15px;
		a{
			width: 50px;
			height: 50px;
			svg{
				width: 30px;
				height: 30px;
			}
			&:before{
				width: 50px;
				height: 50px;
			}
		}
	}
}
// PC style
@media print, screen and (min-width: $breakpoint1){
#pageTop{
	a{
		transition-duration: 200ms;
		&:hover{
			opacity: 0.7;
			&:before{
				transform: scale(1);
				opacity: 1;
				animation: pageTopAnimation 500ms ease 0ms
			}
		}
	}
}
}

@keyframes pageTopAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.4);
		opacity: 0;
	}
	51% {
		transform: scale(0.5);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}


body.scrolled{
	#pageTop{
		transform: translateY(-50%) scale(1);
	}
}

// --------------------
// sectionCommitmentGemWay
// --------------------
.sectionCommitmentGemWay {
	// SP style
	padding-top: 80px;
	padding-bottom:146px;

	.sectionTitle {
		padding-bottom: 47px;
		font-size: 23px;
		letter-spacing: 0.8px;
		color: #000000;
		text-align: center;
		.text1 {
			padding-right: 15px;
		}
	}
	ul.gemWayList {
		li {
			position: relative;

			& + li {
				margin-top: 50px;
				padding-top: 54px;
				@include dotBorderSp(x, left top);
				.number {
					top: 50px;
				}
			}
			.number {
				position: absolute;
				left:0;
				top: 0;
				.num {
					position: relative;
					display: inline-block;
					padding: 0 28px 14px 0;
					line-height: 1;
					.text1 {
						font-size: 25px;
						@include english(500);
						color: $colorOrange;
						letter-spacing: 0;
					}
					.text2 {
						position: absolute;
						right:0;
						bottom:0;
						font-size : 13px;
						@include english(400);
						letter-spacing: 0.8px;
						color : #BCBCBC;
						&:before {
							content:'';
							position: absolute;
							top:-2px;
							left: -25px;
							display: block;
							width: 39px;
							height: 1px;
							background-color: #d6d6d6;
							transform: rotate(-45deg);
						}
					}
				}
			}
			figure {
				margin: auto;
				img {
					width: 100%;
					height: auto;
				}
			}
			.detail {
				margin-top: 23px;
				.listTitle{
					font-size: 17px;
					line-height: 1.6764;
					letter-spacing: 0.8px;
					color: #333333;
				}
				.comments {
					padding-top: 16px;
					font-size: 13px;
					line-height: 1.7777;
					letter-spacing: 1px;
					color: #333333;
				}
			}
			&.list1 {
				figure {
					width: 115px;
				}
			}
			&.list2{
				figure {
					width: 103px;
				}
			}
			&.list3{
				figure {
					width: 122px;
				}
			}
			&.list4{
				figure {
					width: 124px;
				}
			}
			&.list5{
				figure {
					width: 74px;
				}
			}
			&.list6{
				figure {
					width: 76px;
				}
			}
			&.list7{
				figure {
					width: 120px;
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		width: 1000px;
		padding-top: 127px;

		.sectionTitle {
			font-size: 27px;
			letter-spacing: 1px;
			color: #333333;

			.text1 {
				padding-right: 32px;
			}
		}
		ul.gemWayList {
			display: flex;
			flex-wrap:wrap;
			li {
				& + li {
					margin-top: 0;
					.number {
						top:67px;
					}
				}
				.number {
					.num {
						padding: 0 35px 19px 0;
						.text1 {
							font-size: 33px;
						}
						.text2 {
							font-size: 17px;
							&:before {
								top: -4px;
								left: -30px;
								width: 54px;
							}
						}
					}
				}
				.detail {
					margin-top: 47px;
					.listTitle{
						font-size: 19px;
						letter-spacing: 0.95px;
					}
					.comments {
						font-size: 15px;
						line-height: 1.7333;
						letter-spacing: 1.5px;
					}
				}
				&.list1 {
					display: flex;
					justify-content: flex-end;
					flex-basis: 100%;
					max-width:100%;
					padding-top: 43px;
					padding-bottom: 65px;
					.num {
						.text1 {
							font-size: 42px;
							color: $colorOrange3;
						}
					}
					figure {
						width: 132px;
						margin: 0 136px 0 0;
					}
					.detail {
						margin-top: 0;
						flex: 0 0 484px;
						max-width:484px;
					}
				}
				&.list2{
					flex-basis: 50%;
					max-width:50%;
					padding: 63px 86px 72px 0;
					.number {
						left:4px;
					}
					figure {
						padding-top: 63px;
						width: 109px;
					}
				}
				&.list3{
					flex-basis: 50%;
					max-width:50%;
					padding: 63px 0 72px 86px;
					background-image: linear-gradient(to right, transparent 75%, #666 0%),linear-gradient(to bottom, transparent 75%, #666 0%);
					background-position: left top;
					background-size: 4px 1px,1px 4px;
					background-repeat: repeat-x,repeat-y;
					.number {
						left:87px;
					}
					figure {
						padding-top: 87px;
						width: 133px;
						//width: 122px;
						img {
							margin-left: 10px;
						}
					}
				}
				&.list4{
					flex-basis: 50%;
					max-width:50%;
					padding: 63px 86px 72px 0;
					.number {
						left:4px;
					}
					figure {
						//width: 124px;
						padding-top: 66px;
						width: 131px;
						img {
							margin-left: 11px;
						}
					}
					.detail {
						margin-top: 42px;
					}
				}
				&.list5{
					flex-basis: 50%;
					max-width:50%;
					padding: 63px 0 72px 86px;
					background-image: linear-gradient(to right, transparent 75%, #666 0%),linear-gradient(to bottom, transparent 75%, #666 0%);
					background-position: left top;
					background-size: 4px 1px,1px 4px;
					background-repeat: repeat-x,repeat-y;
					.number {
						left:87px;
					}
					figure {
						padding-top: 74px;
						//width: 74px;
					}
					.detail {
						margin-top: 42px;
					}
				}
				&.list6{
					display: flex;
					justify-content: flex-end;
					flex-basis: 100%;
					max-width:100%;
					padding-top: 100px;
					padding-bottom: 71px;
					.number {
						top: 59px;
						.num {
							.text1 {
								font-size: 42px;
								color: $colorOrange3;
							}
						}
					}
					figure {
						width: 101px;
						margin: 2px 142px 0 0;
					}
					.detail {
						margin-top: 0;
						flex: 0 0 481px;
						max-width:481px;
					}
				}
				&.list7{
					display: flex;
					justify-content: flex-end;
					flex-basis: 100%;
					max-width:100%;
					padding-top: 100px;
					padding-bottom: 71px;
					.number {
						top: 59px;
						.num {
							.text1 {
								font-size: 42px;
								color: $colorOrange3;
							}
						}
					}
					figure {
						width: 141px;
						margin: 2px 142px 0 0;
					}
					.detail {
						margin-top: 0;
						flex: 0 0 481px;
						max-width:481px;
					}
				}
			}
		}

	}
}
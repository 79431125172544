// // アイコンフォント
// @mixin icomoon($_icon){
// 	font-family: icomoon;
// 	content: '' + map-get($iconMap, $_icon);
// 	font-weight: normal;
// }
//
// 欧文
@mixin english($num:400){
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: $num;
}

// Noto
@mixin notoSans{
	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
}
// 游ゴシック
@mixin YuGothic{
	font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
}
// メイリオ
@mixin Meiryo{
	font-family: "メイリオ", Meiryo, sans-serif;
}
// @mixin notoThin{
@mixin fontThin{
	font-weight: 100;
}
// @mixin notoLight{
@mixin fontLight{
	font-weight: 300;
}
// @mixin notoRegular{
@mixin fontRegular{
	font-weight: 400;
}
// @mixin notoMedium{
@mixin fontMedium{
	font-weight: 500;
	letter-spacing: 0.07em;
}
// @mixin notoBold{
@mixin fontBold{
	font-weight: 600;
	letter-spacing: 0.07em;
}
// @mixin notoBlack{
@mixin fontBlack{
	font-weight: 800;
	letter-spacing: 0.07em;
}




// 行数制限、越えた場合は「...」になる
@mixin line-num($lineHeight, $num, $flag: true){
	&{
		overflow: hidden;
		@if $flag{
			height: $lineHeight * $num;
		} @else {
			max-height: $lineHeight * $num;
		}

		// @supports (-webkit-box-orient : vertical) and (-webkit-line-clamp : 3) and (display : -webkit-box){
			display: -webkit-box;
			/* autoprefixer: off */
			-webkit-box-orient: vertical;
			/* autoprefixer: on */
			-webkit-line-clamp: $num;
			text-overflow: ellipsis;
		// }
	}
}


// hoverで画像をふわっと拡大する
@mixin imageHover($duration, $scale){
	figure{
		overflow: hidden;
		img{
			width: 100%;
			-webkit-backface-visibility: hidden;
			transition-duration: $duration;
		}
	}
	&:hover{
		figure{
			img{
				transform: scale($scale);
			}
		}
	}
}


// ドットのボーダー
@mixin dotBorder($direction, $control){
	@if $direction == x {
		background-image: linear-gradient(to right, transparent 90%, #666 0%);
		background-position: $control;
		background-size: 6px 1px;
		background-repeat: repeat-x;

	} @else {
		background-image: linear-gradient(to bottom, transparent 90%, #666 0%);
		background-position: $control;
		background-size: 1px 6px;
		background-repeat: repeat-y;
	}
}

// ドットのボーダーSP
@mixin dotBorderSp($direction, $control){
	@if $direction == x {
		background-image: linear-gradient(to right, transparent 75%, #666 0%);
		background-position: $control;
		background-size: 4px 1px;
		background-repeat: repeat-x;

	} @else {
		background-image: linear-gradient(to bottom, transparent 75%, #666 0%);
		background-position: $control;
		background-size: 1px 4px;
		background-repeat: repeat-y;
	}
}

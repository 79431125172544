// --------------------
// contact
// --------------------
body.page-contact main,
body.page-confirm main,
body.page-thanks main{

	//$topAddMarin: 100px;


	// styles
	@import 'contact/_sectionContact.scss';
	@import 'contact/_sectionContactThanks.scss';
}
// --------------------
// btn4
// -------------------- */
.btn4{
	a{
		// height: 44px;
		display: flex;
		align-items: center;
		padding: 19px 0;

		overflow: hidden;
		box-sizing: border-box;
		color: #666;
		.text{
			flex: 0 0 auto;
			font-size: 13px;
			line-height: 1em;
			color: #666;
			text-decoration: underline;
			margin-right: 13px;
		}
		.arrow{
			flex: 0 0 38px;
			margin-right: 15px;
		}
	}
	&.center a{
		text-align: center;
	}

// PC style
@media print, screen and (min-width: $breakpoint1 +1){
	width: 300px;
	a{
		height: 47px;
		transition-duration: 200ms;
		background-color: rgba(#fff,1);
		padding: 0;

		.text{
			font-size: 14px;
			transition-duration: 200ms;
		}
		.arrow{
			flex: 0 0 38px;
		}
		&:hover{
			background-color: rgba(#fff,0.7);
			.text{
				opacity: 0.7;
			}
		}
	}
}
}
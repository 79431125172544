// --------------------
// sectionArchiveNews
// --------------------
.sectionArchiveNews {
	// SP style

	.pageTitle {
		margin-bottom: 58px;
	}

	.categories {
		li {
			border-right: 1px solid #bcbcbc;
			border-bottom: 1px solid #bcbcbc;
			border-left: 1px solid #bcbcbc;

			&:first-child {
				border-top: 1px solid #bcbcbc;
				position: relative;
				&.close {
					&:after {
						position: absolute;
						right:20px;
						top:50%;
						content:'';
						display: block;
						width: 10px;
						height: 10px;
						background-image: url(/wp-content/themes/gempartners_0.1/assets/images/common/btn_burger_close.svg);
						background-size:10px 10px;
						background-repeat: no-repeat;
						transform:rotate(45deg) translate(0,-50%);
					}
				}
				&.open {
					&:after {
						position: absolute;
						right:15px;
						top:50%;
						content:'';
						display: block;
						width: 13px;
						height: 1px;
						background-image: none;
						transform:rotate(0deg) translate(0,1px);
						background-color: #222;
					}
				}
			}

			a{
				display: block;
				padding: 18px 25px 17px 22px;
				box-sizing: border-box;
				font-size: 13px;
				font-weight: bold;
				letter-spacing: 1.3px;
				color: #333333;
				&.current {
				}

			}
		}
	}

	.listArchive {
		margin-top: 44px;
		@include dotBorderSp(x, left top);
		li {
			@include dotBorderSp(x, left bottom);

			a {
				position: relative;
				display: block;
				padding: 25px 53px 25px 0;
				box-sizing: border-box;
				.displayEntryInfo {
					display: flex;
					align-items: center;
					margin-bottom: 9px;
					.category {
						padding: 1px 10px 1px;
						min-width:102px;
						box-sizing: border-box;
						text-align: center;
						font-size: 11px;
						color: #FFFFFF;
						background-color: #333333;
					}
					time {
						padding: 0 11px 0 14px;
						height: 16px;
						box-sizing: border-box;
						font-size : 11px;
						@include english(400);
						letter-spacing : 0.5px;
						color : #A3A3A3;
					}
					.topics {
						width: 60px;
						flex:0 0 60px;
						display: flex;
						align-items: center;
						img {
							width: 100%;
							height: auto;
						}
					}
				}
				.entry-title {
					.text {
						display: block;
						font-size: 13px;
						line-height: 1.3846;
						letter-spacing: 0.8px;
						color: #333333;
					}
					.topics {
						img {
						}
					}
				}
				.arrow{
					position: absolute;
					top: 50%;
					right: 0;
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.categories {
			display: flex;
			flex-wrap: wrap;
			li {
				display: flex;
				flex:1 1 14.2%;
				max-width: 14.2%;
				height: 85px;
				border-top: 1px solid #bcbcbc;
				border-right: 1px solid #bcbcbc;
				border-bottom: 1px solid #bcbcbc;
				border-left: 0;

				&:first-child {
					border-left: 1px solid #bcbcbc;
					position: relative;
					&.close {
						display: none;
					}
					&.open {
						display: none;
					}
				}

				a{
					position: relative;
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 15px 15px 15px;
					box-sizing: border-box;
					font-size : 12px;
					letter-spacing : 0.12px;
					color: #333;
					&.current:after {
						content:'';
						width: 26px;
						height: 5px;
						display: block;
						position: absolute;
						left:50%;
						bottom:-1px;
						transform:translate(-50%,0);
						background-color: #ec8e19;
					}
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}

		.listArchive {
			margin-top: 69px;
			li {
				a {
					position: relative;
					display: flex;
					padding: 19px 94px 19px 0;
					box-sizing: border-box;
					.displayEntryInfo {
						display: flex;
						flex:0 0 auto;
						margin-bottom: 0;
						.category {
							display: flex;
							flex:0 0 120px;
							align-items: center;
							justify-content: center;
							padding: 5px 5px 5px;
							max-width: 120px;
							height: 25px;
							box-sizing: border-box;
							text-align: center;
							font-size: 11px;
							color: #FFFFFF;
							background-color: #333333;
						}
						time {
							display: flex;
							flex:0 0 auto;
							align-items: center;
							padding: 0 26px 0 26px;
							height: 25px;
							box-sizing: border-box;
							font-size : 14px;
							@include english(400);
							letter-spacing : 0.7px;
							color : #A3A3A3;
						}
						.topics {
							display: none;
						}
					}

					.entry-title {
						flex:1 1 695px;
						max-width:695px;
						.text {
							display: inline;
							font-size: 14px;
							letter-spacing: 1.4px;
							color: #333333;
							padding-right: 13px;
						}
						.topics {
							display: inline;
							width: 65px;
							img {
								width: 65px;
								height:26px;
								vertical-align: middle;
								margin:-3px 0 0;
							}
						}
					}
					.arrow{
						flex: 0 0 38px;
						max-width: 38px;
					}
				}
				&:hover{
					opacity: 0.8;
					.entry-title .text{
						text-decoration: underline;
					}
				}
			}
		}
	}
}
// --------------------
// arrowDrop
// -------------------- */
.arrowDrop{

	display: block;
	position: absolute;
	background-position: left top;
	background-image: url('assets/images/home/top_arrow.svg');

	.point{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #a3a3a3;
		opacity: 0;

		transform-origin: center;
		transform: translateY(0) rotate(45deg) scale(0);

		animation-duration: 3700ms;
		animation-delay: 100ms;
		animation-timing-function: ease-in;
		animation-iteration-count: infinite;
	}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	width: 5px;
	height: 240px;
	background-size: 5px 195px;

	.point{
		width:  4px;
		height: 4px;
		animation-name: dropAnimationSP;
	}
}

// PC style
@media print, screen and (min-width: $breakpoint1){
	width: 5px;
	height: 450px;
	background-size: 5px 195px;

	.point{
		width:  6px;
		height: 6px;
		animation-name: dropAnimation;
	}
}
}

@keyframes dropAnimation {
	0% {
		transform: translateY(0px) rotate(45deg) scale(1.0);
		background-color: #a3a3a3;
		opacity: 0.5;
	}
	50% {
		background-color: #a3a3a3;
		opacity: 1;
	}
	70% {
		background-color: #ec8e19;
		opacity: 1;
	}
	80% {
		background-color: #cd151b;
		opacity: 1;
	}
	100% {
		transform: translateY(430px) rotate(45deg) scale(3.5);
		background-color: #cd151b;
		opacity: 0;
	}
}
@keyframes dropAnimationSP {
	0% {
		transform: translateY(0px) rotate(45deg) scale(1.0);
		background-color: #a3a3a3;
		opacity: 0.5;
	}
	50% {
		background-color: #a3a3a3;
		opacity: 1;
	}
	70% {
		background-color: #ec8e19;
		opacity: 1;
	}
	80% {
		background-color: #cd151b;
		opacity: 1;
	}
	100% {
		transform: translateY(220px) rotate(45deg) scale(3.5);
		background-color: #cd151b;
		opacity: 0;
	}
}

// --------------------
// sectionMemberDetailHead
// --------------------
.sectionMemberDetailHead{
	// SP style
	//margin-bottom: 58px;

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		//margin-bottom: 78px;
	}
}

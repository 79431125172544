// --------------------
// sectionLineUp
// --------------------
.sectionLineUp {
	// SP style
	padding-top: 82px;

	.sectionTitle{
		text-align: center;
		font-size: 20px;
		letter-spacing: 1.3px;
		color: #333333;
		@include english(500);
	}
	.notes {
		padding-top: 9px;
		text-align: center;
		font-size: 12px;
		letter-spacing: 1px;
		color: #898989;
	}
	.blockLineUp {
		margin-top: 56px;
		.blockLineUpHead {
			position: relative;
			background-color: $colorOrange2;
			padding: 18px 20px 18px 27px;
			&:before {
				content:'';
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 20px;
				height: 21px;
				background-color: #ce3936;
			}
			.categoryName {
				display: flex;
				align-items: center;
				.num{
					color: #ffffff;
					font-size: 11px;
					@include english();
					padding-right: 14px;
				}
				.text {
					font-size: 18px;
					line-height: 1;
					letter-spacing: 1px;
					color: #ffffff;
				}

			}
		}

		.blockMain {
			background-color: #ffffff;
			padding: 38px 19px 38px;
			> div {
				&[class^="block"] {
					& + div[class^="block"] {
						margin-top: 26px;
						padding-top: 26px;
						@include dotBorderSp(x, left top);
					}
					.itemName {
						padding: 0 0 13px 20px;
						background-position: 0 0.7em;
						background-size: 9px 9px;
						background-repeat: no-repeat;
						background-image: url(assets/images/common/icon_list.svg);
						span {
							font-size: 16px;
							letter-spacing: 0.7px;
							color: #333333;
						}
					}
				}
				&.blockStyle1 {
					.textBlock {
						p {
							font-size: 14px;
							line-height: 1.7142;
							letter-spacing: 1.4px;
							color: #333333;
						}
					}
				}
				&.blockStyle2 {
					figure {
						img {
							width: 100%;
							height: auto;
							vertical-align: top;
						}
					}
				}
			}
			.displayIcon {
				margin-bottom:39px;
				ul {
					display: flex;
					flex-wrap: wrap;
					li {
						display: flex;
						align-items: center;
						margin-right: 24px;
						&:last-child {
							margin-right: 0;
						}
						.iconStar{
							&:before {
								content:'';
								margin-right: 15px;
								display: block;
								width: 13px;
								height: 14px;
								background-repeat: no-repeat;
								background-size: 13px 13px;
								background-image: url(assets/images/business/icon_star.svg);
							}
						}
						.iconDiamond {
							&:before {
								content:'';
								margin-right: 15px;
								display: block;
								width: 13px;
								height: 14px;
								background-repeat: no-repeat;
								background-size: 13px 13px;
								background-image: url(assets/images/business/icon_diamond2.svg);
							}
						}
						.iconCircle {
							&:before {
								content:'';
								margin-right: 15px;
								display: block;
								width: 13px;
								height: 14px;
								background-repeat: no-repeat;
								background-size: 13px 13px;
								background-image: url(assets/images/business/icon_circle.svg);
							}
						}
						.text{
							display: block;
							font-size: 12px;
							letter-spacing: 1px;
							color: #333333;
						}
					}
				}
			}
			.blockStyle1 .textBlock {
				p {
					.text {
						padding-right: 6px;
					}
					.iconStar{
						display: inline-block;
						vertical-align: middle;
						&:before {
							content:'';
							margin-right: 3px;
							display: block;
							width: 13px;
							height: 14px;
							background-repeat: no-repeat;
							background-size: 13px 13px;
							background-image: url(assets/images/business/icon_star.svg);
						}
					}
					.iconDiamond{
						display: inline-block;
						vertical-align: middle;
						&:before {
							content:'';
							margin-right: 3px;
							display: block;
							width: 13px;
							height: 14px;
							background-repeat: no-repeat;
							background-size: 13px 13px;
							background-image: url(assets/images/business/icon_diamond2.svg);
						}
					}
					.iconCircle {
						display: inline-block;
						vertical-align: middle;
						&:before {
							content:'';
							margin-right: 3px;
							display: block;
							width: 13px;
							height: 14px;
							background-repeat: no-repeat;
							background-size: 13px 13px;
							background-image: url(assets/images/business/icon_circle.svg);
						}
					}
				}
				.advertisement {
					background-color: #F4F4F4;
					margin-top: 15px;
					padding: 11px 14px 15px 15px;
					h5 {
						padding-bottom: 3px;
						font-size: 14px;
						font-weight: bold;
						letter-spacing: 0.5px;
						color: #333333;
					}
					p {
						.iconDiamond {}
						.iconStar {}
						.iconCircle {}
					}
				}
			}
			.textOthers {}
		}
	}
	@media print, screen and (min-width: 390px) and (max-width: $breakpoint1 - 1){
		.displayIcon {
			ul {
				justify-content: center;
			}
		}

	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		//padding-top: 82px;

		.sectionTitle{
			font-size: 36px;
			letter-spacing: 0.9px;
			line-height: 1;
		}
		.notes {
			padding-top: 3px;
			font-size: 15px;
			letter-spacing : 0.8px;
		}
		.blockLineUp {
			margin-top: 46px;

			+ .blockLineUp {
				margin-top: 58px;
			}

			.blockLineUpHead {
				padding: 12px 50px 11px 51px;
				&:before {
					width: 18px;
					height: 18px;
				}
				.categoryName {
					.num{
						padding-right: 19px;
						font-size: 16px;
					}
					.text {
						font-size: 20px;
						letter-spacing: 0.5px;
					}

				}
			}

			.blockMain {
				padding: 42px 40px 49px 39px;
				> div {
					&[class^="block"] {
						& + div[class^="block"] {
							margin-top: 31px;
							padding-top: 34px;
						}
						.itemName {
							background-position: 0 0.6em;
							padding: 0 0 23px 30px;
							background-size: 14px 14px;
							span {
								font-size: 18px;
								letter-spacing: 0.4px;
							}
						}
					}
					&.blockStyle1 {
						display: flex;
						justify-content: space-between;
						.itemName {
							flex:0 0 297px;
							max-width:297px;
							padding-right: 40px;
						}
						.textBlock {
							flex:1 1 auto;
							p {
								font-size: 15px;
								line-height: 2;
								letter-spacing: 0.6px;
							}
						}
					}
					&.blockStyle2 {
						figure {
						}
					}
				}
				.displayIcon {
					margin-bottom: 49px;
					ul {
						li {
							margin-right: 48px;
							.text{
								font-size: 13px;
								letter-spacing : 0.7px;
								color: #333333;
							}
						}
					}
				}
				.blockStyle1 .textBlock {
					p {
						.text {

						}
					}
					.advertisement {
						display: block;
						padding: 12px 22px 13px 22px;
						h5 {
							padding-bottom: 4px;
							font-size: 15px;
							letter-spacing: 0.8px;
						}
						p {
							.iconDiamond {}
							.iconStar {}
							.iconCircle {}
						}
					}
				}
				.textOthers {}
			}
		}
	}
}

// --------------------
// post-type-archive-member
// --------------------
body.post-type-archive-member main{
	@media print, screen and (max-width: $breakpoint1) {
		// PC style
		padding-bottom: 100px;
	}

	@media print, screen and (max-width: $breakpoint1) {
		// PC style
		padding-bottom: 100px;
	}

	// styles
	@import 'member/_sectionMemberHead.scss';
	@import 'member/_sectionMember.scss';
}
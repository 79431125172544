// --------------------
// btn2
// -------------------- */
.btn2{
	a{
		// height: 44px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// border-top: 1px solid #666;
		// border-right: 1px solid #666;
		// border-bottom: 1px solid #666;
		background-color: #ec8e19;
		padding: 19px 0;

		background-size: 8px auto;
		background-position: left center;
		background-image: url('assets/images/common/btn_border_white.svg');

		overflow: hidden;
		box-sizing: border-box;
		color: #fff;
		.text{
			flex: 1 1 auto;
			font-size: 12px;
			line-height: 1em;
			margin-left: 30px;
		}
		.arrowWhite{
			flex: 0 0 38px;
			margin-right: 15px;
		}

	}
	&.center a{
		text-align: center;
	}

// PC style
@media print, screen and (min-width: $breakpoint1 +1){
	width: 390px;
	a{
		height: 47px;
		transition-duration: 200ms;
		background-color: #ec8e19;
		padding: 0;

		.text{
			font-size: 14px;
			transition-duration: 200ms;
		}
		.arrowWhite{
			flex: 0 0 38px;
		}
		&:hover{
			background-color: rgba(#ec8e19,0.8);
			.text{
				opacity: 0.7;
			}
		}
	}
}
}

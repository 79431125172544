// --------------------
// head2
// -------------------- */
.head2{
	.title {
		@include english(500);
		// font-feature-settings : "palt";
		font-size: 38px;
		line-height: 1;
		letter-spacing: 0.05em;
		margin-bottom: 12px;
	}
	.sub {
		@include fontBold();
		font-size: 15px;
		letter-spacing: 0.05em;
	}

// PC style
@media print, screen and (min-width: $breakpoint1){
	.title {
		font-size: 70px;
		line-height: 1;
		margin-bottom: 17px;
	}
	.sub {
		font-size: 17px;
	}
}
}

// --------------------
// security
// --------------------
body.page-security main{


	//$topAddMarin: 100px;

	// styles
	@import 'security/_sectionHead.scss';
	@import 'security/_sectionSecurity.scss';
}
// --------------------
// sectionCompanyHead
// --------------------
.sectionCompanyHead {
	// SP style
	margin-bottom: 58px;

	@media print, screen and (min-width: $breakpoint1){
		// PC style
		margin-bottom: 78px;
	}
}

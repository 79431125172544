// --------------------
// sectionPartners
// --------------------
.sectionPartners {
	// SP style
	padding-top:90px;
	padding-bottom: 88px;
	.sectionTitle3 {
		text-align: center;
		margin-bottom: 38px;
	}
	.listPartners {
		overflow: hidden;
		padding-bottom: 56px;
		ul {
			margin: -16px -16px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				flex:0 0 33.3333%;
				max-width:33.333%;
				padding: 16px 16px;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
			}
		}
	}
	.btn1 {

	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top:91px;
		padding-bottom: 102px;
		.sectionTitle3 {
			//text-align: center;
			//margin-bottom: 38px;
			font-size: 28px;
			letter-spacing: 0.7px;
			color: #333333;
		}
		.listPartners {
			//overflow: hidden;
			//padding-bottom: 56px;
			padding-bottom: 69px;
			ul {
				//display: flex;
				//flex-wrap: wrap;
				//justify-content: center;
				margin: -13px -15px;
				li {
					//flex:0 0 33.3333%;
					//max-width:33.333%;
					//padding: 16px 16px;
					flex-basis: 16.6667%;
					max-width: 16.6667%;
					padding: 13px 15px;
					img {
						//width: 100%;
						//height: auto;
						//vertical-align: top;
					}
				}
			}
		}
		.btn1 {
			width: 390px;
			margin:auto;
		}
	}
}

// --------------------
// sectionContact
// --------------------
.sectionContact{
	padding-bottom: 63px;
	padding-left: 0;
	padding-right: 0;

	.breadcrumb {
		margin-left:30px;
		margin-right:30px;
	}

	.pageTitle {
		margin-left:30px;
		margin-right:30px;
	}

	.contactHead {
		margin-top: 48px;
		margin-right: 30px;
		margin-left: 30px;
		margin-bottom: 54px;
		.lead {
			font-size: 14px;
			line-height: 1.6;
			letter-spacing: 1.4px;
			color: #333333;
		}
		.caution {
			font-size: 14px;
			line-height: 1.6;
			letter-spacing: 1.4px;
			color: #CD151B;
		}
	}

	// SP style
	.mw_wp_form {
		form {
			.listForm {
				margin-bottom: 35px;
				border-bottom: 1px solid #e2e2e2;
				input[type="text"],
				input[type="email"]{
					width: 100%;
					font-size: 13px;
					line-height: 1.3;
					padding: 14px 17px 14px;
					height: 44px;
					box-sizing: border-box;
					border: 1px solid #bcbcbc;
					border-radius: 0;
					background-color: #f4f4f4;
					color: #333333;
				}
				select {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					width: 100%;
					max-width: 100%;
					height: 46px;
					padding: 10px 50px 10px 10px;
					box-sizing: border-box;
					font-size : 12px;
					letter-spacing : 1.4px;
					background-color: #ffffff;
					color: #333333;
				}
				button {
					cursor:pointer;
				}
				.wrapSelect {
					position: relative;
					border: 1px solid #bcbcbc;
					border-radius: 0;
					width: 100%;
					box-sizing: border-box;
					&:before {
						position: absolute;
						top: 50%;
						right: 2px;
						display: block;
						content: "";
						width: 35px;
						height: 32px;
						pointer-events: none;
						background-color: #fff;
						border-left:1px solid #BABABA;
						transform: translateY(-50%);
					}
					&:after {
						display: block;
						content: "";
						position: absolute;
						top: 50%;
						right: 13px;
						margin-top: -4px;
						pointer-events: none;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 5px 0 5px;
						border-color: #000000 transparent transparent transparent;
					}
					/* for IE10 */
					select::-ms-expand {
						display: none;
					}
				}
				textarea {
					border: 1px solid #bcbcbc;
					border-radius: 0;
					width:100%;
					height:200px;
					box-sizing: border-box;
					padding:6px 7px 6px;
					font-size: 13px;
					color: #333;
					letter-spacing: 0.04em;
					line-height: 1.55;
					background-color: #f4f4f4;
				}
				> li {
					&:not( :first-child ) {
						//margin-top: 24px;
						//.itemName {
						//	padding-top: 8px;
						//}
					}
					.itemName {
						font-size: 16px;
						font-weight: bold;
						letter-spacing: 1.6px;
						color: #333;
						border-top: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						padding: 14px 30px 14px 30px;
						background-color: #f6f5f6;
					}
					.inputArea {
						padding: 40px 30px 49px;
						.caution {
							margin-top: 10px;
							font-size: 11px;
							color: #666666;
							letter-spacing: 1px;
						}
						> ul {
							li {
								&:not( :first-child ) {
									margin-top: 15px;
								}
							}
						}
					}
					.inputTel {
						letter-spacing: 1px;
					}
					.error {
						position: relative;
						padding-top: 4px;
						padding-left: 1.1em;
						font-size : 12px;
						letter-spacing : 1px;
						color : #CD151B;
						&:before {
							position: absolute;
							left: 0;
							top:2px;
							content:'※';
						}
					}
				}
			}
			.btnContact {
				padding: 0 30px;
				.btn2,
				.btnSend{
						// height: 44px;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						// border-top: 1px solid #666;
						// border-right: 1px solid #666;
						// border-bottom: 1px solid #666;
						background-color: #ec8e19;
						padding: 19px 0;

						background-size: 8px auto;
						background-position: -1px center;
						background-image: url('assets/images/common/btn_border_white.svg');

						overflow: hidden;
						box-sizing: border-box;
						color: #fff;
						.text{
							flex: 1 1 auto;
							font-size: 12px;
							line-height: 1em;
							margin-left: 30px;
						}
						.arrowWhite{
							flex: 0 0 38px;
							margin-right: 15px;
						}
						.arrowWhite:before {
							 content: '';
							 display: block;
							 width: 37px;
							 height: 7px;
							 background-position: right center;
							 background-image: url(assets/images/common/btn_arrow_white.svg);
						 }

					&.center a{
						text-align: center;
					}
				}
			}
		}

		//
		//
		//
		//確認画面
		//
		//
		//
		&.mw_wp_form_confirm {
			.listForm .wrapSelect {
				font-size: 14px;
				position: static;
				padding-left: 0;
				border: 0;
				border-radius: 0;
				width: 100%;
				box-sizing: border-box;
				&:before {
					position: static;
					top: 0;
					left: 0;
					display: none;
					content: "";
					width: auto;
					height: auto;
					pointer-events: none;
					background-color: transparent;
				}
				&:after {
					display: none;
					content: "";
					position: static;
					top: 0;
					left: 0;
					margin-top: 0;
					pointer-events: none;
					width: 0;
					height: 0;
					border-width: 0;
				}
			}

			form {
				.listForm {
					margin-bottom: 35px;
					border-bottom: 0;
					> li {
						display: flex;
						flex-wrap: wrap;
						border-bottom:1px solid #e2e2e2;
						padding: 30px 30px 30px;
						&:first-child {
							border-top:1px solid #e2e2e2;
						}
						&:not( :first-child ) {
							margin-top: 0;
						}
						.itemName {
							flex: 0 0 120px;
							max-width: 120px;
							background-color: transparent;
							border: 0;
							padding: 0;
						}
						.inputArea {
							width: calc(100% - 120px);
							border: 0;
							padding: 0 0 0 10px;
							box-sizing: border-box;
							font-size : 14px;
							line-height: 2;
							color: #333333;
							.caution {
								display: none;
							}
						}
					}
				}
			}
			.inputArea {
				padding-top: 8px;
				font-size: 13px;
				line-height: 2;
				color: #191919;
			}
			.btnContact {
				.btnBack {
					margin-top: 10px;
					// height: 44px;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					//border-top: 1px solid #666;
					//border-right: 1px solid #666;
					//border-bottom: 1px solid #666;
					padding: 19px 0;

					//background-size: 8px auto;
					//background-position: left center;
					//background-image: url('assets/images/common/btn_border.svg');

					overflow: hidden;
					box-sizing: border-box;
					color: #000;
					.text{
						order:2;
						flex: 0 0 auto;
						font-size: 12px;
						line-height: 1em;
						margin-right: 30px;
						margin-left: 15px;
					}
					.arrow{
						order:1;
						flex: 0 0 38px;
						margin-left: 15px;
						&:before {
							content: '';
							display: block;
							width: 37px;
							height: 7px;
							background-position: right center;
							background-image: url(assets/images/common/btn_arrow.svg);
							transform:rotate(180deg)
						}
					}
				}
			}

		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 201px;
		//width: 1024px;
		margin: auto;
		padding-right:50px;
		padding-left:50px;
		.breadcrumb {
			margin-left:0;
			margin-right:0;
		}
		.pageTitle {
			margin-left:0;
			margin-right:0;
		}
		.contactHead {
			margin-top: 70px;
			margin-bottom: 65px;
			margin-right: 0;
			margin-left: 0;
			.lead {
				font-size: 15px;
			}
		}
		.mw_wp_form {
			form {
				.listForm {
					margin-bottom: 35px;
					input[type="text"],
					input[type="email"]{
						width: 100%;
						border: 1px solid #cccccc;
						font-size: 14px;
						line-height: 1.3;
						letter-spacing: 0.04em;
						padding: 10px 16px 10px;
						height: 43px;
						box-sizing: border-box;
						border-radius: 0;
					}
					select {
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						width: 100%;
						max-width: 100%;
						height: 46px;
						padding: 10px 50px 10px 10px;
						box-sizing: border-box;
						font-size : 14px;
						letter-spacing : 1.4px;
						background-color: #ffffff;
						color: #333333;
					}
					.wrapSelect {
						position: relative;
						border: 1px solid #bcbcbc;
						border-radius: 0;
						width: 300px;
						box-sizing: border-box;
						&:before {
							position: absolute;
							top: 50%;
							right: 2px;
							display: block;
							content: "";
							width: 35px;
							height: 32px;
							pointer-events: none;
							background-color: #fff;
							border-left:1px solid #BABABA;
							transform: translateY(-50%);
						}
						&:after {
							display: block;
							content: "";
							position: absolute;
							top: 50%;
							right: 13px;
							margin-top: -4px;
							pointer-events: none;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 10px 5px 0 5px;
							border-color: #000000 transparent transparent transparent;
						}
						/* for IE10 */
						select::-ms-expand {
							display: none;
						}
					}
					textarea {
						border: 1px solid #cccccc;
						border-radius: 0;
						width:322px;
						height:182px;
						box-sizing: border-box;
						padding:10px 8px 10px;
						font-size: 15px;
						color: #000000;
						letter-spacing: 0.04em;
						line-height: 1.55;
						resize: none;
					}
					> li {
						display: flex;
						flex-wrap: wrap;
						border-bottom: 1px solid #e2e2e2;
						border-left: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						&:first-child {
							border-top: 1px solid #e2e2e2;
						}
						&:not( :first-child ) {
							margin-top: 0;
						}
						.itemName {
							display: flex;
							flex:0 0 259px;
							max-width:259px;
							padding: 36px 30px 28px 30px;
							font-size: 15px;
							letter-spacing: 1px;
							font-weight: bold;
							color: #191919;
							background-color: #f6f5f6;
							border-top: 0;
							border-right: 1px solid #e2e2e2;
							border-bottom: 0;
							border-left: 0;
						}
						.inputArea {
							width: calc(100% - 259px);
							border: 0;
							padding: 28px 54px 28px;
							.inputCompanyName{
								//width: 480px;
							}
							.inputName {
								width: 289px;
							}
							.inputMail,
							.inputMailConfirm {
								width: 100%;
							}
							.inputGroupName {
								width: 100%;
							}
							.formDetail {
								width: 100%;
							}
							.caution {
								margin-top: 10px;
								font-size : 12px;
							}
							> ul {
								display: flex;
								width: 100%;
								li {
									&:first-child {
										margin-right: 18px;
									}
									&:not( :first-child ) {
										margin-top: 0;
									}
								}
							}
						}
						.error {
							position: relative;
							padding-top: 2px;
							padding-left: 1.1em;
							font-size: 13px;
							letter-spacing : 1.3px;
							color : #CD151B;
							&:before {
								position: absolute;
								left: 0;
								top:2px;
								content:'※';
							}
						}
					}
				}
				.btnContact {
					padding: 0 30px;
					display: flex;
					align-items: center;
					.btn2,
					.btnSend {
						width: 390px;
						margin:35px auto auto;
						height: 47px;
						transition-duration: 200ms;
						padding: 0;

						.text {
							font-size: 14px;
							transition-duration: 200ms;
							letter-spacing : 1.4px;
						}
						.arrowWhite {
							flex: 0 0 38px;
						}
						&:hover {
							background-color: rgba(#ec8e19, 0.8);
							.text {
								opacity: 0.7;
							}
						}
					}
				}
				input {
					visibility: visible;
				}
			}

			//確認画面
			&.mw_wp_form_confirm {
				form {
					.listForm{
						margin-bottom: 59px;
						.wrapSelect {
							font-size: 14px;

							position: static;
							padding-left: 0;
							border: 0;
							border-radius: 0;
							width: 100%;
							box-sizing: border-box;
							&:before {
								position: static;
								top: 0;
								left: 0;
								display: none;
								content: "";
								width: auto;
								height: auto;
								pointer-events: none;
								background-color: transparent;
							}
							&:after {
								display: none;
								content: "";
								position: static;
								top: 0;
								left: 0;
								margin-top: 0;
								pointer-events: none;
								width: 0;
								height: 0;
								border-width: 0;
							}
						}
						> li {
							padding: 36px 39px 36px;
							border-right: 0;
							border-left: 0;
							&:not(:first-child) {
								.itemName {
									padding-top: 0;
								}
							}
							.itemName {
								flex: 0 0 275px;
								max-width: 275px;
								font-size : 16px;
								letter-spacing : 0.8px;
							}
							.inputArea {
								width: calc(100% - 300px);
								padding-top: 0;
								font-size: 16px;
								letter-spacing: 1px;
								line-height: 1.5;
								color: #333;
								> ul {
									li {
										&:first-child {
											width: 276px;
											margin-right: 10px;
										}
										&:nth-child(2) {
											width: 375px;
										}
									}
								}
							}
						}
					}
					.btnContact {
						position: relative;
						padding: 0 0 0 0;
						.btnSend {
							position: absolute;
							top:10px;
							left: 50%;
							transform:translate(-50%,0);
							width: 390px;
							margin: 0;
							height: 47px;
							transition-duration: 200ms;
							background-color: #ec8e19;
							padding: 0;

							.text{
								font-size: 14px;
								transition-duration: 200ms;
							}
							.arrowWhite{
								flex: 0 0 38px;
							}
							&:hover{
								background-color: rgba(#ec8e19,0.8);
								.text{
									opacity: 0.7;
								}
							}
						}
						.btnBack {
							order:1;
							width: 220px;
							height: 47px;
							transition-duration: 200ms;
							background-color: rgba(#fff,0.0);
							.text{
								font-size: 14px;
								transition-duration: 200ms;
								margin-left: 35px;
							}
							.arrow{
								flex: 0 0 38px;
							}
							&:hover{
								background-color: rgba(#fff,0.1);
								.arrow:before{
									transform: translateX(0) rotate(180deg);
									animation: arrowAnimation2-Contact 500ms ease 0ms
								}
							}

						}
					}
				}
			}
		}
	}

	@keyframes arrowAnimation2-Contact {
		0% {
			transform: translateX(0%) rotate(180deg);
		}
		50% {
			transform: translateX(-100%) rotate(180deg);
		}
		51% {
			transform: translateX(100%) rotate(180deg);
		}
		100% {
			transform: translateX(-0%) rotate(180deg);
		}
	}
}
@mixin placeholder($color) {
	&::placeholder {
		color: $color;
	}

	// IE
	&:-ms-input-placeholder {
		color: $color;
	}

	// Edge
	&::-ms-input-placeholder {
		color: $color;
	}
}
input {
	@include placeholder(#bcbcbc);
}
// --------------------
// btn5
// -------------------- */
.btn5{
	display: flex;
	padding: 0;
	a{
		display: flex;
		align-items: center;
		font-size: 12px;
		letter-spacing: 1.2px;
		color: #333333;
		box-sizing: border-box;

		&:before {
			content: '';
			margin-right: 6px;
			display: block;
			width: 35px;
			height: 7px;
			background-position: right center;
			background-image: url(assets/images/common/btn_arrow.svg);
			background-size:100% auto;
			transform: rotate(-180deg);
		}

		.text{
			flex: 0 0 auto;
			font-size: 12px;
			color: #333333;
		}
	}
	&.center a{
		text-align: center;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1 +1){
		flex:0 0 222px;
		max-width: 222px;
		a{
			box-sizing: border-box;
			&:before {
				content: '';
				margin-right: 21px;
				display: block;
				width: 43px;
				height: 10px;
				background-position: right center;
				background-image: url(assets/images/common/btn_arrow.svg);
				background-size:100% auto;
				transform: rotate(180deg);
			}
			&:hover {
				opacity: 0.7;
				&:before {
					transform: translateX(0) rotate(180deg);
					animation: arrowAnimation-btn5 500ms ease 0ms;
				}
			}
			.text{
				flex: 0 0 auto;
				font-size: 14px;
				letter-spacing: 1.4px;
			}
		}
	}
	@keyframes arrowAnimation-btn5 {
		0% {
			transform: translateX(0%) rotate(180deg);
		}
		50% {
			transform: translateX(-100%) rotate(180deg);
		}
		51% {
			transform: translateX(100%) rotate(180deg);
		}
		100% {
			transform: translateX(-0%) rotate(180deg);
		}
	}
}

// --------------------
// single-member
// --------------------
body.single-member main{
	//$topAddMarin: 100px;

	// styles
	@import 'member/_sectionMemberDetailHead.scss';
	@import 'member/_sectionMemberDetailKV.scss';
	@import 'member/_sectionMemberDetailMain.scss';
	@import 'member/_navigationLocal.scss';
}
// --------------------
// post-type-archive-news
// tax-news_category
// --------------------
body.post-type-archive-recruit main {


	//$topAddMarin: 100px;

	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1) {
		padding-bottom: 100px;
	}
	// PC style
	@media print, screen and (min-width: $breakpoint1) {
		padding-bottom: 100px;
	}

	// styles
	@import 'recruit/_sectionRecruitHead.scss';
	@import 'recruit/_sectionRecruitKV.scss';
	@import 'recruit/_sectionRecruitList.scss';
	@import 'recruit/_sectionCareerProfile.scss';
}
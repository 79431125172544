/*
Theme Name: gempartners
Theme URL:
Description:
Author: 株式会社 sal
Version: 1.0
*/
@charset "UTF-8";

@import 'assets/css/vender/_ress.scss';
@import "assets/css/lib/_mixin.scss";
@import "assets/css/lib/_vars.scss";


/* Google Roboto */
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700');

/* Google Noto Fonts */
@import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');


/* default setting */
body{
	background-color: #fff;
	color: #333;
	transition-duration: 500ms;
	@include YuGothic();

	letter-spacing : 0.1em;
	-webkit-text-size-adjust: 100%;

	&.dark{
		background-color: #333;
	}

	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		font-size : 14px;
		line-height : 1.7;
	}// PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size : 15px;
		line-height : 1.8;

		#root{
			max-width: 1400px;
			margin: 0 auto;
		}
	}

	&:not(.home):not(.page-en){
		main{
			// SP style
			@media print, screen and (max-width: $breakpoint1 - 1) {
				margin-top: 13px;
			}// PC style
			@media print, screen and (min-width: $breakpoint1){
				margin-top: 139px;
				margin-left: 125px;
			}
		}
	}
}

/* IE11のみメイリオ */
@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop,
	body,
	body.home {
		@include Meiryo();
	}
}

a{
	color: #333;
	text-decoration: none;
	transition-duration: 200ms;
	&:hover{
		// text-decoration: underline;
	}
}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	.pc{display: none !important;}
}// PC style
@media print, screen and (min-width: $breakpoint1){
	.sp{display: none !important;}
}


main{
	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		padding-top: 66px;
	}
}


/* components */
@import "assets/css/components/_andmore.scss";
@import "assets/css/components/_arrow.scss";
@import "assets/css/components/_arrowDrop.scss";
@import "assets/css/components/_arrowWhite.scss";
@import "assets/css/components/_breadcrumb.scss";
@import "assets/css/components/_btn1.scss";
@import "assets/css/components/_btn2.scss";
@import "assets/css/components/_btn3.scss";
@import "assets/css/components/_btn4.scss";
@import "assets/css/components/_btn5.scss";
@import "assets/css/components/_footer.scss";
@import "assets/css/components/_head1.scss";
@import "assets/css/components/_head2.scss";
@import "assets/css/components/_header.scss";
@import "assets/css/components/_layout.scss";
@import "assets/css/components/_linkGoogleMap.scss";
@import "assets/css/components/_listNumStyle.scss";
@import "assets/css/components/_listStyle.scss";
@import "assets/css/components/_navigationLocal.scss";
@import "assets/css/components/_pager.scss";
@import "assets/css/components/_pageTitle.scss";
@import "assets/css/components/_topHeader.scss";

/* pages */
@import "assets/css/pages/_business.scss";
@import "assets/css/pages/_company.scss";
@import "assets/css/pages/_contact.scss";
@import "assets/css/pages/_en.scss";
@import "assets/css/pages/_home.scss";
@import "assets/css/pages/_message.scss";
@import "assets/css/pages/_privacy-policy.scss";
@import "assets/css/pages/_security.scss";
@import "assets/css/single/_single-member.scss";
@import "assets/css/single/_single-news.scss";
@import "assets/css/single/_single-recruit.scss";
@import "assets/css/archive/_post-type-archive-member.scss";
@import "assets/css/archive/_post-type-archive-news.scss";
@import "assets/css/archive/_post-type-archive-recruit.scss";
@import 'assets/css/_pc_recruit_archive';
@import 'assets/css/_pc_recruit_single';


/* for IE11 */
// @media all and (-ms-high-contrast:none){
//   *::-ms-backdrop, .foo { color: red }
// }


@media screen and (max-width: 768px){
	@import 'assets/css/_smp_recruit_archive';
	@import 'assets/css/_smp_recruit_single';
}

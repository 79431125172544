// --------------------
// sectionSecurity
// --------------------
.sectionSecurity {
	// SP style
	padding-bottom: 104px;

	.pageTitle {
		margin-bottom: 58px;
		.title {
			.main {
				font-weight: bold;
			}
		}
	}

	.postContent {
		margin-bottom: 88px;
		p:first-child {
			padding-top: 0;
		}

		li + li {
			padding-top: 0;
			margin-top: 25px;
		}
		h2 {
			//padding-top: 78px;
			font-size: 21px;
			line-height:  1.5714;
			letter-spacing: 1.67px;
			color: #333333;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 1.4px;
			color: #333333;
		}		
		.blockText {
			padding-top: 48px;
		}
		ol {
			margin-top: 50px;
			> li {
				position: relative;
				padding-left: 23px;
				counter-increment: listNumber;
				list-style-type: none;
				font-size: 14px;
				line-height: 1.6428;
				letter-spacing: 1.4px;
				color: #333333;
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					content: counter(listNumber);
					font-size : 14px;
					line-height: 1.6428;
					letter-spacing : 1.5px;
					color : #EC8E19;
				}
			}
		}
		.sign{
			text-align: right;
			padding-top: 50px;
			padding-bottom: 50px;
		}

		//config

		//.colorOrange {color: #ec8e19;}
		//.clear{clear: both;}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		//padding-top: 44px;
		padding-bottom: 201px;

		.pageTitle {
			.title {
				.main {
					font-size: 38px;
					line-height: 1.5789;
					letter-spacing: 2.85px;
					color: #333333;
				}
			}
		}

		.postContent {
			margin: auto auto 88px;
			width: 780px;
			li + li {
				margin-top: 15px;
			}
			h2 {
				//padding-top: 87px;
				font-size: 26px;
				line-height: 1.5;
				letter-spacing: 1.3px;
			}
			p {
				padding-top: 27px;
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing: 1.5px;
			}
			.blockText {
				padding-top: 58px;
			}
			ol {
				margin-top: 53px;
			}
			.sign{
				padding-bottom: 70px;
			}
			.btn1{
				width: 390px;
				margin: auto;
			}
		}
	}
}
// breakpoint
$breakpoint1: 768px;
$breakpoint2: 960px;

// size
$pcMinWidth: 1000px + 50px + 50px;
$pcMaxWidth: 1344px + 50px + 50px;


// color
$colorRed: #cf111b;
$colorOrange: #ec8e19;
$colorOrange2: #e36f12;
$colorOrange3: #e45514;
// // icons
// $iconMap: (
// 	icon-mypage:      '\e900',
// 	icon-menu:        '\e901',
// 	icon-close:       '\e902',
// 	icon-right:       '\e903',
// 	icon-top:         '\e904',
// 	icon-plus:        '\e905',
// 	icon-blank:       '\e906',
// 	icon-blank-small: '\e907',
// 	icon-airplane:    '\e908',
// 	icon-calendar:    '\e909',
// 	icon-home2:       '\e90b',
// 	icon-home1:       '\e90a',
// 	icon-entry:       '\e90c',
// );

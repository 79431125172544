// --------------------
// sectionCareerProfile
// --------------------
.sectionCareerProfile {
	// SP style
	padding-top: 82px;
	padding-bottom: 149px;
	.sectionTitle{
		margin-bottom: 57px;
		font-size: 24px;
		@include fontBold();
		letter-spacing: 1.2px;
		color: #000000;
		text-align: center;
	}
	.informationDetail {
		@include dotBorderSp(x, left top);
		margin-top: 59px;
		padding-top: 58px;
		&:first-of-type {
			margin-top: 0;
			padding-top: 0;
			background-image: none;
		}
		.inner {
			.titleJob {
				padding-bottom: 30px;
				font-size: 20px;
				@include fontBold();
				line-height: 1.35;
				letter-spacing: 0;
				color: #000000;
			}
			.person {
				margin-bottom: 42px;
				.name {
					display: flex;
					align-items: flex-end;
					margin-bottom: 13px;
					.jaName {
						margin-right: 14px;
						font-size: 14px;
						letter-spacing: 1.4px;
						color: #333333;
					}
					.enName {
						@include english(400);
						font-size: 11px;
						letter-spacing: 0.5px;
						color: #A2A2A2;
					}
				}
				.text {
					font-size: 11px;
					line-height: 1.46;
					letter-spacing: 1px;
					color: #666666;
				}
			}
			.photo img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			.explanation {
				margin-top: 47px;
				font-size: 14px;
				line-height: 1.7142;
				letter-spacing: 1.4px;
				color: #333333;
			}
			.timeSchedule {
				margin-top: 46px;
				.title {
					display: flex;
					align-items: center;
					&:before {
						content:'';
						display: block;
						width: 40px;
						height: 41px;
						background-size: 40px auto;
						background-position: left center;
						background-image: url('assets/images/common/icon_watch.svg');
						margin-right: 19px;
					}
					.text {
						font-size: 16px;
						line-height: 1.5312;
						letter-spacing: 1.6px;
						color: #333333;
					}

				}
				dl {
					margin-top: 20px;
					display: flex;
					flex-wrap: wrap;
					dt {
						flex:0 0 64px;
						max-width: 64px;
						padding-top: 13px;
						font-size: 13px;
						line-height: 1.4615;
						letter-spacing: 1.3px;
						color: #333333;
						@include english();
						@include fontBold();
						&:first-of-type {
							padding-top: 0;
						}
					}
					dd {
						flex:0 0 calc(100% - 64px);
						max-width:calc(100% - 64px);
						padding-top: 13px;
						font-size: 13px;
						line-height: 1.4615;
						letter-spacing: 1.3px;
						color: #666666;
						&:first-of-type {
							padding-top: 0;
						}
					}
				}
			}
		}
		.btn1{
			margin-top: 57px;
			margin-bottom: 0;
			a {
				.text, .arrow {}
			}
		}
		.timeSchedule h4 {}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top: 137px;
		padding-bottom: 137px;
		.secTitle{
			font-size: 28px;
			line-height: 1;
			letter-spacing : 1.4px;
			color: #333333;
			margin-bottom: 90px;
		}
		.informationDetail {
			margin-top: 90px;
			padding-top: 90px;
			.inner {
				position: relative;
				padding-left: 493px;
				min-height:600px;
				.titleJob {
					padding-bottom: 47px;
				}
				.person {
					.name {
						margin-bottom: 8px;
						.jaName {
							font-size: 15px;
							letter-spacing: 1.5px;
						}
						.enName {
							font-size: 12px;
							letter-spacing: 0.6px;
						}
					}
					.text {
					}
				}
				.photo {
					position: absolute;
					left:0;
					top:0;
					width: 400px;
					img {
					}
				}
				.explainText {
					font-size: 15px;
					line-height: 26px;
					letter-spacing: 1.5px;
				}
				.timeSchedule {
					margin-top: 57px;
					.title {
						&:before {
							content:'';
							display: block;
							width: 40px;
							height: 41px;
							background-size: 40px auto;
							margin-right: 19px;
						}
						.text {
							font-size: 16px;
							line-height: 1.5312;
							letter-spacing: 1.6px;
							color: #333333;
						}

					}
					dl {
						margin-top: 26px;
						dt {
							font-size: 13px;
							letter-spacing: 1.3px;
						}
						dd {
							font-size: 13px;
							letter-spacing: 1.3px;
						}
					}
				}
			}
			.btn1{
				display: flex;
				justify-content: center;
				margin: 70px auto 0;
				width: 481px;
				a {
					width: 100%;
					.text, .arrow {}
				}
			}
			.timeSchedule h4 {}
		}

	}
}

// --------------------
// sectionWhatMeDo
// --------------------
.sectionWhatMeDo{
// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.layoutSolid{
		// height: 530px + $topAddMarin;
		position: relative;
		padding-bottom: 250px;
	}
	.head1{
		margin-right: -25px;
		.title{
			margin-right: 15px;
		}
	}
	.logo {
		padding-bottom: 30px;
		a {
			display: block;
			.logoImage {
				width: 63%;
				margin: auto;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
			}
			.urlText {
				padding-top: 15px;
				text-align: center;
				font-size: 11px;
				letter-spacing: 1.4px;
				color: #898989;
				text-decoration: underline;
			}
		}
	}
	.block1 {
		.blockTitle {
			padding-bottom: 38px;
			font-size : 17px;
			line-height : 1.4705;
			letter-spacing : 0.42px;
			color : #000000;
			font-weight: bold;
		}
		.text {
			p {
				@include english(400);
				font-size : 14px;
				line-height : 1.7142;
				letter-spacing : 0.7px;
				color : #333333;
				overflow: hidden;
				& + p {
					margin-top: 20px;
				}
				em {
					font-style: normal;
					color: $colorOrange2;
				}
				&.ellipsis {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					text-overflow: ellipsis;
				}

			}

			.btnReadMore1 {
				text-align: center;
				cursor: pointer;
				span {
					display: inline-block;
					padding: 13px 44px 13px;
					border-radius:46px;
					vertical-align: middle;
					border:1px solid #d5d5d5;
					font-size: 11px;
					letter-spacing: 0.875px;
					color: #666666;
				}
			}
		}
	}

	.btn2{
		margin: 40px 0 40px;
		a {
			background-color: #ce3936;
			background-position: -1px center;
		}
	}

	.arrowDrop{
		bottom: 0;
		left: 50%;

	}



}

// PC style
@media print, screen and (min-width: $breakpoint1){
	position: relative;
	// padding-top: 60px;
	padding-top: 0;
	padding-left: 200px;

	.layoutSolid{
		// height: 840px + 60px + $topAddMarin;
		//height: 840px + 60px;
		//position: relative;
		padding-bottom: 200px;
	}
	.head1{
		.title{
			margin-right: 50px;
		}
		.line{
			// margin-right: 50px;
			// margin-left: -200px;
		}
	}
	.logo {
		width: 360px;
		margin: auto;
		padding-top: 101px;
		a {
			display: block;
			.logoImage {
				width: 360px;
				margin: auto;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
			}
			.urlText {
				padding-top: 30px;
				font-size: 15px;
				line-height: 1.73333;
				letter-spacing: 0.5px;
				color: #666666;
				text-decoration: none;
				text-align: center;
			}
			&:hover {
				.logoImage {
					opacity: 0.7;
				}
				.urlText {
					text-decoration: underline;
				}
			}
		}
	}
	// .text{
	// 	@include fontBold();
	// 	font-size: 20px;
	// 	line-height: 2.9;
	// }
	.block1 {
		width: 751px;
		padding-top: 46px;
		margin:auto;
		.blockTitle {
			font-size: 20px;
			line-height: 1.5;
			letter-spacing: 0.5px;
			color : #000000;
		}
		.text {
			margin-top: 37px;
			p {
				height: auto !important;
				font-size: 15px;
				line-height: 1.73333;
				letter-spacing: 0.8px;
				& + p {
					margin-top: 20px;
				}
			}
		}
		.btnReadMore1 {
			display: none;
		}
	}

	.btn2{
		// width: 838px;
		margin: 85px auto 0 auto;
		a {
			background-color: #ce3936;
			background-position: -1px center;
		}
	}

	.arrowDrop{
		top: 175px;
		left: 55px;
	}


}
}

// --------------------
// company
// --------------------
body.page-company main{


	//$topAddMarin: 100px;

	// styles
	@import 'company/_sectionCompanyHead.scss';
	@import 'company/_sectionCompanyKV.scss';
	@import 'company/_sectionCompanyProfile.scss';
}
// @import '../../../../../css/function.scss';




#container {
	#content.single-recruit {
		h1 {
			font-weight: bold;
			font-size: 26px;
		}
		
		.area-content {
			background-color: lightgreen;
			
			h2 {
				font-weight: bold;
				font-size: 20px;
				margin: 1em 0
			}
			
			h3 {
				font-weight: bold;
				font-size: 16px;
				margin: 1em 0
			}
		}
		
	}
}

body {
//	&.page-template-page-contact-thanks,
//	&.page-template-page-document-thanks{
//		@import 'assets/css/_pc_contact_thanks';
//	}
}

// --------------------
// sectionRecruitList
// --------------------
.sectionRecruitList {
	// SP style
	padding-top: 82px;
	padding-bottom: 90px;
	background-color: #F6F5F6;

	.inner {
		.sectionTitle{
			margin-bottom: 48px;
			font-size: 24px;
			@include fontBold();
			letter-spacing: 1.2px;
			color: #000000;
			text-align: center;
		}
		.lead {
			font-size: 14px;
			line-height: 1.7142;
			letter-spacing: 1.4px;
			color: #333333;
		}
		.entries{
			margin-top: 48px;
			.listEntry .btn3{
				margin-top: 11px;
				&:first-child{
					margin-top: 0;
				}
				a {
					padding: 28px 0 28px;
					.text {
						font-size: 13px;
						@include fontBold();
						line-height: 1.5384;
						letter-spacing: 0.8px;
						color: #333333;
						margin-left: 27px;
					}
					.arrow {
					}
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top: 128px;
		padding-bottom: 137px;

		.inner {
			.sectionTitle{
				margin-bottom: 46px;
				font-size: 28px;
				letter-spacing: 1.4px;
				color: #333333;
			}
			.lead {
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing : 1.5px;
				color : #333333;
				text-align: center;
			}
			.entries{
				margin-top: 59px;
				overflow: hidden;
				.listEntry {
					display: flex;
					flex-wrap:wrap;
					margin:-11px -11px;
					.btn3{
						display: flex;
						flex:1 1 50%;
						max-width:50%;
						height: auto;
						padding: 11px 11px;
						margin-top: 0;
						&:first-child{
							margin-top: 0;
						}
						a {
							padding: 39px 30px 42px 0;
							min-height: 65px;
							box-sizing: content-box;
							overflow: visible;
							display: flex;
							flex-wrap: wrap;
							height: auto;
							flex:1 1 auto;
							.text {
								font-size: 15px;
								line-height: 1.5333;
								letter-spacing: 1.5px;
								color: #333333;
								margin-left: 67px;
								display: flex;
								flex: 0 0 290px;
							}
							.arrow {
								margin-left: 45px;
							}
						}
					}
				}
			}
		}

	}
}

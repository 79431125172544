// --------------------
// en
// --------------------
body.page-en{
	// #header{
	// 	display: none;
	// }

	// background-color: #fff;
	// transition-duration: 500ms;
	//
	// &.dark{
	// 	background-color: #333;
	// }
	@include english(400);


	// SP style
	@media print, screen and (max-width: $breakpoint1 - 1){
		.layoutSolid,
		.layoutLiquid{
			padding: 0 25px;
		}
		// logo
		#header{
			.siteId a:before{
				// display: none;
				// opacity: 0;
				margin-top: -100px;
			}
			.siteId.scrolled a:before{
				// display: none;
				// opacity: 1;
				margin-top: 0;
				// background-color: #ddd;
				// background-size: 108px 14px;
			}
		}
		
		.readmore,
		.readmore1 {
			overflow: hidden;
			transition: height 500ms;
		}
		
		.readmore p.ellipsis,
		.readmore1 p.ellipsis {
			 display: -webkit-box; 
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			text-overflow: ellipsis;
		}
		
		.btnReadMore.sp {
			text-align: center;
		}
		.btnReadMore.sp a {
			display: inline-block;
			padding: 13px 44px 13px;
			border-radius: 46px;
			vertical-align: middle;
			border: 1px solid #d5d5d5;
			font-size: 11px;
			letter-spacing: 0.875px;
			color: #666666;
		}
		
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.layoutFull{
			min-width: 1050px + 200px;
			max-width: 1400px;
			overflow: hidden;
			margin-left: 0;
			padding-left: 0;
		}
		.layoutLiquid{
			min-width: 1050px;
		}
		.layoutSolid{
			width: 1050px;
			padding-left: 0;
		}
	}
}
body.page-en main{

	$topAddMarin: 100px;

	// styles
	@import 'en/_sectionTop.scss';
	@import 'en/_sectionMission';
	@import 'en/_sectionWhatMeDo';
	@import 'en/_sectionProfile.scss';
	@import 'en/_sectionMember.scss';

}

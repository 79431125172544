// --------------------
// sectionProductsNews
// --------------------
.sectionProductsNews {
	// SP style
	margin-top: 56px;
	.inner {
		padding: 38px 19px 38px;
		background-color: #ffffff;
		.sectionTitle2 {
			padding: 0 0 13px 20px;
			background-position: 0 0.7em;
			background-size: 9px 9px;
			background-repeat: no-repeat;
			background-image: url(assets/images/common/icon_list.svg);
			span {
				font-size: 16px;
				letter-spacing: 0.7px;
				color: #333333;
			}
		}
		ul.list {
			li{

				& + li {
					@include dotBorderSp(x, left top);

				}

				a{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					position: relative;
					padding: 23px 40px 23px 0;

					.text{
						flex: 1 1 100%;
						margin-top: 5px;
						color: #333;
						font-size: 13px;
						line-height: 28px;
					}

					.arrow{
						position: absolute;
						top: 50%;
						right: 0;
					}
				}
			}
		}

	}
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin-top:58px;
		.inner {
			padding: 34px 40px 18px 39px;
			.sectionTitle2 {
				background-position: 0 0.55em;
				padding: 0 0 19px 40px;
				background-size: 16px 16px;
				span {
					font-size: 20px;
					letter-spacing: 0.7px;
				}
			}
			ul.list {
				margin: 0 0;
				li{
					padding: 11px 0;

					a{
						padding: 11px 40px 11px 0;
						&:hover{
							.text{
								text-decoration: underline;
							}
						}

						.text{
							margin-top: 0;
							font-size: 14px;
							line-height: 1.7142;
							letter-spacing: 1.4px;
						}

						.arrow{
							transform:translate(0,-50%);
						}
					}
				}
			}

		}
	}
}

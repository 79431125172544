// --------------------
// btn3
// -------------------- */
.btn3{
	a{
		// height: 44px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;
		padding: 19px 0;

		background-size: 8px auto;
		background-position: left center;
		background-image: url('assets/images/common/btn_border_gray.svg');

		overflow: hidden;
		box-sizing: border-box;
		color: #000;
		.text{
			flex: 1 1 auto;
			font-size: 12px;
			line-height: 1em;
			margin-left: 30px;
		}
		.arrow{
			flex: 0 0 38px;
			margin-right: 15px;
		}
	}
	&.center a{
		text-align: center;
	}

// PC style
@media print, screen and (min-width: $breakpoint1 +1){
	width: 300px;
	a{
		height: 47px;
		transition-duration: 200ms;
		background-color: rgba(#fff,1);
		padding: 0;

		background-size: 14px auto;
		background-position: -1px center;

		.text{
			font-size: 14px;
			transition-duration: 200ms;
		}
		.arrow{
			flex: 0 0 38px;
		}
		&:hover{
			background-color: rgba(#fff,0.7);
			.text{
				opacity: 0.7;
			}
		}
	}
}
}
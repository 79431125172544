// --------------------
// sectionNews
// --------------------
.sectionNews {
	// SP style
	padding-bottom: 104px;

	.displayEntryInfo {
		display: flex;
		align-items: center;
		margin-bottom: 38px;
		.category {
			background-color: #333333;
			padding: 0 10px 0;
			height: 16px;
			box-sizing: border-box;
			font-size: 11px;
			letter-spacing: 0.5px;
			color: #FFFFFF;
		}
		time {
			padding: 0 14px 0;
			height: 16px;
			box-sizing: border-box;
			font-size : 11px;
			@include english(400);
			letter-spacing : 0.5px;
			color : #919191;
		}
	}

	.pageTitle {
		margin-bottom: 58px;
		.title {
			.main {
				font-size: 23px;
				font-weight: bold;
				line-height: 1.5434;
				letter-spacing: 1.5px;
				color: #333333;
				@include YuGothic();
			}
		}
	}

	.postContent {
		margin-bottom: 88px;
		p:first-child {
			padding-top: 0;
		}

		li + li {
			padding-top: 0;
			margin-top: 25px;
		}
		h2 {
			padding-top: 78px;
			font-size: 21px;
			line-height:  1.5714;
			letter-spacing: 1.67px;
			color: #333333;
		}
		h3 {
			padding-top: 60px;
			font-size: 16px;
			line-height: 1.2307;
			letter-spacing: 1.2px;
			color: #333333;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 1.4px;
			color: #333333;
		}
		strong {
			font-size: 14px;
			font-weight: bold;
			line-height: 2;
			letter-spacing: 1.4px;
		}
		a {

		}
		.bgOrange {
			background-color: #ec8e19;
			color: #FFFFFF;
		}
		> hr {
			margin-top: 30px;
			border:0;
			border-top:1px solid #D6D6D6;
		}
		.blockText {
			padding-top: 48px;
			li + li {
				padding-top: 0;
				margin-top: 25px;
			}
			b {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + b{
				display: block;
				padding-top: 20px;
			}
			strong {
				font-size: 14px;
				line-height: 2;
				font-weight: bold;
			}
			p + strong {

			}
		}
		.caution {
			font-size: 11px;
			line-height: 1.5714;
			letter-spacing: 1.1px;
			color: #666666;
		}
		.blockDetail {
			margin-top: 58px;
			background-color: #f6f5f6;
			padding: 32px 23px;
			p:not(:first-child){
				padding-top: 20px;
			}
			li + li {
				padding-top: 0;
				margin-top: 25px;
			}
			.text1 {
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 1px;
				color: #333333;
			}
			.text2 {
				padding-top: 20px;
			}
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		.tduOrange {
			color: #ec8e19;
			text-decoration: underline;
			.text {
				color: #333333;
			}
		}
		.bdbGray {
			padding-bottom: 10px;
			border-bottom: 1px solid #e2e2e2;
		}
		a {
			font-size : 14px;
			letter-spacing: 1.4px;
			color: #808080;
			text-decoration: underline;
		}
		[class^='btn']{
			margin-top: 50px;
			a {
				text-decoration: none;
				.text {
					color: #000;
				}
				.arrow {
				}
			}
		}
		> ol {
			margin-top: 50px;
			> li {
				position: relative;
				padding-left: 23px;
				counter-increment: listNumber;
				list-style-type: none;
				font-size: 14px;
				line-height: 1.6428;
				letter-spacing: 1.4px;
				color: #333333;
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					content: counter(listNumber);
					font-size : 14px;
					line-height: 1.6428;
					letter-spacing : 1.5px;
					color : #EC8E19;
				}
			}
		}
		> ul {
			margin-top: 50px;
			> li {
				position: relative;
				padding-left: 23px;
				counter-increment: listNumber;
				list-style-type: none;
				font-size: 14px;
				line-height: 1.6428;
				letter-spacing: 1.4px;
				color: #333333;
				&:before {
					content:'';
					position: absolute;
					left: 0;
					top: 6px;
					width: 10px;
					height: 11px;
					font-size : 14px;
					line-height: 1.6428;
					letter-spacing : 1.5px;
					color : #EC8E19;
					background-position: left top ;
					background-repeat:no-repeat ;
					background-size: 10px 10px;
					background-image: url(assets/images/common/icon_list.svg);
				}
			}
		}
		figure {
			padding-top: 80px;
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}
			> .notes {
				padding-top: 17px;
				font-size: 11px;
				line-height: 1.574;
				letter-spacing: 1px;
				color: #666666;
			}
		}

		.blockText2 {
			display: flex;
			flex-direction: column;
			margin-top: 85px;

			p:first-child{
				padding-top: 0;
			}
			li + li {
				padding-top: 0;
				margin-top: 25px;
			}
			figure {
				img, .notes {}
			}
			> .notes {
				padding-top: 10px;
			}
			.order1 {
				order:1;
				padding-bottom: 35px;
			}
			.order2 {
				order:2;
			}
		}

		.youtube {
			margin-top: 85px;
			padding-bottom: 56.25%;
			height: 0;
			position: relative;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		table {
			padding-top: 88px;
			display: block;
			border-collapse: collapse;
			tbody {
				display: block;
			}
			tr {
				display: block;
				border-top: 1px solid #e2e2e2;
				& + tr {
					border-top: 0;
				}
				th {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					background-color: #f6f5f6;
					padding: 18px 23px 18px 23px;
					font-weight: normal;
					text-align: left;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
				td {
					display: block;
					border-left: 1px solid #e2e2e2;
					border-bottom: 1px solid #e2e2e2;
					border-right: 1px solid #e2e2e2;
					border-collapse: collapse;
					padding: 18px 23px 18px 23px;
					font-size: 13px;
					line-height: 1.826;
					letter-spacing: 1.3px;
					color: #333333;
				}
			}
		}
		.fwb {
			font-weight:bold;
		}

		//config

		.colorOrange {color: #ec8e19;}
		.clear{clear: both;}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 201px;

		.displayEntryInfo {
			margin-bottom: 40px;
			.category {
				padding: 3px 29px 6px;
				height: 25px;
				box-sizing: border-box;
				font-size: 11px;
				letter-spacing: 0.5px;
			}
			time {
				padding: 0 26px 0;
				height: 25px;
				box-sizing: border-box;
				font-size : 13px;
				@include english(400);
				letter-spacing : 0.7px;
				color : #A3A3A3;
			}
		}

		.pageTitle {
			margin-bottom: 58px;
			.title {
				.main {
					font-size: 38px;
					line-height: 1.5789;
					letter-spacing: 1.42px;
				}
			}
		}

		.postContent {
			margin: auto auto 88px;
			width: 780px;
			p:first-child {
				padding-top: 0;
			}

			li + li {
				padding-top: 0;
				margin-top: 15px;
			}
			h2 {
				padding-top: 87px;
				font-size: 26px;
				line-height: 1.5;
				letter-spacing: 1.3px;
				color: #333333;
			}
			h3 {
				padding-top: 60px;
				font-size: 16px;
				line-height: 1.2307;
				letter-spacing: 1.2px;
				color: #333333;
			}
			p {
				padding-top: 27px;
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing: 1.5px;
				color: #333333;
			}
			strong {
				font-size: 14px;
				font-weight: bold;
				line-height: 2;
				letter-spacing: 1.4px;
			}
			a {

			}
			.bgOrange {
				background-color: #ec8e19;
				color: #FFFFFF;
			}
			> hr {
				margin-top: 58px;
			}
			.blockText {
				padding-top: 58px;
				li + li {
					padding-top: 0;
					margin-top: 15px;
				}
				b {
					font-size: 14px;
					line-height: 2;
					font-weight: bold;
				}
				p + b{
					display: block;
					padding-top: 27px;
				}
				strong {
					font-size: 14px;
					line-height: 2;
					font-weight: bold;
				}
				p + strong {
					display: block;
					padding-top: 27px;
				}
			}
			.caution {
				font-size: 11px;
				line-height: 1.5714;
				letter-spacing: 1.1px;
				color: #666666;
			}
			.blockDetail {
				margin-top: 60px;
				background-color: #f6f5f6;
				padding: 46px 50px;
				p:not(:first-child){
					padding-top: 20px;
				}
				li + li {
					padding-top: 0;
					margin-top: 15px;
				}
				.text1 {
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 1px;
					color: #333333;
				}
				.text2 {
					padding-top: 20px;
				}
			}
			.bdb {
				padding-bottom: 26px;
				border-bottom: 1px solid #e2e2e2;
			}
			.tduOrange {
				color: #ec8e19;
				text-decoration: underline;
				.text {
					color: #333333;
				}
			}
			.bdbGray {
				padding-bottom: 10px;
				border-bottom: 1px solid #e2e2e2;
			}
			a {
				font-size : 14px;
				letter-spacing: 1.4px;
				color: #808080;
				text-decoration: underline;
			}
			[class^='btn']{
				margin-top: 50px;
				a {
					text-decoration: none;
					.text {
						color: #000;
					}
					.arrow {
					}
				}
			}
			> ol {
				margin-top: 53px;
				> li {
					position: relative;
					padding-left: 23px;
					counter-increment: listNumber;
					list-style-type: none;
					font-size: 14px;
					line-height: 1.6428;
					letter-spacing: 1.4px;
					color: #333333;
					&:before {
						position: absolute;
						left: 0;
						top: 0;
						content: counter(listNumber);
						font-size : 14px;
						line-height: 1.6428;
						letter-spacing : 1.5px;
						color : #EC8E19;
					}
				}
			}
			> ul {
				margin-top: 53px;
				> li {
					position: relative;
					padding-left: 23px;
					counter-increment: listNumber;
					list-style-type: none;
					font-size: 14px;
					line-height: 1.6428;
					letter-spacing: 1.4px;
					color: #333333;
					&:before {
						content:'';
						position: absolute;
						left: 0;
						top: 6px;
						width: 10px;
						height: 11px;
						font-size : 14px;
						line-height: 1.6428;
						letter-spacing : 1.5px;
						color : #EC8E19;
						background-position: left top ;
						background-repeat:no-repeat ;
						background-size: 10px 10px;
						background-image: url(assets/images/common/icon_list.svg);
					}
				}
			}
			figure {
				padding-top: 96px;
				img {
					width: 100%;
					height: auto;
					vertical-align: top;
				}
				> .notes {
					padding-top: 19px;
					font-size: 11px;
					line-height: 1.574;
					letter-spacing: 1px;
					color: #666666;
				}
			}

			.blockText2 {
				display: block;
				flex-direction: row;
				margin-top: 97px;

				p:first-child{
					padding-top: 0;
				}
				li + li {
					padding-top: 0;
					margin-top: 25px;
				}
				figure {
					padding-top: 0;
					img, .notes {}
				}
				.notes {
					padding-top: 27px;
				}
				.order1 {
					order:0
				}
				.order2 {
					order:0
				}
				.text {

				}
				.flR {
					float: right;
					margin: 0 0 72px 72px;
				}
				.flL {
					float: left;
					margin: 0 72px 72px 0;
				}
				figure.flR,
				figure.flL{
					width: 370px;
				}
			}
			.blockText2{
				zoom:1;
			}

			.blockText2:after{
				content:"";
				display:block;
				clear:both;
			}

			.youtube {
				margin-top: 85px;
				padding-bottom: 56.25%;
				height: 0;
				position: relative;

				iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
			table {
				padding-top: 0;
				margin-top: 100px;
				display: table;
				border-collapse: collapse;
				width: 100%;
				tbody {
					display: table-row-group;
				}
				tr {
					display: table-row;
					border-top: 1px solid #e2e2e2;
					& + tr {
						border-top: 0;
					}
					th {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						background-color: #f6f5f6;
						padding: 18px 23px 18px 23px;
						font-weight: normal;
						text-align: left;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
					td {
						display: table-cell;
						border-left: 1px solid #e2e2e2;
						border-bottom: 1px solid #e2e2e2;
						border-right: 1px solid #e2e2e2;
						border-collapse: collapse;
						padding: 18px 23px 18px 23px;
						font-size: 13px;
						line-height: 1.826;
						letter-spacing: 1.3px;
						color: #333333;
					}
				}
			}
			.fwb {
				font-weight:bold;
			}
		}
	}
}
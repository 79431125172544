// --------------------
// sectionMember
// --------------------
.sectionMember {
	// SP style
	padding-bottom: 50px;
	overflow: hidden;

	ul.entries {
		display: flex;
		flex-wrap: wrap;
		margin:-18px -8px;
		li {
			display: flex;
			flex: 0 0 50%;
			max-width: 50%;
			box-sizing: border-box;
			padding:18px 8px;
			a {
				display: block;
				width: 100%;
				figure {
					img {
						width: 100%;
						height: auto;
						vertical-align: top;
					}
				}
				.position {
					padding-top: 7px;
					font-size: 10px;
					letter-spacing: 1px;
					color: #000000;
				}
				.nameJa {
					padding-top: 5px;
					font-size: 17px;
					font-weight: bold;
					letter-spacing: 1.75px;
					color: #333333;
				}
				.nameEnglish {
					font-size : 10px;
					letter-spacing : 1px;
					color : #A2A2A2;
					@include english(400);
				}
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 193px;

		ul.entries {
			margin:-19px -20px;
			li {
				flex: 0 0 25%;
				max-width: 25%;
				padding:19px 20px;
				a {
					&:hover {
						opacity: 0.7;
					}
					.position {
						padding-top: 15px;
						font-size: 12px;
						letter-spacing: 1.2px;
						color: #000000;
					}
					.nameJa {
						padding-top: 3px;
						font-size: 19px;
						letter-spacing: 1.43px;
						color: #333333;
					}
					.nameEnglish {
						font-size: 12px;
						letter-spacing: 0.6px;
						color: #A2A2A2;
					}
				}
			}
		}
		.andmore {
			margin-top: 74px;
			padding-top: 24px;
			@include dotBorder(x, left top);
			p {
				font-size: 16px;
			}
		}
	}
}
// --------------------
// navigationLocal
// --------------------
.navigationLocal {
	// SP style
	margin-top: 70px;

	@media print, screen and (min-width: $breakpoint1) {
		// PC style
		margin-top: 129px;
		padding-bottom: 201px;
	}
}
// @import '../../../../../css/function.scss';




#container {
	#content.archive {
		h1 {
			font-weight: bold;
			font-size: 26px;
		}
		
		h2 {
			font-weight: bold;
			font-size: 26px;
		}
		
		.entries {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.entry {
				font-size: 10px;
				border: 1px solid green;
				box-sizing: border-box;
				padding: 5px;
				width: 24%;
				margin-bottom: 5px;
				.title {
					font-size: 12px;
				}
			}
				
			.button {
				font-size: 10px;
				
				// padding: 10px 10px 10px 10px;
				a {
					border: 1px solid green;
					display: block;
					width: 90%;
					text-align: center;
					
					position: relative;
					&:after {
						content: '';
						width: 8px;
						height: 8px;
						border-top: 1px solid red;
						border-right: 1px solid red;
						display: inline-block;
						transform: translateY(-50%) rotate(45deg);
						
						position: absolute;
						top: 50%;
						right: 8%;
					}
				}
			
			}
		}
	}

}

body {
//	&.page-template-page-contact-thanks,
//	&.page-template-page-document-thanks{
//		@import 'assets/css/_pc_contact_thanks';
//	}
}

// --------------------
// sectionPrivacyPolicy
// --------------------
.sectionPrivacyPolicy {
	// SP style
	padding-bottom: 104px;

	.postContent {
		//margin-bottom: 88px;
		p:first-child {
			padding-top: 0;
		}

		li + li {
			padding-top: 0;
			margin-top: 25px;
		}
		h2 {
			//padding-top: 78px;
			font-size: 21px;
			line-height:  1.5714;
			letter-spacing: 1.67px;
			color: #333333;
		}
		h3 {
			padding-top: 60px;
			font-size: 16px;
			line-height: 1.2307;
			letter-spacing: 1.2px;
			color: #333333;
		}
		p {
			padding-top: 20px;
			font-size: 14px;
			line-height: 2;
			letter-spacing: 1.4px;
			color: #333333;
			a.mail {
				font-size : 14px;
				letter-spacing: 1.4px;
				color: #808080;
				text-decoration: underline;
			}
		}		
		.blockText {
			padding-top: 48px;
		}
		.bdb {
			padding-bottom: 26px;
			border-bottom: 1px solid #e2e2e2;
		}
		ol {
			margin-top: 50px;
			> li {
				position: relative;
				padding-left: 23px;
				counter-increment: listNumber;
				list-style-type: none;
				font-size: 14px;
				line-height: 1.6428;
				letter-spacing: 1.4px;
				color: #333333;
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					content: counter(listNumber);
					font-size : 14px;
					line-height: 1.6428;
					letter-spacing : 1.5px;
					color : #EC8E19;
				}
			}
		}
		
		ul {
			margin-top: 30px;
			> li {
				position: relative;
				padding-left: 23px;
				counter-increment: none;
				list-style-type: none;
				font-size: 14px;
				line-height: 1.6428;
				letter-spacing: 1.4px;
				color: #333333;
				&:before {
					content:'';
					position: absolute;
					left: 0;
					top: 6px;
					width: 10px;
					height: 11px;
					font-size : 14px;
					line-height: 1.6428;
					letter-spacing : 1.5px;
					color : #EC8E19;
					background-position: left top ;
					background-repeat:no-repeat ;
					background-size: 10px 10px;
					background-image: url(assets/images/common/icon_list.svg);
				}
			}
		}
		.sign{
			text-align: right;
		}
		.btn1{
			padding-top: 70px;
		}
		/*.fwb {
			font-weight:bold;
		}*/
		
		

		//config

		//.colorOrange {color: #ec8e19;}
		//.clear{clear: both;}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		//padding-top: 44px;
		padding-bottom: 201px;


		.postContent {
			//margin: auto auto 88px;
			margin:auto;
			width: 780px;
			li + li {
				margin-top: 15px;
			}
			h2 {
				//padding-top: 87px;
				font-size: 26px;
				line-height: 1.5;
				letter-spacing: 1.3px;
			}
			p {
				padding-top: 27px;
				font-size: 15px;
				line-height: 1.7333;
				letter-spacing: 1.5px;
			}
			.blockText {
				padding-top: 58px;
			}
			ol {
				margin-top: 53px;
			}
			ul {
				margin-top: 23px;
			}
			.btn1{
				width: 390px;
				margin: auto;
			}
		}
	}
}
// --------------------
// linkGoogleMap
// --------------------
.linkGoogleMap {
	margin-top: 20px;
	padding-left: 19px;
	display: block;
	position: relative;
	&:before {
		content:'';
		position: absolute;
		left:0;
		top:2px;
		display: block;
		width: 11px;
		height: 16px;
		background-image: url(assets/images/common/icon_map.svg);
		background-repeat: no-repeat;
		background-size:11px 16px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-left: 30px;

		&:hover {
			opacity: 0.7;
		}

		&:before {
			width: 14px;
			height: 20px;
			background-size:14px 20px;
		}
	}
}

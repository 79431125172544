// --------------------
// navigationLocal
// --------------------
.navigationLocal{
	// SP style
	display: flex;
	justify-content: center;
	.prev {
		display: flex;
		flex:1 1 29.4%;
		max-width: 29.4%;
		box-sizing: border-box;
		align-items: center;
		padding: 0;
		border-right: 1px solid #d6d6d6;

		a {
			display: flex;
			align-items: center;
			padding-right: 13%;
			width: 100%;
			font-size: 11px;
			font-weight: bold;
			letter-spacing: 1.1px;
			color: #000000;
			@include english(400);

			&:before {
				content: '';
				margin-right: 6px;
				display: block;
				width: 35px;
				height: 7px;
				background-position: right center;
				background-image: url(assets/images/common/btn_arrow.svg);
				background-size:100% auto;
				transform: rotate(-180deg);
			}
		}
	}
	.btn {
		display: flex;
		justify-content: center;
		padding: 0;
		text-align: center;
		flex:1 1 41.2%;
		max-width: 41.2%;
		a {
			display: block;
			font-size: 12px;
			letter-spacing: 1.2px;
			color: #333333;
			padding:0 10px 0 10px;
			box-sizing: border-box;
		}
	}
	.next {
		display: flex;
		align-items: center;
		flex:1 1 29.4%;
		max-width: 29.4%;
		box-sizing: border-box;
		padding: 0;
		border-left: 1px solid #d6d6d6;
		a {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-left: 13%;
			width: 100%;
			font-size: 11px;
			font-weight: bold;
			letter-spacing: 1.1px;
			color: #000000;
			@include english(400);
			text-align: center;
			&:after {
				content: '';
				display: block;
				margin-left: 6px;
				width: 35px;
				height: 7px;
				background-position: right center;
				background-image: url(assets/images/common/btn_arrow.svg);
				background-size:100% auto;
			}
		}
	}

	@media print, screen and (min-width: $breakpoint1){
		// PC style
		display: flex;
		justify-content: center;
		width: 780px;
		margin:124px auto auto;

		.prev {
			display: flex;
			flex:0 0 151px;
			max-width: none;
			box-sizing: border-box;
			align-items: center;
			padding: 0;
			border-right: 1px solid #d6d6d6;

			a {
				display: flex;
				align-items: center;
				padding-right: 13%;
				width: 100%;
				font-size: 13px;
				letter-spacing: 0.65px;
				color: #000000;
				@include english(400);
				&:before {
					content: '';
					margin-right: 21px;
					display: block;
					width: 43px;
					height: 10px;
					background-position: right center;
					background-image: url(assets/images/common/btn_arrow.svg);
					background-size:100% auto;
					transform: rotate(180deg);
				}
				&:hover {
					opacity: 0.7;
					&:before {
						transform: translateX(0) rotate(180deg);
						animation: arrowAnimation2-navigationLocal 500ms ease 0ms;
					}
				}
			}
		}
		.btn {
			display: flex;
			justify-content: center;
			padding: 0;
			text-align: center;
			flex:0 0 222px;
			max-width: 222px;
			a {
				display: block;
				font-size: 14px;
				letter-spacing: 1.4px;
				color: #333333;
				padding:0 10px 0 10px;
				box-sizing: border-box;
				&:hover {
					opacity: 0.7;
				}
			}
		}
		.next {
			display: flex;
			align-items: center;
			flex:0 0 151px;
			max-width: 151px;
			box-sizing: border-box;
			padding: 0;
			border-left: 1px solid #d6d6d6;
			a {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-left: 13%;
				width: 100%;
				font-size: 13px;
				letter-spacing: 0.65px;
				color: #000000;
				@include english(400);
				text-align: center;
				&:after {
					content: '';
					display: block;
					margin-left: 21px;
					width: 43px;
					height: 10px;
					background-position: right center;
					background-image: url(assets/images/common/btn_arrow.svg);
					background-size:100% auto;
				}
				&:hover {
					opacity: 0.7;
					&:after{
						transform: translateX(0);
						animation: arrowAnimation-navigationLocal 500ms ease 0ms;
					}
				}

			}
		}
	}
	@keyframes arrowAnimation2-navigationLocal {
		0% {
			transform: translateX(0%) rotate(180deg);
		}
		50% {
			transform: translateX(-100%) rotate(180deg);
		}
		51% {
			transform: translateX(100%) rotate(180deg);
		}
		100% {
			transform: translateX(-0%) rotate(180deg);
		}
	}
	@keyframes arrowAnimation-navigationLocal {
		0% {
			transform: translateX(-0%);
		}
		50% {
			transform: translateX(100%);
		}
		51% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(0%);
		}
	}
}

// --------------------
// sectionContactThanks
// --------------------
.sectionContactThanks{
	// SP style
	padding-top: 90px;
	padding-bottom: 150px;
	.mw_wp_form_complete {
		.pageTitle {
			.main {
				font-size: 31px;
				font-weight: 600;
				line-height: 1.4;
				letter-spacing: 1.7px;
				text-align: center;
				color : #000000;
			}
			.sub {
				text-align: center;
				font-size: 13px;
			}
		}
	}
	.btn1 {
		margin: 54px auto 0;
		width: 100%;
	}
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-top: 100px;
		padding-bottom: 200px;
		.mw_wp_form_complete {
			.pageTitle {
				.main {
					text-align: center;
					font-size: 57px;
					letter-spacing: 2.85px;
				}
				.sub {
					font-size: 18px;
					line-height: 32px;
					color: #000000;
					text-align: center;
					letter-spacing: 1px;
				}
			}
		}
		.btn1 {
			margin: 45px auto 0;
			width: 390px;
		}
	}
}

// @import '../../../../../css/function.scss';




#container {
	#content.single {
	}
}

body {
//	&.page-template-page-contact-thanks,
//	&.page-template-page-document-thanks{
//		@import 'assets/css/_pc_contact_thanks';
//	}
}

// --------------------
// sectionWhatMeDo
// --------------------
.sectionWhatMeDo{
// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.layoutSolid{
		// height: 530px + $topAddMarin;
		position: relative;
		padding-bottom: 250px;
	}
	.head1{
		margin-right: -25px;
		.title{
			margin-right: 15px;
		}
	}

	ul{
		margin: 0 0;
		li{
			margin-bottom: 20px;
			&:nth-child(1) dl dt:before{
				background-image: url('assets/images/home/icon_what1.svg');
			}
			&:nth-child(2) dl dt:before{
				background-image: url('assets/images/home/icon_what2.svg');
			}
			&:nth-child(3) dl dt:before{
				background-image: url('assets/images/home/icon_what3.svg');
			}
		}
		a, div.wmdWrap{
			display: block;
			padding: 20px;
			// display: flex;
			// align-items: center;
			// justify-content: space-between;
			border-top: 1px solid #f2f3f5;
			border-right: 1px solid #f2f3f5;
			border-bottom: 1px solid #f2f3f5;

			background-size: 8px auto;
			background-position: left bottom;
			background-image: url('assets/images/common/btn_border3.svg');

			overflow: hidden;
			box-sizing: border-box;
			color: #000;

			// &:hover{
			// 	opacity: 0.7;
			// }


			dl{
				// flex: 1 1 auto;
				// max-width: 555px;
				// margin: 30px 0;

				@include dotBorderSp(x, left bottom);

				dt{
					@include fontBold();
					font-size: 17px;
					line-height: 1.4;

					display: flex;
					align-items: center;

					em{
						color: #e38a21;
						font-style: normal;
					}
					span{
						flex: 1 1 50px;
						padding-left: 15px;
					}
					&:before{
						content: '';
						display: block;
						flex: 0 0 50px;
						height: 50px;
						background-position: center center;
						background-size: contain;
					}

				}
				dd{
					font-size : 11px;
					line-height: 1.4;
					color: #666;
					padding: 15px 0 30px;
				}
			}
			.arrow{
				margin: 16px auto 0;
			}
		}
	}

	.btn1{
		margin: 40px 0 40px;
	}

	.arrowDrop{
		bottom: 0;
		left: 50%;

	}



}

// PC style
@media print, screen and (min-width: $breakpoint1){
	position: relative;
	// padding-top: 60px;
	padding-top: 0;
	padding-left: 200px;

	.layoutSolid{
		// height: 840px + 60px + $topAddMarin;
		height: 840px + 60px;
		position: relative;
	}
	.head1{
		.title{
			margin-right: 50px;
		}
		.line{
			// margin-right: 50px;
			// margin-left: -200px;
		}
	}
	// .text{
	// 	@include fontBold();
	// 	font-size: 20px;
	// 	line-height: 2.9;
	// }
	ul{
		width: 838px;
		margin: 80px 0 0 auto;
		li{
			margin-bottom: 20px;
			&:nth-child(1) a:before,
			&:nth-child(1) div.wmdWrap:before{
				background-image: url('assets/images/home/icon_what1.svg');
			}
			&:nth-child(2) a:before,
			&:nth-child(2) div.wmdWrap:before{
				background-image: url('assets/images/home/icon_what2.svg');
			}
			&:nth-child(3) a:before,
			&:nth-child(3) div.wmdWrap:before{
				background-image: url('assets/images/home/icon_what3.svg');
			}
		}
		a, div.wmdWrap{
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: 1px solid #f2f3f5;
			border-right: 1px solid #f2f3f5;
			border-bottom: 1px solid #f2f3f5;

			background-size: 13px auto;
			background-position: left center;
			background-image: url('assets/images/common/btn_border2.svg');

			overflow: hidden;
			box-sizing: border-box;
			color: #000;


			&:before{
				content: '';
				display: block;
				flex: 0 0 170px;
				max-width: 170px;
				align-self: stretch;

				background-position: center center;
				background-size: auto;
			}
			dl{
				flex: 1 1 auto;
				// max-width: 555px;
				margin: 30px 0;

				@include dotBorder(y, right top);

				dt{
					@include fontBold();
					font-size: 20px;
					em{
						color: #e38a21;
						font-style: normal;
					}
				}
				dd{
					font-size : 13px;
				}
			}
			.arrow{
				flex: 0 0 38px;
				max-width: 38px;
				margin-right: 30px;
				margin-left: 30px;
			}
		}
		a{
			&:hover{
				opacity: 0.7;
			}
		}
	}
	.btn1{
		// width: 838px;
		margin: 35px 0 0 auto;
	}

	.arrowDrop{
		top: 175px;
		left: 55px;
	}


}
}

// --------------------
// breadcrumb
// --------------------
.breadcrumb{
	margin: 18px 0 36px 0;
	padding: 0 0 10px 0;
	ol{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		list-style: none;
		li{
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			max-width: 100%;
			>a,>span{

				font-size: 10px;
				line-height: 1;
				position: relative;
				padding: 2px 0;
				margin-right: 17px;
				text-decoration: none;
				&:after{
					content: '\03e';
					font-size: 11px;
					line-height: 1;
					display: inline-block;
					color: #cccccc;
					position: absolute;
					top: 50%;
					right: -12px;
					transform: translateY(-50%) scale(0.8);
				}
			}
			>span{
				color: #222;
			}

			&:last-child>a,
			&:last-child>span{
				&:after{
					display: none;
				}
			}
			>a {
				color : #BCBCBC;
			}
			&:last-child>a {
				color : #666;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		width: 100%;
		//max-width: 1444px;
		//min-width: 1100px;
		margin-left: auto;
		margin-right: auto;

		padding:0 0 65px 0;
		box-sizing: border-box;
		ol{
			li{
				>a,>span{
					font-size: 14px;
					margin-right: 30px;
					&:after{
						font-size: 16px;
						right: -20px;
					}
				}
				>a {
					transition-duration: 200ms;
				}
				>a:hover{
					opacity: 0.7;
				}
			}
		}
	}
}

// --------------------
// privacy-policy
// --------------------
body.page-privacy-policy main{


	//$topAddMarin: 100px;

	// styles
	@import 'privacy-policy/_sectionHead.scss';
	@import 'privacy-policy/_sectionPrivacyPolicy.scss';
}
// --------------------
// sectionMission
// --------------------
.sectionMission{
// SP style
@media print, screen and (max-width: $breakpoint1 - 1){

	.layoutSolid{
		// height: 530px + $topAddMarin;
		position: relative;
		padding-bottom: 50px;
	}
	.head1{
		margin-left: -25px;
		flex-direction: row-reverse;
		.title{
			margin-left: 15px;
			// margin-left: 50px;
			// margin-right: -220px;
		}
	}
	.text{
		// margin: 80px 250px 0 0;
		@include fontBold();
		font-size: 16px;
		line-height: 1.65;

		em{
			background-color: #e38a21;
			color: #fff;
			font-style: normal;
			padding-left: 0.5em;
		}
	}

	.arrowDrop{
		display: none;
	}


}

// PC style
@media print, screen and (min-width: $breakpoint1){
	position: relative;
	// padding-top: 60px;
	padding-top: 0;
	padding-left: 200px;

	.layoutSolid{
		height: 530px + $topAddMarin;
		position: relative;
	}
	.head1{
		flex-direction: row-reverse;
		.title{
			margin-left: 50px;
			margin-right: -220px;
		}
	}
	.text{
		margin: 80px 250px 0 0;
		@include fontBold();
		// font-feature-settings : "palt";
		font-size: 20px;
		line-height: 2.9;
		text-align: right;

		em{
			background-color: #e38a21;
			color: #fff;
			font-style: normal;
			padding-left: 0.5em;
		}
	}

	.arrowDrop{
		top: 180px;
		right: 205px;
	}


}
}

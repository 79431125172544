// --------------------
// andMore
// -------------------- */

.andMore {
	margin-top: 60px;
	padding-top: 23px;
	@include dotBorderSp(x, left top);
	p {
		text-align: center;
		@include english();
		font-size: 13px;
		color: #B7B7B7;
		letter-spacing: 1px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1 +1){
		margin-top: 74px;
		padding-top: 24px;
		@include dotBorder(x, left top);
		p {
			font-size: 16px;
		}
	}
}

// --------------------
// sectionMessageKV
// --------------------
.sectionMessageKV {
	// SP style
	padding-bottom: 0;
	overflow: hidden !important;

	figure {
		img {
			vertical-align: top;
			width: 100%;
			height: auto;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding-bottom: 0;
		margin-left: -125px;
		padding-left: 0;
		min-width: 0;

		figure {
			line-height: 1;
			img {
			}
		}
	}
}

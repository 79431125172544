// @import '../../../../../css/function.scss';




#container {
	#content {
		h1 {
			font-weight: bold;
		}
		
		h2 {
			font-weight: bold;
		}
		
		.entry {
			border: 1px solid green;
			
			.title {
				text-decoration: none;
				color: red;
			}
			
			.button {
				a {
					border: 1px solid blue;
					padding: 10px 10px 10px 10px;
					text-align: center;
					&:after {
						content: ''
					}
				}
			}
		}
	}
}

body {
//	&.page-template-page-contact-thanks,
//	&.page-template-page-document-thanks{
//		@import 'assets/css/_pc_contact_thanks';
//	}
}
